import React from 'react';
import styled from 'styled-components';

import { toggleCartModalAction } from '_store/ui';
import phone from '_images/phone.svg';
import { ResponseBarRow } from './components/responseBarRow';
import { useSelector, useDispatch } from 'react-redux';
import { isMobileDevice } from '_helpers';
import electricityIcon from '_images/electricityIcon.svg';
import telecomIcon from '_images/telecomIcon.svg';
import insuranceIcon from '_images/insuranceIcon.svg';
import moversIcon from '_images/moversIcon.svg';
import securityIcon from '_images/securityIcon.svg';

const routesToHide = ['/', '/serviceSelect', '/confirmation'];

const Container = styled.div`
  display: ${({ pathname }) =>
    routesToHide.includes(pathname) || (isMobileDevice && pathname !== 'cart')
      ? 'none'
      : 'flex'};
  width: 250px;
  flex-direction: column;
  align-items: flex-start;
`;

const Heading = styled.div`
  font-weight: bold;
  align-self: center;
  margin-bottom: 25px;
`;

const PhoneLink = styled.a`
  align-self: flex-end;
`;

const PhoneNumberContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: flex-end;
  padding: 5px 0px;
`;

const PhoneNumber = styled.div`
  font-weight: bold;
`;

const PhoneIcon = styled.img`
  height: 20px;
  margin-right: 5px;
`;

const CloseIcon = styled.div`
  align-self: flex-end;
  font-weight: bold;
  cursor: pointer;
`;

export const DetailedProgress = ({ pathname }) => {
  const reducer = (state) => state.userReducer;
  const { answers, cart } = useSelector(reducer);

  const uiReducer = (state) => state.uiReducer;
  const { isOpenCartModal } = useSelector(uiReducer);

  const dispatch = useDispatch();

  const services = [
    {
      heading: 'Electricity',
      answers: Object.values(answers.electricity),
      plan: cart.electricity,
      link: '../questions/electricity',
      icon: electricityIcon,
    },
    {
      heading: 'TV and Internet',
      answers: Object.values(answers.telecom),
      plan: cart.telecom,
      link: '../questions/telecom',
      icon: telecomIcon,
    },
    {
      heading: 'Insurance',
      answers: Object.values(answers.insurance),
      plan: cart.insurance,
      link: '../questions/insurance',
      icon: insuranceIcon,
    },
    {
      heading: 'Movers',
      answers: Object.values(answers.movers),
      plan: cart.movers,
      link: '../questions/movers',
      icon: moversIcon,
    },
    {
      heading: 'Security',
      answers: Object.values(answers.security),
      plan: cart.security,
      link: '../questions/security',
      icon: securityIcon,
    },
  ];

  return (
    <Container pathname={pathname}>
      {pathname === 'cart' && (
        <>
          <CloseIcon
            onClick={() => {
              dispatch(toggleCartModalAction(!isOpenCartModal));
            }}
          >
            X
          </CloseIcon>
          <PhoneLink href="tel:1-844-387-7747">
            <PhoneNumberContainer>
              <PhoneIcon src={phone}></PhoneIcon>
              <PhoneNumber>844-387-7747</PhoneNumber>
            </PhoneNumberContainer>
          </PhoneLink>
        </>
      )}
      <Heading>My Services</Heading>
      {services.map((service) => {
        return (
          <ResponseBarRow
            service={service.heading}
            key={service.heading}
            answers={service.answers}
            plan={service.plan ? service.plan.plan : 'No plan selected'}
            link={service.link}
            icon={service.icon}
          />
        );
      })}
    </Container>
  );
};
