import React, { useState } from 'react';
import styled from 'styled-components';
import { ValidationError } from 'yup';
import { AddressAutocomplete } from '_components/addressAutocomplete';
import {
  isFieldValid
} from '_helpers/inputFieldsValidation';




const StyledInputContainer = styled.div`
  margin: 10px 0px;
  width: 50%;
  width: ${({ width }) => width};
`;

const Text = styled.div`
  font-size: ${({ fontSize }) => fontSize};
  font-weight: ${({ fontWeight }) => fontWeight};
`;

const StyledInput = styled.input`
  border: 1px solid rgb(210, 211, 212);
  height: 40px;
  margin-right: ${({ space }) => space && '5px'};
  border-radius: 6px;
  width: 100%;
  padding: 5px;
`;

const StyledInputError = styled.input`
  border: 1px solid rgb(255,0,0);
  height: 40px;
  margin-right: ${({ space }) => space && '5px'};
  border-radius: 6px;
  width: 100%;
  padding: 5px;
  style={{color: "red"}}
`;

const FlexRow = styled.div`
  display: flex;
  align-content: center;
`;

const ButtonText = styled.div`
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  color: blue;
  margin-left: 5px;
  cursor: pointer;
`;

const PromoCodeText = styled.div`
  color: ${({ color }) => color};
  font-size: 10px;
`;

const ErrorMessage = styled.label`
  color: rgb(255,0,0);
  font-size: 10px;
  `;

export const InputContainer = ({
  text,
  name,
  value,
  placeholder,
  onChange,
  width,
  space,
  button,
  address,
  fieldType,
  isRequired,
  validationType,
}) => {
  const [buttonClicked, setButtonClicked] = useState(false);

  const[isValid, setIsValid] = useState(true);
  const[isRequiredCompleted,setIsRequiredCompleted] = useState(true);

  const runValidation = () => {
    if (isRequired === "true"){
      if (value.trim() === ''){
        setIsRequiredCompleted(false);
      }
      else{
        setIsRequiredCompleted(true);
        if (validationType){
          if (isFieldValid(value,validationType)){
            setIsValid(true);
          }else{
            setIsValid(false);
          }
        }
      }
    } else {
      setIsRequiredCompleted(true);
      if (validationType){
        if (isFieldValid(value,validationType)){
          setIsValid(true);
        } else {
          setIsValid(false);
        }
      }
    }
  };

  return (
    <StyledInputContainer width={width}>
      <Text>{text}</Text>
      <FlexRow>
        {address ? (
          <AddressAutocomplete addressInput={address} />
        ) : (isRequiredCompleted && isValid ? (
          <StyledInput
            name={name}
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            space={space}
            type={fieldType}
            onBlur={()=>{runValidation()}}
          />
        ) : ( !isRequiredCompleted ? (<StyledInputError
            name={name}
            value={value}
            onChange={onChange}
            placeholder={placeholder+' is required.'}
            space={space}
            type={fieldType}
            onBlur={()=>{runValidation()}}
          />
          ):(<StyledInputError
            name={name}
            value={value}
            onChange={onChange}
            placeholder={placeholder+' is invalid.'}
            space={space}
            type={fieldType}
            onBlur={()=>{runValidation()}}
          />
          )))}
          {!isValid ? <ErrorMessage>{placeholder} is not valid.</ErrorMessage>:''}
        {button && (
          <ButtonText
            onClick={() => {
              setButtonClicked(true);
            }}
          >
            {button}
          </ButtonText>
        )}
      </FlexRow>
    </StyledInputContainer>
  );
};
