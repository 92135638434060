import React, { useEffect } from 'react';
import styled from 'styled-components';
import { deviceSize, colors } from '_constants';
import { useDispatch, useSelector } from 'react-redux';
import { ResultCard } from '_components';
import { clearCartAction } from '_store/user';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 50px 100px;
  align-items: center;

  @media (max-width: ${deviceSize.tablet}px) {
    padding: 0px;
  }
`;

const ExtraInfoContainer = styled.div`
  width: 50%;
  text-align: left;
`;

const ExtraInfoContainerCenter = styled.div`
  width: 50%;
  text-align: center;  
`

const Heading = styled.div`
  font-size: 36px;
  line-height: 1;
  font-weight: 900;
  margin-bottom: 20px;
`;

const Subtitle = styled.div`
  color: ${colors.darkGray};
  font-size: 12px;
`;

const PhoneRow = styled.a`
  align-items: center;
`;

export const Main = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(clearCartAction());
  }, [dispatch]);
  const reducer = (state) => state.userReducer;
  const { cartCopy } = useSelector(reducer);
  const selectedPlansInformation =
    cartCopy && Object.values(cartCopy).filter((service) => service !== null);
  return (
    <Container>
      <Heading>Congrats!</Heading>
      <ExtraInfoContainerCenter>
      Thanks for using Connect for your home service needs!
      </ExtraInfoContainerCenter> 
      <br/>
      {selectedPlansInformation?.map((plan) => (
        <ResultCard plan={plan} key={plan.plan} />
      ))}
      <br/>
      <ExtraInfoContainer>
        <Heading>So what happens next?</Heading>
        An order specialist will be in touch with you to gather any additional details needed to place your order with the selected providers. If we cannot place the order directly, you will receive a text and/or email with the information needed to obtain quotes and great promotions from our valued partners. Once your order is placed, we’ll provide all the necessary confirmation and account numbers, and you’ll hear directly from the service provider.
        <br />
        <br />
        
        <Heading>Also need to forward your address?</Heading>
        Fill out this online form with the <a href="https://moversguide.usps.com/mgo/address-info?utm_medium=email&_hsenc=p2ANqtz--KgbGz2diUi3m7EpxDMsgwaP0isq5CSqt2Wjuo5gT8T9_01aE7fgdT5qBxu5xXKR41hCCSC4_KRMwSjWJSuuj4Szm5MFSawcgwdJZP9OtT0-yppR0&_hsmi=2&utm_content=2&utm_source=hs_email">USPS</a> to ensure you don’t miss any important mail.
        <br />
        <br />
        <Heading>Questions or need something else?</Heading>
        Email <a href="mailto:info@connectservices.com">info@connectservices.com</a> OR Text/Call us at <PhoneRow href="tel:1-844-406-1540">844-406-1540 </PhoneRow>
        <br/>
        {/* <br/>Monday – Friday: 
        8:00 a.m. to 6:00 p.m. CST
        <br/>Saturday – Sunday:
        Closed */}
      </ExtraInfoContainer>
    </Container>
  );
};
