import { createSlice } from '@reduxjs/toolkit';

// import { userEventsService } from '_services';

// export const getuserEventsAction = createAsyncThunk(
//   'content/user/get',
//   async (payload) => {
//     const response = await userEventsService.getuserEvents(
//       payload.id,
//       payload.location
//     );
//     return response;
//   }
// );

const plansSlice = createSlice({
  name: 'plans',
  initialState: {
    plans: {
      telecom: [],
      electricity: [],
      movers: [],
      insurance: [],
      security: [],
    },
  },
  reducers: {
    updatePlansAction(state, action) {
      state.plans = action.payload;
    },
  },
  // extraReducers: {
  //   [getuserEventsAction.fulfilled]: (state, action) => {
  //     state.user = action.payload;
  //   },
  // },
});

export const { updatePlansAction } = plansSlice.actions;
export default plansSlice.reducer;
