import React from 'react';
import styled from 'styled-components';
import { colors } from '_constants';
import { isMobileDevice } from '_helpers';

const routesToHide = ['/', '/serviceSelect', '/confirmation'];

const Container = styled.div`
  display: ${({ pathname }) =>
    routesToHide.includes(pathname) || isMobileDevice ? 'none' : 'flex'};
  flex-direction: column;
  width: 75px;
  justify-content: center;
  align-items: center;
  align-self: flex-start;
  margin-right: 50px;
`;

const Dot = styled.span`
  background-color: ${({ show }) => (show ? colors.brand : 'gray')};
  border: none;
  border-radius: 50%;
  margin: 10px auto;
  height: 10px;
  width: 10px;
`;

const Text = styled.span`
  color: ${({ show }) => (show ? colors.brand : 'gray')};
  font-size: 12px;
`;

const Line = styled.span`
  height: 25px;
  margin: 5px auto;
  text-align: center;
  width: 2px;
  background-color: ${({ show }) => (show ? colors.brand : 'gray')};
`;

export const SimpleProgress = ({ pathname }) => {
  //TODO:
  //Do this differently
  return (
    <Container show pathname={pathname}>
      <Dot show />
      <Text show>START</Text>
      <Line show />
      <Dot show />
      <Text show>SHOP</Text>

      <Line
        show={
          pathname.includes('/review') ||
          pathname.includes('/info') ||
          pathname === '/confirmation'
        }
      />
      <Dot
        show={
          pathname.includes('/review') ||
          pathname.includes('/info') ||
          pathname === '/confirmation'
        }
      />
      <Text
        show={
          pathname.includes('/review') ||
          pathname.includes('/info') ||
          pathname === '/confirmation'
        }
      >
        INFO
      </Text>

      <Line show={pathname === '/confirmation'} />
      <Dot show={pathname === '/confirmation'} />
      <Text show={pathname === '/confirmation'}>DONE</Text>
    </Container>
  );
};
