import React, { useEffect } from 'react';
import styled from 'styled-components';

import { updateReferrerAction, updateUserCartAction } from '_store/user';
import contactInfo, {updateContactInfoAction} from '_store/contactInfo';
import { updatePlansAction } from '_store/plans';
import { useDispatch, useSelector } from 'react-redux';

import partners from '_images/partners.png';

import { colors, deviceSize } from '_constants/styleConstants';
import { Hero } from './hero';
import { Services } from './services';
import { HowItWorks } from './howItWorks';
import { Allies } from './allies';
import { Pricing } from './pricing';
import { Curve } from './components/curve';
import { toggleCartModalAction } from '_store/ui';
// import { Marquee } from './components/marquee';

// const Container = styled.div`
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
// `;

const PartnersContainer = styled.div`
  margin: 0 auto;
  width: 65%;

  @media (max-width: ${deviceSize.tablet}px) {
    width: 80%;
  }
`;

const Partners = styled.img`
  max-width: 100%;
  padding: 10px;
`;

const StyledCurve = styled(Curve)`
  display: block;
  ${({ toFooter }) => toFooter && 'background-color: black;'}
`;

export const Home = ({ className, query }) => {
  const contactInfoReducer = (state) => state.contactInfoReducer;
  const { contactInfo } = useSelector(contactInfoReducer);
  const {
    firstName,
    lastName,
    phone,
    email,
    unit,
    cc,
    exp,
    cvv,
    ss,
    dob,
    dl,
    dlState,
    dlExp,
    billingFirst,
    billingLast,
    billingUnit,
    gclid,
    ga,
    expId,
    expName,
    ga_cookies,
  } = contactInfo;
  const serviceTypes = ["electricity", "telecom", "movers", "insurance", "security"];
  const cookies = ["gclid","ga","experimentId","experimentName"];
  const services = Object.keys(query);
  const dispatch = useDispatch();
  let ga_cookies_values = {};
  useEffect(() => {
    if (query.ref) {
      dispatch(updateReferrerAction(query.ref));
    }

    fetch('/api/salesforceproducts', {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
      .then((response) => response.json())
      .then((plans) => {
        dispatch(updatePlansAction(plans));
      });

    if (services) {
      services.forEach((service) => {
        if (serviceTypes.includes(service)){
          fetch(`/buildCart/${service}`, {
            method: 'post',
            body: JSON.stringify({
              plan: decodeURIComponent(query[service]),
            }),
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
            },
          })
            .then((response) => response.json())
            .then((plan) => {
              dispatch(
                updateUserCartAction({ service: service.toLowerCase(), plan })
              );
              dispatch(toggleCartModalAction(true));
            });
        } else if (cookies.includes(service)){
          ga_cookies_values[service] = query[service];
        }
      });
      if (ga_cookies_values){
        dispatch(updateContactInfoAction({...contactInfo, ["ga_cookies"]: ga_cookies_values}));
      }
    }
  }, []);

  return (
    <div>
      <Hero />
      {/* <StyledCurve color="black" className={className} />
      <PartnersContainer>
        <Partners src={partners} />
      </PartnersContainer>
      <Services />
      <StyledCurve
        color={colors.parchment}
        straightOnBottom
        className={className}
      />
      <HowItWorks />
      <StyledCurve color={colors.parchment} className={className} /> */}
      {/* <Marquee /> */}
      <Allies />
      {/* <StyledCurve
        color={colors.parchment}
        straightOnBottom
        className={className}
      />
      <Pricing />
      <StyledCurve color={colors.parchment} toFooter className={className} /> */}
    </div>
  );
};
