import React from 'react';
import styled from 'styled-components';

import { colors, deviceSize } from '_constants/styleConstants';

import att from '_images/grayLogos/att.png';
import cox from '_images/grayLogos/cox.png';
import centurylink from '_images/grayLogos/centurylink.png';
import directv from '_images/grayLogos/directv.png';
import dish from '_images/grayLogos/dish.png';
import frontier from '_images/grayLogos/frontier.png';
import spectrum from '_images/grayLogos/spectrum.png';
import suddenlink from '_images/grayLogos/suddenlink.png';
import verizon from '_images/grayLogos/verizon.png';

import champion from '_images/grayLogos/champion.png';
import cirro from '_images/grayLogos/cirro.png';
import direct from '_images/grayLogos/direct.png';
import firstchoice from '_images/grayLogos/firstchoice.png';
import electricfrontier from '_images/grayLogos/electricfrontier.png';
import gexa from '_images/grayLogos/gexa.png';
import greenmountain from '_images/grayLogos/greenmountain.png';
import reliant from '_images/grayLogos/reliant.png';
import txu from '_images/grayLogos/txu.png';
import veteran from '_images/grayLogos/veteran.png';

import allied from '_images/grayLogos/allied.png';
import atlas from '_images/grayLogos/atlas.png';
import bekins from '_images/grayLogos/bekins.png';
import graebel from '_images/grayLogos/graebel.png';
import mayflower from '_images/grayLogos/mayflower.png';
import national from '_images/grayLogos/national.png';
import northamerican from '_images/grayLogos/northamerican.png';
import penske from '_images/grayLogos/penske.png';
import uhaul from '_images/grayLogos/uhaul.png';
import united from '_images/grayLogos/united.png';

import allstate from '_images/grayLogos/allstate.png';
import esurance from '_images/grayLogos/esurance.png';
import farmers from '_images/grayLogos/farmers.png';
import lemonade from '_images/grayLogos/lemonade.png';
import liberty from '_images/grayLogos/liberty.png';
import progressive from '_images/grayLogos/progressive.png';
import nationwide from '_images/grayLogos/nationwide.png';
import statefarm from '_images/grayLogos/statefarm.png';
import travelers from '_images/grayLogos/travelers.png';
import usaa from '_images/grayLogos/usaa.png';

import adt from '_images/grayLogos/adt.png';
import alarm from '_images/grayLogos/alarm.png';
import brinks from '_images/grayLogos/brinks.png';
import frontpoint from '_images/grayLogos/frontpoint.png';
import nest from '_images/grayLogos/nest.png';
import protectamerica from '_images/grayLogos/protectamerica.png';
import ring from '_images/grayLogos/ring.png';
import scout from '_images/grayLogos/scout.png';
import simplisafe from '_images/grayLogos/simplisafe.png';
import vivint from '_images/grayLogos/vivint.png';

import { SectionTitle } from './components/sectionTitle';
import { useSelector } from 'react-redux';

const Container = styled.div`
  width: 100%;
  background-color: white;
  padding: 50px 150px;

  @media (max-width: ${deviceSize.tablet}px) {
    padding: 20px;
  }
`;

const TopRow = styled.div`
  display: flex;
  margin-left: 50px;
  margin-bottom: 50px;

  @media (max-width: ${deviceSize.tablet}px) {
    margin: 0 auto;
  }
`;

const TitleText = styled.div`
  width: 33%;

  @media (max-width: ${deviceSize.tablet}px) {
    width: initial;
  }
`;

const Heading = styled.div`
  font-size: 36px;
  font-weight: bold;
  margin-top: 20px;
  line-height: 1.2;
  color: #4A4A4A;

  @media (max-width: ${deviceSize.tablet}px) {
    font-size: 20px;
  }
`;

const Subheading = styled.div`
  font-size: 16px;
  color: ${colors.darkGray};
`;

const LogoRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 75px 0px;

  @media (max-width: ${deviceSize.tablet}px) {
    margin: 35px 0px;
  }
`;

const LogoContainer = styled.div`
  width: 20%;
  text-align: center;

  @media (max-width: ${deviceSize.tablet}px) {
  }
`;

const Logo = styled.img`
  max-height: ${({ adt }) => (adt ? '20px' : '50px')};

  @media (max-width: ${deviceSize.tablet}px) {
    width: 15%;
  }
`;

const Spacing = styled.div`
  padding-bottom:390px;

  @media (max-width: ${deviceSize.tablet}px) {
    padding-bottom:75px;
  }
`

const telecom1 = [att, cox, centurylink, directv, dish];
const telecom2 = [frontier, spectrum, suddenlink, verizon];

const electricity1 = [champion, cirro, direct, firstchoice, electricfrontier];
const electricity2 = [gexa, greenmountain, reliant, txu, veteran];

const movers1 = [allied, atlas, bekins, graebel, mayflower];
const movers2 = [national, northamerican, penske, uhaul, united];

const insurance1 = [allstate, esurance, farmers, lemonade, liberty];
const insurance2 = [progressive, nationwide, statefarm, travelers, usaa];

const security1 = [adt, alarm, brinks, frontpoint, nest];
const security2 = [protectamerica, ring, scout, simplisafe, vivint];

export const Allies = () => {
  const uiReducer = (state) => state.uiReducer;
  const { selectedHeroButton } = useSelector(uiReducer);

  return (
    <Container>
      {!['trash', 'HomeWarranty', 'PestControl'].includes(selectedHeroButton) ?
      (
        <Heading>
          Compare prices from the {selectedHeroButton.toLowerCase() == "movers" ? "moving" : selectedHeroButton.toLowerCase()} companies you
          trust.
        </Heading>
      ):(
        <Spacing></Spacing>
      )}
      {selectedHeroButton === 'Telecom' && (
        <>
          <LogoRow>
            {telecom1.map((logo) => {
              return <Logo src={logo} key={logo} />;
            })}
          </LogoRow>
          <LogoRow>
            {telecom2.map((logo) => {
              return <Logo src={logo} key={logo} />;
            })}
          </LogoRow>
        </>
      )}
      {selectedHeroButton === 'Electricity' && (
        <>
          <LogoRow>
            {electricity1.map((logo) => {
              return <Logo src={logo} key={logo} />;
            })}
          </LogoRow>
          <LogoRow>
            {electricity2.map((logo) => {
              return <Logo src={logo} key={logo} />;
            })}
          </LogoRow>
        </>
      )}
      {selectedHeroButton === 'Movers' && (
        <>
          <LogoRow>
            {movers1.map((logo) => {
              return <Logo src={logo} key={logo} />;
            })}
          </LogoRow>
          <LogoRow>
            {movers2.map((logo) => {
              return <Logo src={logo} key={logo} />;
            })}
          </LogoRow>
        </>
      )}
      {selectedHeroButton === 'Insurance' && (
        <>
          <LogoRow>
            {insurance1.map((logo) => {
              return <Logo src={logo} key={logo} />;
            })}
          </LogoRow>
          <LogoRow>
            {insurance2.map((logo) => {
              return <Logo src={logo} key={logo} />;
            })}
          </LogoRow>
        </>
      )}
      {selectedHeroButton === 'Security' && (
        <>
          <LogoRow>
            {security1.map((logo) => {
              return <Logo src={logo} key={logo} />;
            })}
          </LogoRow>
          <LogoRow>
            {security2.map((logo) => {
              return <Logo src={logo} key={logo} />;
            })}
          </LogoRow>
        </>
      )}
    </Container>
  );
};
