import React, {useState} from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { colors, deviceSize } from '_constants';
import PropTypes from 'prop-types';

import rightArrow from '_images/rightArrow.svg';

const Button = styled.div`
  ${({ filterSelected }) =>
    filterSelected
      ? `background-color: ${colors.brand}; border: 1px solid ${colors.brand};`
      : `background-color: white; border: 1px solid ${colors.darkGray};`}
  min-width: 120px;
  max-width: initial;
  max-height: initial;
  height: auto;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 35px;
  font-size: 16px;
  margin-top: 10px;
  cursor: pointer;

  @media (max-width: ${deviceSize.tablet}px) {
    height: auto;
    max-width: initial;
    min-width: initial;
    font-size: 12px;
  }
`;

const ButtonText = styled.p`
  height: initial;
  line-height: initial;
  color: ${({ filterSelected }) => (filterSelected ? 'white' : 'black')};
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
  display: inline;

  @media (max-width: ${deviceSize.tablet}px) {
    height: initial;
    line-height: initial;
  }
`;

const StyledMarging = styled.div`
  margin: 5px;
`;

export const FilterButton = ({filterSelected, label}) => {
  return (
    <StyledMarging>
      <Button filterSelected={filterSelected}>
        <ButtonText filterSelected={filterSelected}>
          {label}
        </ButtonText>
      </Button>
    </StyledMarging>
  );
};
