import { createSlice } from '@reduxjs/toolkit';

// import { userEventsService } from '_services';

// export const getuserEventsAction = createAsyncThunk(
//   'content/user/get',
//   async (payload) => {
//     const response = await userEventsService.getuserEvents(
//       payload.id,
//       payload.location
//     );
//     return response;
//   }
// );

const startDatesSlice = createSlice({
  name: 'startDates',
  initialState: {
    startDates: {
      electricity: 'No start date specified.',
      telecom: 'No start date specified.',
      insurance: 'No start date specified.',
      movers: 'No start date specified.',
      security: 'No start date specified.',
    },
  },
  reducers: {
    updateStartDatesAction(state, action) {
      state.startDates = action.payload;
    },
  },
  // extraReducers: {
  //   [getuserEventsAction.fulfilled]: (state, action) => {
  //     state.user = action.payload;
  //   },
  // },
});

export const { updateStartDatesAction } = startDatesSlice.actions;
export default startDatesSlice.reducer;
