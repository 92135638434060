import { createSlice } from '@reduxjs/toolkit';

// import { userEventsService } from '_services';

// export const getuserEventsAction = createAsyncThunk(
//   'content/user/get',
//   async (payload) => {
//     const response = await userEventsService.getuserEvents(
//       payload.id,
//       payload.location
//     );
//     return response;
//   }
// );

const urlParams = new URLSearchParams(window.location.search);

const userSlice = createSlice({
  name: 'user',
  initialState: {
    servicesWanted: [],
    filters: {
      electricity: '',
      telecom: '',
      insurance: '',
      movers: '',
      security: '',
    },
    cart: {
      electricity: null,
      telecom: null,
      insurance: null,
      movers: null,
      security: null,
    },
    cartCopy: {
      electricity: null,
      telecom: null,
      insurance: null,
      movers: null,
      security: null,
    },
    userInfo: { lat: null, lng: null },
    searchedAddress: '',
    previousAddress: '',
    billingAddress: '',
    zip: '',
    loading: 'idle',
    answers: {
      electricity: {
        questionOne: '',
        questionTwo: '',
        questionThree: '',
      },
      telecom: {
        questionOne: '',
        questionTwo: '',
        questionThree: '',
      },
      insurance: {
        questionOne: '',
        questionTwo: '',
        questionThree: '',
      },
      movers: {
        questionOne: '',
        questionTwo: '',
        questionThree: '',
      },
      security: {
        questionOne: '',
        questionTwo: '',
        questionThree: '',
      },
    },
    serviceDate: urlParams.get('moveInDate') || '',
    questions: {
      electricity: null,
      telecom: null,
      insurance: null,
      movers: null,
      security: null,
    },
    answersToQuestions:[
      
    ],
    referrer: 'N/A',
  },
  reducers: {
    updateUserInfoAction(state, action) {
      state.userInfo = action.payload;
    },
    updateSearchedAddressAction(state, action) {
      state.searchedAddress = action.payload;
    },
    updatePreviousAddressAction(state, action) {
      state.previousAddress = action.payload;
    },
    updateBillingAddressAction(state, action) {
      state.billingAddress = action.payload;
    },
    updateZipAction(state, action) {
      state.zip = action.payload;
    },
    updateServiceDateAction(state, action) {
      state.serviceDate = action.payload;
    },
    updateUserAnswersAction(state, action) {
      state.answers[action.payload.service][action.payload.questionNumber] =
        action.payload.answer;
    },
    updateUserQuestionsAction(state, action) {
      state.questions[action.payload.service] = action.payload.question;
    },
    updateUserAnswersToQuestionsAction(state, action) {
      state.answersToQuestions = action.payload.answerToQuestion;
    },
    updateUserCartAction(state, action) {
      state.cart[action.payload.service] = action.payload.plan;
    },
    updateUserCartCopyAction(state, action) {
      state.cartCopy = action.payload;
    },
    clearCartAction(state) {
      state.cart = {
        electricity: null,
        telecom: null,
        insurance: null,
        movers: null,
        security: null,
      };
    },
    updateUserFiltersAction(state, action) {
      state.filters[action.payload.service] = action.payload.filter;
    },
    updateServicesWantedAction(state, action) {
      if (state.servicesWanted.includes(action.payload.service)) {
        state.servicesWanted = state.servicesWanted.filter(
          (service) => service !== action.payload.service
        );
      } else {
        state.servicesWanted.push(action.payload.service);
      }
    },
    updateReferrerAction(state, action) {
      state.referrer = action.payload;
    },
  },
  // extraReducers: {
  //   [getuserEventsAction.fulfilled]: (state, action) => {
  //     state.user = action.payload;
  //   },
  // },
});

export const {
  updateUserInfoAction,
  updateSearchedAddressAction,
  updatePreviousAddressAction,
  updateBillingAddressAction,
  updateZipAction,
  updateServiceDateAction,
  updateUserAnswersAction,
  updateUserQuestionsAction,
  updateUserAnswersToQuestionsAction,
  updateUserCartAction,
  updateUserCartCopyAction,
  clearCartAction,
  updateUserFiltersAction,
  updateServicesWantedAction,
  updateReferrerAction,
} = userSlice.actions;
export default userSlice.reducer;
