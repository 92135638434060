import React from 'react';
import styled from 'styled-components';
import { connect, useSelector } from 'react-redux';
import { Switch, Redirect, Route, useParams } from 'react-router-dom';

import { GlobalStyles } from '../globalStyles';
import {
  Home,
  Questions,
  Results,
  UserInfo,
  Sensitive,
  Confirmation,
  ServiceSelect,
  Review,
  Checkout,
  PrivacyPolicy,
  PartnerPortal,
  OrderTracking
} from '_pages';
import {
  ScrollToTop,
  NavBar,
  SimpleProgress,
  DetailedProgress,
  CartModal,
  Footer,
} from '_components';
import { ViewportProvider } from '_hooks';
import { deviceSize } from '_constants';
import { CartDropdown } from '_components/cartDropdown';
import { CheckoutModal } from '_components/checkoutModal';

const Container = styled.div`
  ${({ pathname }) =>
    pathname !== '/' &&
    `
  display: flex;
  width: 100%;
  padding: 20px;

  @media (max-width: ${deviceSize.tablet}px) {
    padding: 0px;
  }
  `}
`;

const DynamicQuestions = () => {
  const { serviceType } = useParams();
  return <Questions serviceType={serviceType} />;
};

const DynamicResults = () => {
  const { serviceType } = useParams();
  return <Results serviceType={serviceType} />;
};

const Routes = ({ pathname, query }) => {
  const reducer = (state) => state.uiReducer;
  const { isOpenCartDropdown } = useSelector(reducer);
  return (
    <ViewportProvider>
      <ScrollToTop />
      <GlobalStyles pathname={pathname} />
      {pathname !== '/' && <NavBar pathname={pathname} />}
      <CheckoutModal />
      <Switch>
        <Route
          exact
          path="/"
          render={(props) => <Home {...props} query={query} />}
        />
        <Route exact path="/serviceSelect" component={ServiceSelect} />
        <Route
          exact
          path="/questions/:serviceType"
          component={DynamicQuestions}
        />
        <Route exact path="/results/:serviceType" component={DynamicResults} />
        <Route
          path="/review"
          render={(props) => (
            <Review {...props} query={query} pathname={pathname} />
          )}
        />
        <Route exact path="/checkout" component={Checkout} />
        <Route exact path="/confirmation" component={Confirmation} />
        <Route exact path="/privacy" component={PrivacyPolicy} />
        <Route exact path="/partners" component={PartnerPortal} />
        <Route exact path="/orderTracking" component={OrderTracking} />
        <Redirect to="/" />
      </Switch>
      <Footer />
    </ViewportProvider>
  );
};

const mapStateToProps = (state) => ({
  pathname: state.router.location.pathname,
  search: state.router.location.search,
  hash: state.router.location.hash,
  query: state.router.location.query,
});

export default connect(mapStateToProps)(Routes);
