import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { push } from 'connected-react-router';

import { updateSelectedHeroButtonAction } from '_store/ui';
import { AddressAutocomplete } from '_components/addressAutocomplete';
import store from '_store';
import rightArrow from '_images/rightArrow.svg';
import { colors, deviceSize } from '_constants/styleConstants';
import { useSelector, useDispatch } from 'react-redux';
import magnifyingGlass from '_images/magnifyingGlass.png';
import electricityIcon from '_images/electricityIcon.svg';
import telecomIcon from '_images/telecomIcon.svg';
import moversIcon from '_images/moversIcon.svg';
import insuranceIcon from '_images/insuranceIcon.svg';
import securityIcon from '_images/securityIcon.svg';
import blueElectricityIcon from '_images/blueElectricityIcon.svg';
import blueTelecomIcon from '_images/blueTelecomIcon.svg';
import blueMoversIcon from '_images/blueMoversIcon.svg';
import blueInsuranceIcon from '_images/blueInsuranceIcon.svg';
import blueSecurityIcon from '_images/blueSecurityIcon.svg';
import graySecurityIcon from '_images/graySecurityIcon.svg';
import grayElectricityIcon from '_images/grayElectricityIcon.svg';
import grayTelecomIcon from '_images/grayTelecomIcon.svg';
import grayMoversIcon from '_images/grayMoversIcon.svg';
import grayInsuranceIcon from '_images/grayInsuranceIcon.svg';
import spinner from '_images/spinner.gif';
import { NavBar, ResultCard } from '_components';
import { HeroTab } from './components/heroTab';
import { FilterButton } from '_components/filterButton';
import session from 'redux-persist/lib/storage/session';
import {
  updateUserInfoAction,
  updateSearchedAddressAction,
  updatePreviousAddressAction,
  updateBillingAddressAction,
  updateZipAction,
} from '_store/user';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import { updatePlansAction } from '_store/plans';

const Container = styled.div`
  background-color: white;
  color: black;
  width: 100%;
  padding: 50px 150px;

  @media (max-width: ${deviceSize.tablet}px) {
    padding: 20px;
    padding-top: 0px;
  }
`;

const ContainerSubSection = styled.div`
  width: 100%;
  padding-top: 10px;
`;

const LeftAlignContainer = styled.div`
  text-align: left;
`;

// const MainText = styled.div`
//   font-size: 70px;
//   font-weight: bold;
//   line-height: 1.1;

//   @media (max-width: ${deviceSize.tablet}px) {
//     font-size: 30px;
//   }
// `;

// const SubText = styled.div`
//   margin: 25px 0px 55px 0px;
//   font-size: 30px;
//   color: ${colors.darkGray};

//   @media (max-width: ${deviceSize.tablet}px) {
//     font-size: 12px;
//   }
// `;

const AddressRow = styled.div`
  display: flex;
  color: ${colors.darkGray};
  margin-top: 20px;
  align-items: center;
  border-radius: 6px;

  @media (max-width: ${deviceSize.tablet}px) {
    width: 100%;
    flex-direction: column;
  }
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  border: 1px solid ${colors.darkGray};
  border-radius: 6px;
`;

const FilterRow = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  padding-bottom: 20px;
  flex-wrap: wrap;


  @media (max-width: ${deviceSize.tablet}px) {
    width: 100%;
    justify-content: center;
  }
`;

const SpinnerRow = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  padding-bottom: 20px;
  justify-content: center;
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex: ${({ flex }) => flex && flex};

  @media (max-width: ${deviceSize.tablet}px) {
    width: 100%;
  }
`;

const AddressButton = styled.span`
  height: 82px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: ${colors.brand};
  color: white;
  border-radius: 6px;
  box-shadow: ${colors.brand} 0px 10px 40px -10px;
  width:250px;
  @media (max-width: ${deviceSize.tablet}px) {
    height: 60px;
    margin-top: 10px;
  }
`;

const MenuItems = styled.div`
  display: none;

  @media (max-width: ${deviceSize.tablet}px) {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
`;

const ButtonText = styled.span`
  font-size: 16px;
  text-align: center;

  @media (max-width: ${deviceSize.tablet}px) {
    font-size: 14px;
  }
`;

const Arrow = styled.img`
  height: 40px;
`;

const ErrorText = styled.div`
  font-size: 10px;
  color: red;
`;

const ButtonRow = styled.div`
  display: flex;
  justify-content: center;
`;

const MainText = styled.div`
  font-size: 36px;
  font-weight: bold;
  margin: 50px 0px 0px 0px;
  color:#4A4A4A;

  @media (max-width: ${deviceSize.tablet}px) {
    font-size: 24px;
    line-height: 1.2;
    margin: 20px 0px 5px 0px;
  }
`;

const SubText = styled.div`
  font-size: 20px;
  color: #a5a4a4;

  @media (max-width: ${deviceSize.tablet}px) {
    font-size: 12px;
  }
`;

const ButtonSpacing = styled.div`
  margin-left:20px;
`;

const AddressSearchSpacing = styled.div`
  margin-left:15px;
`;

const NumberedCircle = styled.div`
  background-color: ${colors.brand};
  color: white;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const BarColumn = styled.div`
  max-width: 33%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

const BarHeading = styled.div`
  font-size: 22px;
  text-align: center;

  @media (max-width: ${deviceSize.tablet}px) {
    font-size: 14px;
    margin: 10px;
  }
`;

const BarSubheading = styled.div`
  font-size: 14px;
  text-align: center;

  @media (max-width: ${deviceSize.tablet}px) {
    font-size: 10px;
  }
`;

const MagnifyingGlass = styled.img`
  height: 30px;
  margin: 0px 10px;
`;

const CurrentAddressLabel = styled.div`
  font-size: 20px;
  color: #000000;
  font-weight: bold;

  @media (max-width: ${deviceSize.tablet}px) {
    font-size: 12px;
  }
`;

const AddressErrorLabel = styled.div`
  margin-top: 20px;
  font-size: 16px;
  color: #000000;
  font-weight: bold;

  @media (max-width: ${deviceSize.tablet}px) {
    font-size: 10px;
  }
`;

const ChangeAddressButton = styled.div`
  font-size: 18px;
  color: #a5a4a4;
  cursor: pointer;

  @media (max-width: ${deviceSize.tablet}px) {
    font-size: 12px;
  }
`;


export const Hero = () => {
  const uiReducer = (state) => state.uiReducer;
  const { selectedHeroButton } = useSelector(uiReducer);

  const reducer = (state) => state.userReducer;
  const { zip, address, searchedAddress, userInfo, filters } = useSelector(
    reducer
  );

  const plansReducer = (state) => state.plansReducer;
  const allPlans = useSelector(plansReducer).plans;
  const dispatch = useDispatch();

  const [regulated, setRegulated] = useState(false);


  const urlParams = new URLSearchParams(window.location.search);


  const [tagFilters,setTagFilters] = useState({
    electricity:['%'],
    telecom:['%'],
    movers:['%'],
    insurance:['%'],
    security:['%'],
    homewarranty: ['%'],
    pestcontrol: ['%'],
    lawncare: ['%']
  });

  // Extra functionality in case they want the buttons to by dynamic according to the data.
  const [tagFilterButtonLabels,setTagFilterButtonLabels] = useState({
    electricity:['%'],
    telecom:['%'],
    movers:['%'],
    insurance:['%'],
    security:['%'],
    homewarranty: ['%'],
    pestcontrol: ['%'],
    lawncare: ['%']
  });

  const [speedFilters, setSpeedFilters] = useState([]);
  const [speedLimits, setSpeedLimits] = useState({
    electricity: {min: 0, max: 1000000000},
    telecom: {min: 0, max: 1000000000},
    movers: {min: 0, max: 1000000000},
    insurance: {min: 0, max: 1000000000},
    security: {min: 0, max: 1000000000},
    homewarranty: {min: 0, max: 1000000000},
    pestcontrol: {min: 0, max: 1000000000},
    lawncare: {min: 0, max: 1000000000}
  });

  const [addressError, setAddressError] = useState(false);

  const [priceOrder, setPriceOrder] = useState("Price ↑↓");


  useEffect(() => {
    if (searchedAddress) {
      handleSearch(selectedHeroButton);
    } else if (urlParams.get('address')) {
      handleNewAddress(urlParams.get('address'));
    }
  }, []);

  const handleNewAddress = async (address) => {
    try {
      const results = await geocodeByAddress(address);
      const fullAddress = results[0].formatted_address;
      sessionStorage.setItem('address', fullAddress);
      dispatch(updateSearchedAddressAction(fullAddress));
      dispatch(updateZipAction(fullAddress.slice(-10, -5)));
      const latLng = await getLatLng(results[0]);
      dispatch(updateUserInfoAction({ lat: latLng.lat, lng: latLng.lng }));
      handleSearch(selectedHeroButton);
    } catch (error) {
      //invalid address
      setAddressError(true);
    }
  };

  const [plans, setPlans] = useState([]);

  const handleButtonSelect = (selected) => {
    dispatch(updateSelectedHeroButtonAction(selected));
  };

  const filterTelecom = () => {
    const { lat, lng } = userInfo;
    fetch(`/getProviders`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({ lat: lat, lon: lng }),
    })
      .then((response) => response.json())
      .then((providers) => {
        const filteredPlans = allPlans.telecom.filter((plan) => {
          const matchingProvider = providers.find((provider) => {
            if (provider.dbaname.includes(plan.dba) && provider.maxaddown >= plan.speed) {
              return true;
            }
            return false;
          });
          if (matchingProvider) return true;
          return false;
        });
        setPlans(filteredPlans);
        setIsProcessingPlans(false);
      });

  };

  const handleSearch = async (selected) => {
    if (selected !== selectedHeroButton) {
      handleButtonSelect(selected);
    }
    if (!sessionStorage.getItem('address')) {
      return;
    }
    setIsAddressSearchShown(false);
    setAddressError(false);
    setIsProcessingPlans(true);

    applyFiltersToPlans(selected.toLowerCase(), tagFilters, speedLimits, priceOrder);


    // else if (selected === 'Electricity') {
    //   fetch(`/api/deregulated`, {
    //     method: 'POST',
    //     headers: {
    //       'Content-Type': 'application/json',
    //       Accept: 'application/json',
    //     },
    //     body: JSON.stringify({ zip }),
    //   })
    //     .then((response) => response.json())
    //     .then((data) => {
    //       if (data !== null) {
    //         setPlans(allPlans[selected.toLowerCase()]);
    //         setRegulated(false);
    //       } else {
    //         setRegulated(true);
    //         setPlans([]);
    //       }
    //     });
    // }
  };

  const getTagsInPlans = () =>{
    let currentTags = tagFilterButtonLabels;
    for (let serviceType in allPlans){
      for (let plan in allPlans[serviceType]){
        if (allPlans[serviceType][plan].tag){
          currentTags[serviceType].push(allPlans[serviceType][plan].tag);
        }
      }
    }
    for (let serviceType in currentTags){
      currentTags[serviceType] = currentTags[serviceType].filter(onlyUnique);
    }
    setTagFilterButtonLabels(currentTags);
  }

  function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  const handleTagFilter = (tag) =>{
    setIsApplyingFilters(true);
    let currentTagFilters = tagFilters;
    let selectedServiceType = selectedHeroButton.toLowerCase();
    if (currentTagFilters[selectedServiceType].includes(tag)){
      currentTagFilters[selectedServiceType] = currentTagFilters[selectedServiceType].filter((value)=>{return value!=tag});
    } else {
      currentTagFilters[selectedServiceType] = currentTagFilters[selectedServiceType].filter((value)=>{return value=='%'});
      currentTagFilters[selectedServiceType].push(tag);
    }
    setTagFilters(currentTagFilters);
    applyFiltersToPlans(selectedServiceType, currentTagFilters, speedLimits, priceOrder);
  };

  const handleSpeedFilter = (speedTag) =>{
    setIsApplyingFilters(true);
    let currentSpeedFilters = speedFilters;
    if (currentSpeedFilters.includes(speedTag)){
      currentSpeedFilters = [];
    } else {
      currentSpeedFilters = [];
      currentSpeedFilters.push(speedTag);
    }
    setSpeedFilters(currentSpeedFilters);
    let currentLimits = speedLimits;
    if (currentSpeedFilters.length>0){
      let lowerLimits = [];
      let higherLimits = [];
      for (let i = 0; i<currentSpeedFilters.length; i++){
        lowerLimits.push(parseInt(currentSpeedFilters[i].split(",")[0]));
        higherLimits.push(parseInt(currentSpeedFilters[i].split(",")[1]));
      }
      currentLimits.telecom.min = Math.min(...lowerLimits);
      currentLimits.telecom.max = Math.max(...higherLimits);
      setSpeedLimits(currentLimits);
    } else {
      currentLimits.telecom = {min: 0, max: 100000000};
      setSpeedLimits(currentLimits);
    }
    applyFiltersToPlans("telecom", tagFilters, currentLimits, priceOrder);
  };

  const applyFiltersToPlans = (selectedServiceType, tagFilters, speedLimits, currentPriceOrder) => {
    fetch('/api/filterPlansV2', {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        selectedService: selectedServiceType,
        tagFilters: tagFilters,
        speedLimits: speedLimits,
        isRegulated: (!searchedAddress.includes('TX') && selectedServiceType === 'electricity') || (selectedServiceType === 'trash')
      })
    })
    .then((response) => response.json())
    .then((plans) => {
      let filteredPlans = plans.filteredPlans;
      if (selectedServiceType === "telecom"){
        filterTelecomPlansByLocation(filteredPlans, currentPriceOrder);
      } else {
        if (currentPriceOrder !== "Price ↑↓" && selectedServiceType === "electricity") {
          sortPlansByPrice(currentPriceOrder,filteredPlans);
        } else {
          setPlans(filteredPlans);
        }
        setIsApplyingFilters(false);
        setIsProcessingPlans(false);
      }
    })
    .catch((error) => {
      //TODO error message displayed?
      setIsApplyingFilters(false);
      setIsProcessingPlans(false);
    });
  };

  const filterTelecomPlansByLocation = (plans, currentPriceOrder) => {
    const { lat, lng } = userInfo;
    let latitude = lat;
    let longitude = lng;

    fetch(`/api/getProviders`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({ lat: latitude, lon: longitude }),
    })
      .then((response) => response.json())
      .then((providers) => {
        const filteredPlans = plans.filter((plan) => {
          const matchingProvider = providers.find((provider) => {
            if (provider.dbaname.includes(plan.dba) && provider.maxaddown >= plan.speed) {
              return true;
            }
            return false;
          });
          if (matchingProvider) return true;
          return false;
        });
        return filteredPlans;
      })
      .then((filteredPlans)=>{
        if (currentPriceOrder !== "Price ↑↓"){
          sortPlansByPrice(currentPriceOrder,filteredPlans)
        }else{
          setPlans(filteredPlans);
        }
        setIsProcessingPlans(false);
        setIsApplyingFilters(false);
      })
  };


  const handlePriceOrder = () =>{
    setIsApplyingFilters(true);
    let currentPriceOrder = "";
    if (priceOrder === "Price ↑↓") {
      currentPriceOrder = "Price: Low To High";
    } else if (priceOrder === "Price: Low To High") {
      currentPriceOrder = "Price: High To Low";
    } else {
      currentPriceOrder = "Price ↑↓";
    }
    setPriceOrder(currentPriceOrder);
    sortPlansByPrice(currentPriceOrder, plans);
  };

  const sortPlansByPrice = (currentPriceOrder, plans) =>{
    if (currentPriceOrder === "Price: Low To High"){
      plans.sort((a,b) => a.price-b.price);
      setPlans(plans);
      setIsApplyingFilters(false);
    } else if (currentPriceOrder === "Price: High To Low") {
      plans.sort((a,b) => b.price-a.price);
      setPlans(plans);
      setIsApplyingFilters(false);
    } else {
      applyFiltersToPlans(selectedHeroButton.toLowerCase(),tagFilters,speedLimits, currentPriceOrder);
    }
  }

  const hasRecommendedProducts = (plans) =>{
    if (plans){
      for (let i = 0; i<plans.length; i++){
        if (plans[i].topRecommended === true){
          return true;
        }
      }
    }
    return false;
  };

  const taglines = {
    Electricity: {
      main: "Let's tackle your electricity",
      sub: "We'll make this fast & simple",
    },
    Telecom: { main: 'Internet is life', sub: "Let's get you connected" },
    Movers: {
      main: 'Movers to get you moving',
      sub: 'We only work with the best',
    },
    Insurance: { main: 'Insurance is security', sub: "Let's get you protected" },
    Security: {
      main: 'Security is up next',
      sub: "We'll help you protect what matters most",
    },
    Trash: { main: 'Trash is a must', sub: "We've got you covered" },
    PestControl: { main: 'Pests dont stand a chance', sub: 'We put your pest worries to rest'},
    Movers: {main: 'Moving is hard enough', sub: 'Let us do the heavy lifting'},
    Insurance: {main: 'Insurance is just a click away', sub: 'You have the power to protect your future'},
    HomeWarranty: {main: 'Home Warranty at your Fingertips', sub: 'They fix what others "repaired"'},
    LawnCare: {main: 'Keep your lawn maintained', sub: 'Keep your lawn looking great'}
  };

  const [isAddressSearchShown, setIsAddressSearchShown] = useState(true);
  const [isProcessingPlans, setIsProcessingPlans] = useState(false);
  const [isApplyingFilters, setIsApplyingFilters] = useState(false);

  const firstKeyItems = [
    'Telecom', 'Trash', 'PestControl', 'Movers'
  ];

  const realPageItems = [
    'Telecom',
    // "Electricity",
    // "Security"
  ]

  const wfgItems = [
    'Telecom', 'Electricity', 'Security', 'Movers', 'Insurance', 'HomeWarranty', 'LawnCare'
  ]

  const defaultItems = [
    'Telecom', 'Electricity', 'Security', 'Movers', 'Insurance', 'HomeWarranty', 'LawnCare'
  ];

  let currentItems = defaultItems; 

  if (window.location.href.indexOf("firstkey") > -1) { 
    currentItems = firstKeyItems;
  } else if (window.location.href.indexOf("shoprp") > -1) {
    currentItems = realPageItems;
  } else if (window.location.href.indexOf("wfg") > -1) {
    currentItems = wfgItems;
  }

  const menuItems = currentItems.map((service) => (
    <HeroTab
      key={service}
      service={service}
      selected={service === selectedHeroButton}
      onClick={() => {
        if (!isProcessingPlans && !isApplyingFilters){
          handleSearch(service);
        }
      }}
    />
  ));

  const buttonText = (selectedHeroButton) => {
    switch (selectedHeroButton) {
      case 'Telecom':
        return 'TV/Internet'
        break
        
      case 'PestControl':
        return 'Pest Control'
        break

      case 'HomeWarranty':
        return 'Home Warranty'
        break

      case 'LawnCare':
        return 'Lawn Care'
        break

      default:
        return selectedHeroButton
    }



    if (selectedHeroButton === 'Telecom') {
      return 'TV/Internet'
    } else if (selectedHeroButton === 'PestControl') {
      return 'Pest Control'
    }
    
    return selectedHeroButton
  }

  return (
    <>
      <NavBar pathname={'/'} menuItems={menuItems} />
    <Container>
      <MenuItems>
        {menuItems}
      </MenuItems>
      <MainText>{taglines[selectedHeroButton].main}</MainText>
      <SubText>{taglines[selectedHeroButton].sub}</SubText>
      { !isAddressSearchShown ?
      <AddressRow>
        <CurrentAddressLabel>{searchedAddress}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</CurrentAddressLabel>
        <ChangeAddressButton
          onClick={()=>{
            sessionStorage.removeItem('address');
            dispatch(updateSearchedAddressAction(''));
            dispatch(updateZipAction(''));
            dispatch(updateUserInfoAction({ lat: null, lng: null }));
            setIsAddressSearchShown(true);
            }}> Change Address</ChangeAddressButton>
      </AddressRow>
      :''}
      { addressError && <AddressErrorLabel>We are sorry but the address provided was not able to be verified. Please enter the property address again below and click submit to proceed.</AddressErrorLabel> }
      { isAddressSearchShown ?
      <AddressRow>
        <FlexColumn flex="3">
          <FlexRow><AddressSearchSpacing />
            <AddressAutocomplete addressInput="searched" />
          </FlexRow>
        </FlexColumn><ButtonSpacing />
        <FlexColumn flex="2">
          <AddressButton onClick={() => {
            if (searchedAddress){
              handleSearch(selectedHeroButton);
            }
            }}>
            <ButtonText>
              Find My{' '}
              {/* {selectedHeroButton === 'Telecom'
                ? 'TV/Internet'
                : selectedHeroButton} */
                 
                buttonText(selectedHeroButton)
                
              }
            </ButtonText>
          </AddressButton>
        </FlexColumn>
      </AddressRow>
      :''}
      {isProcessingPlans && !isAddressSearchShown &&
      (
        <SpinnerRow>
          <img src={spinner} height="100px" width="100px"></img>
        </SpinnerRow>
      )}
      {!isAddressSearchShown && selectedHeroButton === 'Electricity' && !isProcessingPlans &&
      (
        <FilterRow>
          <a onClick={()=>handleTagFilter("Standard")}><FilterButton filterSelected={tagFilters[selectedHeroButton.toLowerCase()].includes("Standard")} label="Standard" /></a>
          <a onClick={()=>handleTagFilter("Green")}><FilterButton filterSelected={tagFilters[selectedHeroButton.toLowerCase()].includes("Green")} label="Green" /></a>
          <a onClick={()=>handleTagFilter("Prepaid")}><FilterButton filterSelected={tagFilters[selectedHeroButton.toLowerCase()].includes("Prepaid")} label="Prepaid" /></a>
          <a onClick={()=>handleTagFilter("Free Weekends")}><FilterButton filterSelected={tagFilters[selectedHeroButton.toLowerCase()].includes("Free Weekends")} label="Free Weekends" /></a>
          <a onClick={()=>handleTagFilter("Free Nights")}><FilterButton filterSelected={tagFilters[selectedHeroButton.toLowerCase()].includes("Free Nights")} label="Free Nights" /></a>
          <a onClick={()=>handlePriceOrder()}><FilterButton filterSelected={priceOrder === "Price: Low To High" || priceOrder === "Price: High To Low"} label={priceOrder} /></a>
        </FilterRow>
      )
      }
      {!isAddressSearchShown && selectedHeroButton === 'Telecom' && !isProcessingPlans &&
      (
        <FilterRow>
          <a onClick={()=>handleTagFilter("Internet")}><FilterButton filterSelected={tagFilters[selectedHeroButton.toLowerCase()].includes("Internet")} label="Internet" /></a>
          <a onClick={()=>handleTagFilter("TV")}><FilterButton filterSelected={tagFilters[selectedHeroButton.toLowerCase()].includes("TV")} label="TV" /></a>
          <a onClick={()=>handleTagFilter("Bundle")}><FilterButton filterSelected={tagFilters[selectedHeroButton.toLowerCase()].includes("Bundle")} label="Bundle" /></a>
          <a onClick={()=>handleSpeedFilter("1,50")}><FilterButton filterSelected={speedFilters.includes("1,50")} label="Up to 50mbps" /></a>
          <a onClick={()=>handleSpeedFilter("51,100")}><FilterButton filterSelected={speedFilters.includes("51,100")} label="+50mbps to 100mbps" /></a>
          <a onClick={()=>handleSpeedFilter("101,1000000000")}><FilterButton filterSelected={speedFilters.includes("101,1000000000")} label="+100mbps" /></a>
          <a onClick={()=>handlePriceOrder()}><FilterButton filterSelected={priceOrder === "Price: Low To High" || priceOrder === "Price: High To Low"} label={priceOrder} label={priceOrder} /></a>
        </FilterRow>
      )
      }
      {isApplyingFilters && !isAddressSearchShown &&
      (
        <SpinnerRow>
          <img src={spinner} height="100px" width="100px"></img>
        </SpinnerRow>
      )}
      {(selectedHeroButton == 'Electricity' && searchedAddress.includes('TX') || selectedHeroButton != 'Electricity') && hasRecommendedProducts(plans) && !isProcessingPlans && !isAddressSearchShown && !isApplyingFilters &&
        <>
        <ContainerSubSection>
          <SubText>Popular Plans</SubText>
          {plans.filter((plan) => {
            if (filters[selectedHeroButton.toLowerCase()] && plan.topRecommended) {
              return plan.tag === filters[selectedHeroButton.toLowerCase() && plan.topRecommended];
            } else {
              return true;
            }
          })
          .map((plan) => {
            if (plan.topRecommended){
              return <ResultCard key={plan.SFID} plan={plan} />;
            }
          })
          }
        </ContainerSubSection>
        <ContainerSubSection>
          <SubText>Other Available Plans</SubText>
        </ContainerSubSection>
        </>
      }
      {regulated && selectedHeroButton === 'Electricity' && searchedAddress && !isProcessingPlans ? (
        <MainText>
          We&apos;re sorry, but it seems like you live in a regulated area.
        </MainText>
      ) : (
        searchedAddress && !isAddressSearchShown && !isProcessingPlans && !isApplyingFilters &&
        plans
          ?.filter((plan) => {
            if (filters[selectedHeroButton.toLowerCase()]) {
              return plan.tag === filters[selectedHeroButton.toLowerCase()];
            } else {
              return true;
            }
          })
          .map((plan) => {
            return <ResultCard key={plan.SFID} plan={plan} />;
          })
      )}
      { plans.length===0 && !isAddressSearchShown && !isProcessingPlans && !isApplyingFilters &&
        <MainText>
        Sorry, no plans match the selected criteria.
        </MainText>
      }
    </Container>
    </>
  );
};
