import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import checkmark from '_images/checkmark.svg';
import { updateUserAnswersAction } from '_store/user';
import { colors, deviceSize } from '_constants';
import { isMobileDevice } from '_helpers';

const QuestionRow = styled.div`
  display: flex;
  align-items: center;
  margin-left: 10px;
`;

const Question = styled.div`
  margin: 10px;
`;

const Indicator = styled.div`
  height: 20px;
  line-height: 20px;
  color: white;
  width: 20px;
  border-radius: 50%;
  background-color: ${({ selected }) => (selected ? colors.brand : 'black')};
  text-align: center;
`;

const Menu = styled.select`
  width: 100%;
  max-width: 300px;
  height: 70px;
  margin: 10px;

  @media (max-width: ${deviceSize.tablet}px) {
    width: 95%;
    margin: 10px;
  }
`;

const MenuOption = styled.option`
  ${isMobileDevice && 'font-size: 2.8vw;'}
`;

export const Dropdown = ({
  name,
  question,
  answers,
  service,
  questionNumber,
}) => {
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const reducer = (state) => state.userReducer;
  const storedAnswers = useSelector(reducer).answers;

  useEffect(() => {
    if (storedAnswers[service][questionNumber]) {
      setSelectedAnswer(storedAnswers[service][questionNumber]);
    }
  }, [storedAnswers, service, questionNumber]);

  const dispatch = useDispatch();
  const handleClick = (event) => {
    setSelectedAnswer(event.target.value);
    dispatch(
      updateUserAnswersAction({
        answer: event.target.value,
        service,
        questionNumber,
      })
    );
  };
  return (
    <>
      <QuestionRow>
        <Indicator selected={selectedAnswer}>
          {selectedAnswer ? (
            <img style={{ height: '100%' }} src={checkmark} />
          ) : questionNumber === 'questionOne' ? (
            1
          ) : questionNumber === 'questionTwo' ? (
            2
          ) : (
            3
          )}
        </Indicator>
        <Question>{question}</Question>
      </QuestionRow>
      <Menu name={name} id={name} onChange={handleClick}>
        <MenuOption value="" key="select" selected disabled hidden>
          {storedAnswers[service][questionNumber]
            ? storedAnswers[service][questionNumber]
            : 'Select One'}
        </MenuOption>

        {answers.map((answer) => {
          return (
            <MenuOption
              value={answer}
              key={answer}
              service={service}
              questionNumber={questionNumber}
            >
              {answer}
            </MenuOption>
          );
        })}
      </Menu>
    </>
  );
};
