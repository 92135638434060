import React, { useState } from 'react';
import styled from 'styled-components';

import pageData from '_pageData.json';
import checkmark from '_images/checkmark.svg';
import { RadioOption } from './radioOption';
import { colors } from '_constants';

const Container = styled.div`
  margin-bottom: 50px;
`;

const QuestionRow = styled.div`
  display: flex;
  align-items: center;
  margin-left: 10px;
`;

const Question = styled.div`
  margin: 10px;
`;

const Indicator = styled.div`
  height: 20px;
  color: white;
  line-height: 20px;
  width: 20px;
  border-radius: 50%;
  background-color: ${({ selected }) => (selected ? colors.brand : 'black')};
  text-align: center;
`;

export const RadioGroup = ({ question, service, questionNumber }) => {
  const [selectedAnswer, setSelectedAnswer] = useState(null);

  const getSelectedAnswer = (answer) => {
    setSelectedAnswer(answer);
  };

  const { answers } = pageData[service][questionNumber];

  return (
    <Container>
      <QuestionRow>
        <Indicator selected={selectedAnswer}>
          {selectedAnswer ? (
            <img style={{ height: '100%' }} src={checkmark} />
          ) : questionNumber === 'questionOne' ? (
            1
          ) : questionNumber === 'questionTwo' ? (
            2
          ) : (
            3
          )}
        </Indicator>
        <Question>{question}</Question>
      </QuestionRow>
      {answers?.map((answer) => {
        return (
          <RadioOption
            answer={answer.answer || answer}
            service={service}
            questionNumber={questionNumber}
            tag={answer.tag}
            selectAnswer={getSelectedAnswer}
            selectedAnswer={selectedAnswer}
          />
        );
      })}
    </Container>
  );
};
