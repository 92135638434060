import React, { useEffect } from 'react';
import styled from 'styled-components';
import { deviceSize, colors } from '_constants';
import { useDispatch, useSelector } from 'react-redux';
import { ResultCard } from '_components';
import { clearCartAction } from '_store/user';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 100px;
  align-items: center;

  @media (max-width: ${deviceSize.tablet}px) {
    padding: 0px 25px;
  }
`;

const Heading = styled.div`
  font-size: 36px;
  line-height: 1;
  font-weight: 900;
  margin: 20px 0px;
`;

const Section = styled.div`
  font-size: 24px;
  line-height: 1;
  font-weight: 900;
  margin-bottom: 20px;
`;

const Subtitle = styled.div`
  color: ${colors.darkGray};
  font-size: 12px;
`;

export const Main = () => {
  return (
    <Container>
      <Heading>Privacy Policy</Heading>
      <br />
      This privacy policy has been compiled to better serve those who are
      concerned with how their &apos;Personally identifiable information&apos;
      (PII) is being used online. PII, as used in US privacy law and information
      security, is information that can be used on its own or with other
      information to identify, contact, or locate a single person, or to
      identify an individual in context. Please read our privacy policy
      carefully to get a clear understanding of how we collect, use, protect or
      otherwise handle your Personally Identifiable Information in accordance
      with our website.
      <br />
      <br />
      <Section>
        What personal information do we collect from the people that visit
        website?
      </Section>
      When ordering services on our site, as appropriate, you may be asked to
      enter your name, email address, mailing address, phone number, credit card
      information, social security number, Marital Status or other details to
      help you with your experience.
      <br />
      <br />
      <Section>When do we collect information?</Section>
      We collect information from you when you place an order or enter
      information on our site.
      <br />
      <br />
      <Section>How do we use your information?</Section>
      We may use the information we collect from you when you register, place an
      order, sign up to receive additional information on services, or use
      certain other site features in the following ways: • To quickly process
      your transactions.
      <br />
      <br />
      <Section>How do we protect visitor information?</Section>
      Our website is scanned on a regular basis for security holes and known
      vulnerabilities in order to make your visit to our site as safe as
      possible. We use regular Malware Scanning and are PCI DSS Validated. Your
      personal information is contained behind secured networks and is only
      accessible by a limited number of persons who have special access rights
      to such systems and are required to keep the information confidential. In
      addition, all sensitive/credit information you supply is encrypted via
      Secure Socket Layer (SSL) technology. We implement a variety of security
      measures when a user places an order, submits, or accesses their order
      information to maintain the safety of your personal information. We
      “scrub” (keep only the last 4 digits) of sensitive data (such as SS# and
      Drivers Licenses) after the order has been processed (approved or
      rejected).
      <br />
      <br />
      <Section>Do we use &apos;cookies&apos;?</Section>
      Yes. Cookies are small files that a site or its service provider transfers
      to your computer&apos;s hard drive through your Web browser (if you allow)
      that enables the site&apos;s or service provider&apos;s systems to
      recognize your browser and capture and remember certain information. For
      instance, we use cookies to help us remember and process the items in your
      shopping cart (Services selected). They are also used to help us
      understand your preferences based on previous or current site activity,
      which enables us to provide you with improved services. We use cookies to:
      <br />
      <br />
      <li>Help remember and process the items in the shopping cart.</li>
      <br /> You can choose to have your computer warn you each time a cookie is
      being sent, or you can choose to turn off all cookies. You do this through
      your browser (like Internet Explorer) settings. Each browser is a little
      different, so look at your browser&apos;s Help menu to learn the correct
      way to modify your cookies. If you disable cookies, some features will be
      disabled but won&apos;t affect the user&apos;s experience that make your
      site experience more efficient but some of our services will not function
      properly. You will not be able to place orders. We destroy all cookies
      related to your order once the orders have been successfully submitted.
      <br />
      <br />
      <Heading>Third-party disclosure</Heading>
      <Section>
        Do we disclose the information we collect to Third-Parties?
      </Section>
      We transfer to outside parties your name, address, city, town, any form or
      online contact identifier email, phone number, SSN, etc. We engage in this
      practice because: We pass orders to our service providers/partners (we
      don&apos;t process the orders).
      <br />
      <br />
      <Section>Google</Section>
      Google use first-party cookies (such as the Google Analytics cookies) or
      other third-party identifiers together for Web Traffic Trends Opting out:
      Users can set preferences for how Google advertises to you using the
      Google Ad Settings page. Alternatively, you can opt out by visiting the
      Network Advertising initiative opt out page or permanently using the
      Google Analytics Opt Out Browser add on.
      <br />
      <br />
      <Section>COPPA (Children Online Privacy Protection Act)</Section>
      When it comes to the collection of personal information from children
      under 13, the Children&apos;s Online Privacy Protection Act (COPPA) puts
      parents in control. The Federal Trade Commission, the nation&apos;s
      consumer protection agency, enforces the COPPA Rule, which spells out what
      operators of websites and online services must do to protect
      children&apos;s privacy and safety online. We do not specifically market
      to children under 13.
      <br />
      <Section>Fair Information Practices</Section>
      The Fair Information Practices Principles form the backbone of privacy law
      in the United States and the concepts they include have played a
      significant role in the development of data protection laws around the
      globe. Understanding the Fair Information Practice Principles and how they
      should be implemented is critical to comply with the various privacy laws
      that protect personal information.
      <br />
      <br />
      <Section>
        In order to be in line with Fair Information Practices we will take the
        following responsive action, should a data breach occur:
      </Section>
      We will notify the users via email
      <br />
      <br />
      <li>Within 1 business day</li>
      <br />
      We also agree to the Individual Redress Principle, which requires that
      individuals have a right to pursue legally enforceable rights against data
      collectors and processors who fail to adhere to the law. This principle
      requires not only that individuals have enforceable rights against data
      users, but also that individuals have recourse to courts or government
      agencies to investigate and/or prosecute non-compliance by data
      processors.
      <br />
      <br />
      <Section>CAN SPAM Act</Section>
      The CAN-SPAM Act is a law that sets the rules for commercial email,
      establishes requirements for commercial messages, gives recipients the
      right to have emails stopped from being sent to them, and spells out tough
      penalties for violations.
      <br />
      <br />
      <Section>We collect your email address in order to:</Section>
      <li>
        Process orders and to send information and updates pertaining to orders.
      </li>
      <br />
      <br />
      <Section>
        To be in accordance with CANSPAM we agree to the following:
      </Section>
      <ul>
        <li>NOT use false or misleading subjects or email addresses.</li>
        <li>
          Include the physical address of our business or site headquarters.
        </li>
        <li>
          Monitor third-party email marketing services for compliance, if one is
          used.
        </li>
        <li>Honor opt-out/unsubscribe requests quickly.</li>
      </ul>
      <br />
      If at any time you would like to unsubscribe from receiving future emails,
      you can email us at: connect.support@transactly.com
      <br />
      <br />
      <Section>Contacting Us</Section>
      If there are any questions regarding this privacy policy, you may contact
      us using the information below.
      <br />
      <br />
      Cake
      <br />
      14555 Dallas Pkwy
      <br />
      Dallas, Texas 75254
      <br />
      email: connect.support@transactly.com
      <br />
      Last Edited on 10/06/2020
    </Container>
  );
};
