import React, { useEffect, useState } from 'react';
import CountUp from 'react-countup';

import './style.css';

import electricityIcon from '_images/electricityIcon.svg';
import telecomIcon from '_images/telecomIcon.svg';
import insuranceIcon from '_images/insuranceIcon.svg';
import moversIcon from '_images/moversIcon.svg';
import securityIcon from '_images/securityIcon.svg';

export const NewLoader = () => {
  const images = [
    electricityIcon,
    telecomIcon,
    insuranceIcon,
    moversIcon,
    securityIcon,
  ];
  const [counter, setCounter] = useState(0);
  const [imageContent, setImageContent] = useState(images[counter]);

  useEffect(() => {
    const changeImage = () => {
      if (counter === 4) {
        setCounter(0);
        setImageContent(images[0]);
      } else {
        setCounter(counter + 1);
        setImageContent(images[counter + 1]);
      }
    };

    setTimeout(changeImage, 3000);
  }, [counter, images]);

  return (
    <div className="loader">
      <div className="image">
        <img style={{ height: '50px' }} src={imageContent} />
        <div></div>
      </div>
      <CountUp end={100} duration={5} suffix="%"></CountUp>
    </div>
  );
};
