import React from 'react';
// import ReactDOM from 'react-dom';
import Modal from 'react-modal';

import { NewLoader } from './newLoader';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    border: 'none',
    padding: 'none',
  },
};

// const Loader = styled.img`
//   height: 100vh;
//   width: 100vw;
// `;

// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
// Modal.setAppElement('#yourAppElement')

export const LoadingModal = () => {
  // var subtitle;
  const [modalIsOpen, setIsOpen] = React.useState(true);
  // const openModal = () => {
  //   setIsOpen(true);
  // };

  const afterOpenModal = () => {
    setTimeout(() => setIsOpen(false), 5000);
  };

  // const closeModal = () => {
  //   setIsOpen(false);
  // };

  return (
    <div>
      {/* <button onClick={openModal}>Open Modal</button> */}
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        // onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Finding Services..."
      >
        {/* <Loader src={loading} /> */}
        <NewLoader />
      </Modal>
    </div>
  );
};
