import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { push } from 'connected-react-router';

import { ProceedButton, Modal, DetailedProgress, Underline } from '_components';
import store from '_store';
import {
  ResultCard,
  InfoSubmitButton,
  LoadingModal,
  MobileSimpleProgress,
} from '_components';
import { startDatesRegExp } from '_helpers';
import { updateUserFiltersAction } from '_store/user';
import { updateStartDatesAction } from '_store/startDates';
import { deviceSize, colors, zIndexes } from '_constants';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 100px;

  @media (max-width: ${deviceSize.tablet}px) {
    padding: 0px;
  }
`;

const Heading = styled.div`
  margin: 10px auto 20px 10px;

  font-size: 36px;
  line-height: 1;
  font-weight: 900;
`;

const Subtitle = styled.div`
  color: ${colors.darkGray};
  margin-left 10px;
  font-size: 12px;
`;

const ShowAllText = styled.div`
  cursor: pointer;
`;

const StyledInput = styled.input`
  width: 400px;
  height: 60px;
  margin: 10px auto ${({ last }) => (last ? '30px' : '10px')} 10px;

  @media (max-width: ${deviceSize.tablet}px) {
    width: 90%;
    max-width: 280px;
  }
`;

const ErrorText = styled.div`
  ${({ show }) => !show && 'display: none;'}
  font-size: 10px;
  color: red;
  margin-left: 10px;
`;

const findNext = (item, array) => {
  const index = array.indexOf(item);
  return array[index + 1];
};

export const Main = ({ serviceType }) => {
  const [showCart, setShowCart] = useState(false);
  const [plans, setPlans] = useState(null);
  const [clicked, setClicked] = useState(false);

  const reducer = (state) => state.userReducer;
  const { userInfo, filters, servicesWanted, cart } = useSelector(reducer);

  const startDatesReducer = (state) => state.startDatesReducer;
  const { startDates } = useSelector(startDatesReducer);

  const generateString = () => {
    let text = '';
    for (const property in cart) {
      if (cart[property]) {
        text += `${property}=${cart[property].plan}&`;
      }
    }
    return text.slice(0, -1);
  };

  useEffect(() => {
    if (serviceType === 'telecom') {
      const { lat, lng } = userInfo;
      fetch(`/getProviders`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        body: JSON.stringify({ lat: lat, lon: lng }),
      })
        .then((response) => response.json())
        .then((providers) => {
          fetch(`/api/${serviceType}`, {
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
            },
          })
            .then((response) => response.json())
            .then((plans) => {
              const filteredPlans = plans.filter((plan) => {
                const matchingProvider = providers.find((provider) => {
                  if (provider.dbaname.includes(plan.dba)) {
                    return true;
                  }
                  return false;
                });

                if (matchingProvider) return true;
                return false;
              });

              setPlans(filteredPlans);
            });
        });
    } else {
      fetch(`/api/${serviceType}`, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      })
        .then((response) => response.json())
        .then((plans) => {
          setPlans(plans);
        });
    }
  }, [serviceType, userInfo]);

  const setRoutes = () => {
    const nextPage = findNext(serviceType, servicesWanted);
    if (nextPage) {
      return `../questions/${nextPage}`;
    } else {
      return `../review/?${generateString()}`;
    }
  };
  const dispatch = useDispatch();

  const showAll = () => {
    dispatch(updateUserFiltersAction({ service: serviceType, filter: null }));
  };

  const allowProgress = () => {
    if (cart[serviceType]) {
      if (startDatesRegExp.test(startDates[serviceType])) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };

  //allowing blank info for now
  // const checkErrors = () => {
  //   if (allowProgress()) {
  //     store.dispatch(push(setRoutes()));
  //   } else {
  //     setClicked(true);
  //   }
  // };

  const checkErrors = () => {
    store.dispatch(push(setRoutes()));
  };

  const toggleCart = () => {
    setShowCart(!showCart);
  };

  const modalStyles = {
    content: {
      height: '100%',
      width: '300px',
      top: '0px',
      left: 'auto',
      right: '0px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-around',
      alignItems: 'center',
      zIndex: zIndexes.OVERLAY,
    },
  };

  return (
    <Container>
      <MobileSimpleProgress />
      <LoadingModal />
      <Modal
        isOpen={showCart}
        customStyles={modalStyles}
        closeModal={toggleCart}
      >
        <DetailedProgress pathname="cart" />
        <ProceedButton text="Close" to="#" onClick={toggleCart} />
      </Modal>
      <Heading>Now the good stuff.</Heading>
      <Subtitle>Choose the option that&apos;s best for you.</Subtitle>
      <Underline />
      {plans
        ?.filter((plan) => {
          if (filters[serviceType]) {
            return plan.tag === filters[serviceType];
          } else {
            return true;
          }
        })
        .map((plan) => {
          return <ResultCard plan={plan} />;
        })}
      {filters[serviceType] && (
        <ShowAllText onClick={showAll}>Show All Plans</ShowAllText>
      )}
      <StyledInput
        placeholder="When would you like services to start? (MM/DD/YYYY)"
        value={
          startDates[serviceType] === 'No start date specified.'
            ? null
            : startDates[serviceType]
        }
        onChange={(event) => {
          dispatch(
            updateStartDatesAction({
              ...startDates,
              [serviceType]: event.target.value,
            })
          );
        }}
      ></StyledInput>
      {/* <ProceedButton to={setRoutes} /> */}

      {/* allowing blank info for now */}
      <InfoSubmitButton
        // color={allowProgress() ? colors.brand : colors.fadedBrand}
        color={colors.brand}
        onClick={checkErrors}
        cursor="pointer"
        // cursor={allowProgress() ? 'pointer' : 'not-allowed'}
        text="Save and Continue"
      />
      <ErrorText show={clicked && !allowProgress()}>
        Please select a date to begin service.
      </ErrorText>
    </Container>
  );
};
