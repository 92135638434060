import React, { useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import {Link} from 'react-router-dom';

import { updateUserCartAction, updateUserQuestionsAction } from '_store/user';
import { colors, deviceSize } from '_constants';
import { isMobileDevice, post } from '_helpers';
import { AddButton } from './addButton';
import store from '_store';
import { push } from 'connected-react-router';
import { update } from 'lodash';

const Card = styled.div`
  display: flex;
  flex-direction: column;
  ${({ condense }) => !condense && 'margin: 10px 0px'};
  width: 100%;
  background-color: white;
  border: 1px solid gray;
  border-radius: 6px;
  font-size: 12px;
  transition: all 0.25s;

  &:hover {
    box-shadow: 0 6px 40px rgba(210, 211, 212, 0.5);
    transform: translateY(-2px);
  }
`;

const InfoRow = styled.div`
  display: flex;
  justify-content: space-around;
  padding: 10px;
  ${({ clickable }) => clickable && 'cursor: pointer;'}
  color: ${({ color }) => color};
  font-size: ${({ fontSize }) => fontSize};

  @media (max-width: ${deviceSize.tablet}px) {
    font-size: initial;
  }
`;

const CardSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  padding: 5px;
  ${({ clickable }) => clickable && 'cursor: pointer;'}
  font-size: 16px;

  @media (max-width: ${deviceSize.tablet}px) {
    font-size: initial;
  }
`;

const CardDetailsSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  font-size: 16px;

  @media (max-width: ${deviceSize.tablet}px) {
    font-size: initial;
  }
`;

const CardImage = styled.div`
  background-image: ${(props) =>
    props.frontierInternet
      ? `url('/companyLogos/frontierInternet.png')`
      : props.backgroundImage};
  height: 70%;
  width: 70%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`;

export const ResultCard = ({ plan, condense }) => {
  const image = `url('${plan.logo}')`; 
  const dispatch = useDispatch();
  const updateCart = (plan) => {

    if (plan.type == "telecom") {
      if (cart[plan.tag]?.plan === plan.plan) {
        let questions = null;
        dispatch(updateUserCartAction({ service: plan.tag, plan: null }));
        dispatch(updateUserQuestionsAction({ service: plan.tag, question: null}));
      } else {
        dispatch(updateUserCartAction({ service: plan.tag, plan: plan }));
        updateQuestions(plan.SFID, plan.tag);
      }
    } else {
      if (cart[plan.type]?.plan === plan.plan) {
        let questions = null;
        dispatch(updateUserCartAction({ service: plan.type, plan: null }));
        dispatch(updateUserQuestionsAction({ service: plan.type, question: null}));
      } else {
        dispatch(updateUserCartAction({ service: plan.type, plan: plan }));
        updateQuestions(plan.SFID, plan.type);
      }
    }
  };

  const updateQuestions = (productSFID, service) => {
    // let productId = {"id":productSFID};
    // fetch('/api/getQuestionsByVendor', {
    //   method: 'post',
    //   headers: {
    //     'Content-Type': 'application/json',
    //     Accept: 'application/json',
    //   },
    //   body: JSON.stringify(productId),
    // })
    //   .then((response) => response.json())
    //   .then((res) => {
    //     if (res.questions.length === 0){
    //       dispatch(updateUserQuestionsAction({service: service, question: null}));
    //     }
    //     else{
    //       dispatch(updateUserQuestionsAction({service: service, question: res.questions}));
    //     }
    //   });
  };

  const reducer = (state) => state.userReducer;
  const { cart } = useSelector(reducer);

  const routerReducer = (state) => state.router;
  const { location } = useSelector(routerReducer);
  const { pathname } = location;

  const [showMoreInfo, setShowMoreInfo] = useState(false);
  return (
    <Card condense={condense}>
      <InfoRow>
        <CardSection>
        <CardImage
              backgroundImage={image}
              frontierInternet={
                plan.company.includes('Frontier') && plan.type === 'telecom'
              }> 
            </CardImage>
        </CardSection>
        <CardSection>{plan.plan}</CardSection>
        { plan.SFID != '01t5w00000GFyxTAAT' && plan.SFID != '01t5w00000GFxM0AAL' ? (
          <CardSection>
            { plan.type === 'electricity'
              ? `${plan.price}${plan.price_unit}`
              : <>
                  { plan.type === 'insurance'
                    ? ''
                    : `From $${plan.price} /${plan.price_unit}`
                  }
                </>
            }
        </CardSection>
        ) : <CardSection></CardSection>}
        {!isMobileDevice && !condense && (
          <>
            <CardDetailsSection>
              {plan.detail1 && <div>{plan.detail1}<br></br></div>}
              {!plan.detail1 &&
                !plan.detail2 &&
                !plan.detail3 &&
                'No extra details to show.'}
            </CardDetailsSection>
          </>
        )}
        {pathname !== '/confirmation' && (
          <CardSection>
            <AddButton to="#"
              onClick={() => updateCart(plan)}
              productSelected={plan.type == "telecom" ? cart[plan.tag]?.plan === plan.plan : cart[plan.type]?.plan === plan.plan}
            />
          </CardSection>
        )}
      </InfoRow>
      <InfoRow
        clickable
        color={colors.darkGray}
        fontSize="16px"
        onClick={() => setShowMoreInfo(!showMoreInfo)}
      >
        {showMoreInfo ? 'Less' : 'More Info'}
      </InfoRow>
      {showMoreInfo && plan.efl ? <InfoRow ><a href={plan.efl} target="_blank" rel="noopener noreferrer">Electricity Fact Label</a></InfoRow> : ''}
      {showMoreInfo &&
        (plan.promotions || plan.detail2 || plan.detail3 ? (
          <div>
            <InfoRow fontSize="16px">{plan.detail2}</InfoRow>
            <InfoRow fontSize="16px">{plan.detail3}</InfoRow>
            <InfoRow fontSize="16px">{plan.promotions}</InfoRow>
          </div>
        ) : (
          <InfoRow>No extra info to show.</InfoRow>
        ))}
    </Card>
  );
};
