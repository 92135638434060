import React, { useState } from 'react';
import styled from 'styled-components';

import rightArrow from '_images/rightArrow.svg';
import risingLine from '_images/risingLine.svg';
import fallingLine from '_images/fallingLine.svg';
import { colors, deviceSize } from '_constants/styleConstants';
import { Card } from './components/card';
import { Circle } from '../components/circle';
import { SectionTitle } from '../components/sectionTitle';
import { isMobileDevice } from '_helpers';
import { RightArrow } from '_components';

const Container = styled.div`
  width: 100%;
  padding: 100px;
  background-color: ${colors.parchment};

  @media (max-width: ${deviceSize.tablet}px) {
    padding: 20px;
  }
`;

const TopRow = styled.div`
  display: flex;
  margin-bottom: 100px;

  @media (max-width: ${deviceSize.tablet}px) {
    flex-direction: column;
  }
`;

const BottomRow = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const TitleText = styled.div`
  width: 25%;
  margin-right: 50px;
  flex: 2;

  @media (max-width: ${deviceSize.tablet}px) {
    width: initial;
    margin-right: 10px;
  }
`;

const Subtext = styled.div`
  font-size: 50px;
  font-weight: bold;
  width: 90%;
  line-height: 1.1;

  @media (max-width: ${deviceSize.tablet}px) {
    font-size: 32px;
    margin-bottom: 20px;
  }
`;

const DetailTextRow = styled.div`
  flex: 3;
  margin-right: 20%;
`;

const DetailText = styled.div`
  color: ${colors.darkGray};
  width: 50%;

  @media (max-width: ${deviceSize.tablet}px) {
    width: initial;
  }
`;

const LearnMoreRow = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  ${({ hover }) =>
    hover &&
    `box-shadow: 0 6px 40px rgba(210, 211, 212, 0.5);
  transform: translateY(-2px);`}
`;

const LearnMoreText = styled.div`
  font-size: 12px;
  color: ${({ color }) => color};
  margin-left: 10px;
  ${({ hover }) => hover && `color: ${colors.brand};`}
`;

const Arrow = styled(RightArrow)`
  height: 25px;
  vertical-align: middle;
  transition: 1s;
  ${({ hover }) => hover && 'margin-left: 5px;'}
`;

const Line = styled.img`
  height: 100px;
  ${({ number }) =>
    (number === '1' || number === '3') && 'position: relative; bottom: 15px;'};

  @media (max-width: ${deviceSize.tablet}px) {
    display: none;
  }
`;

const StyledHr = styled.hr`
  margin: 20px 0px;
`;

const circles = [
  {
    number: '1',
    mainText: 'Share',
    detail: 'Enter the service address and pick which services you want.',
  },
  {
    number: '2',
    mainText: 'Compare',
    detail:
      'We instantly show you options from top providers to compare for free.',
  },
  {
    number: '3',
    mainText: 'Save',
    detail: 'Choose your favorites and secure the best rates in an instant.',
  },
  {
    number: '4',
    mainText: 'Enjoy',
    detail: "Sit back and relax knowing you've tackled everything.",
  },
];

export const HowItWorks = ({ className }) => {
  const [hover, setHover] = useState(false);
  const handleHover = () => {
    setHover(!hover);
  };
  const scrollToTop = () => {
    document.getElementById('address').scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'center',
    });
    setTimeout(() => document.getElementById('address').focus(), 1000);
  };
  return (
    <Container>
      <TopRow>
        <TitleText>
          <SectionTitle number="02" title="HOW IT WORKS" />
          <Subtext>Connect everything in minutes</Subtext>
        </TitleText>
        <DetailTextRow>
          <DetailText>
            Technology has made buying the right car, house - the right anything
            really - super easy. Why would connecting home services & utilities
            any different? Welcome to Cake--the modern way to get connected.
          </DetailText>
          <StyledHr />

          <LearnMoreRow
            onMouseEnter={handleHover}
            onMouseLeave={handleHover}
            hover={hover}
            onClick={scrollToTop}
          >
            <Circle color={hover ? colors.brand : colors.darkGray} />
            <LearnMoreText hover={hover} color="black">
              Get started{' '}
              <Arrow
                className={className}
                color={hover && colors.brand}
                hover={hover}
              />
            </LearnMoreText>
          </LearnMoreRow>
        </DetailTextRow>
      </TopRow>
      <BottomRow>
        {circles.map((circle) => {
          return (
            <>
              <Card
                number={circle.number}
                mainText={circle.mainText}
                detail={circle.detail}
              />
              {circle.number === '1' || circle.number === '3' ? (
                <Line number={circle.number} src={fallingLine} />
              ) : (
                circle.number === '2' && <Line src={risingLine} />
              )}
            </>
          );
        })}
      </BottomRow>
    </Container>
  );
};
