import React from 'react';

export const PhoneIcon = ({
  color,
  className,
  width = '15',
  height = '16',
  viewBox = '0 0 15 16',
}) => {
  return (
    <svg
      className={className}
      height={height}
      viewBox={viewBox}
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Call icon</title>
      <path
        d="M9.5,15.77C4.5,18-3.35,3.19,1.56.69L3,0,5.38,4.55,4,5.23C2.5,6,5.58,12,7.1,11.23l1.4-.68,2.4,4.53ZM8.42,6.83l2,.58a2,2,0,0,0-.16-1.55A2.13,2.13,0,0,0,9,4.86Zm1.23-4a4.27,4.27,0,0,1,2.56,2A4.08,4.08,0,0,1,12.55,8l-1.22-.35a2.92,2.92,0,0,0-.24-2.26A3,3,0,0,0,9.29,4ZM10.3.67a6.51,6.51,0,0,1,3.91,3.1,6.3,6.3,0,0,1,.52,4.9L13.5,8.3a5.06,5.06,0,0,0-.41-3.92A5.29,5.29,0,0,0,10,1.89Z"
        fill={color}
        fillRule="evenodd"
        transform="translate(-0.01)"
      ></path>
    </svg>
  );
};
