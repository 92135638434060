import React, { useState, useEffect } from 'react';
import { push } from 'connected-react-router';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';

import store from '_store';
import { InfoSubmitButton, MobileSimpleProgress, Underline } from '_components';
import { deviceSize, colors } from '_constants';
import { updateContactInfoAction } from '_store/contactInfo';
import { phoneRegExp, emailRegExp } from '_helpers';
import { AddressAutocomplete } from '_components/addressAutocomplete';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 100px;

  @media (max-width: ${deviceSize.tablet}px) {
    padding: 0px;
  }
`;

const Heading = styled.div`
  margin: 10px auto 20px 10px;

  font-size: 36px;
  line-height: 1;
  font-weight: 900;
`;

const Subtitle = styled.div`
  color: ${colors.darkGray};
  margin-left 10px;
  font-size: 12px;
`;

const StyledInput = styled.input`
  width: 400px;
  height: 60px;
  margin: 10px auto 10px 10px;

  @media (max-width: ${deviceSize.tablet}px) {
    width: 90%;
    max-width: 280px;
  }
`;

const StyledUnitInput = styled.input`
  width: 200px;
  height: 60px;
  margin: 10px auto 10px 10px;

  @media (max-width: ${deviceSize.tablet}px) {
    width: 45%;
    max-width: 140px;
  }
`;

const ErrorText = styled.div`
  ${({ show }) => !show && 'display: none;'}
  font-size: 10px;
  color: red;
  margin-left: 10px;
`;

const Indicator = styled.div`
  height: 15px;
  width: 15px;
  border-radius: 15px;
  background-color: ${({ hasUnit }) => (hasUnit ? colors.brand : 'white')};
  border: 1px solid ${colors.darkGray};
  cursor: pointer;
`;

const UnitInfo = styled.div`
  display: flex;
  align-items: center;
  margin: 10px auto 10px 10px;

  @media (max-width: ${deviceSize.tablet}px) {
  }
`;

const UnitText = styled.div`
  margin-left: 10px;
`;

export const Main = () => {
  const [hasUnit, setHasUnit] = useState(false);

  const contactInfoReducer = (state) => state.contactInfoReducer;
  const { contactInfo } = useSelector(contactInfoReducer);
  const { firstName, lastName, phone, email, unit } = contactInfo;
  const dispatch = useDispatch();

  const allowProgress = () => {
    if (
      firstName &&
      lastName &&
      phoneRegExp.test(phone) &&
      emailRegExp.test(email)
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  };

  const checkErrors = () => {
    setClicked(true);
    if (!disabled) {
      store.dispatch(push('../info/2'));
    }
  };

  async function updateContactInfo(event) {
    const { name } = event.target;
    return dispatch(
      updateContactInfoAction({ ...contactInfo, [name]: event.target.value })
    );
  }

  const getContactInfo = (event) => {
    updateContactInfo(event).then(allowProgress);
  };

  const [disabled, setDisabled] = useState(true);
  const [clicked, setClicked] = useState(false);

  useEffect(() => {
    allowProgress();
  });

  return (
    <Container>
      <MobileSimpleProgress />
      {/* <Heading>Final Details about you!</Heading> */}
      <Heading>Tell us a little about yourself.</Heading>
      <Subtitle>We take your privacy seriously.</Subtitle>
      <Subtitle last>Your information is always secure with us.</Subtitle>
      <Underline />
      <StyledInput
        name="firstName"
        value={firstName}
        placeholder="First Name"
        onChange={getContactInfo}
      />
      <ErrorText show={clicked && !firstName}>First name is required</ErrorText>
      <StyledInput
        name="lastName"
        value={lastName}
        placeholder="Last Name"
        onChange={getContactInfo}
      />
      <ErrorText show={clicked && !lastName}>Last name is required</ErrorText>
      <StyledInput
        name="phone"
        value={phone}
        placeholder="Phone"
        type="tel"
        onChange={getContactInfo}
      />
      <ErrorText show={clicked && !phoneRegExp.test(phone)}>
        Invalid phone number
      </ErrorText>
      <StyledInput
        name="email"
        value={email}
        placeholder="Email"
        type="email"
        onChange={getContactInfo}
      />
      <ErrorText show={clicked && !emailRegExp.test(email)}>
        Invalid Email
      </ErrorText>
      <AddressAutocomplete />
      <UnitInfo>
        <Indicator
          onClick={() => {
            setHasUnit(!hasUnit);
          }}
          hasUnit={hasUnit}
        />
        <UnitText>I have a unit number</UnitText>
      </UnitInfo>
      {hasUnit && (
        <StyledUnitInput
          name="unit"
          value={unit}
          placeholder="Unit #"
          type="unit"
          onChange={getContactInfo}
        />
      )}
      <InfoSubmitButton
        color={disabled ? colors.fadedBrand : colors.brand}
        onClick={checkErrors}
        cursor={disabled ? 'not-allowed' : 'pointer'}
      />
    </Container>
  );
};
