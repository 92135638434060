import React, { useEffect } from 'react';
import styled from 'styled-components';
import { deviceSize, colors } from '_constants';
import { useDispatch, useSelector } from 'react-redux';
import { clearCartAction } from '_store/user';
import { useState } from 'react';
import { OpportunityCard } from './components/opportunityCard'
import { Modal } from '_components/modal';
import { zIndexes } from '_constants';
import { isMobileDevice } from '_helpers';
import { InputContainer } from '_pages/checkout/components/inputContainer';
import { Link } from 'react-router-dom';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 50px 200px;
  align-items: center;

  @media (max-width: ${deviceSize.tablet}px) {
    padding: 20px;
  }
`;

const ExtraInfoContainer = styled.div`
  width: 100%;
  text-align: left;
`;

const Heading = styled.div`
  font-size: 26px;
  line-height: 1;
  font-weight: 900;
  margin-bottom: 20px;

  @media (max-width: ${deviceSize.tablet}px) {
    font-size: 20px;
  }

`;

const Subtitle = styled.div`
  color: ${colors.darkGray};
  font-size: 20px;

  @media (max-width: ${deviceSize.tablet}px) {
    font-size: 12px;
  }
`;

const Button = styled.span`
  height: 40px;
  width: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: ${colors.brand};
  color: white;
  border-radius: 6px;
`;

const ButtonText = styled.span`
  font-size: 16px;
  text-align: center;

  @media (max-width: ${deviceSize.tablet}px) {
    font-size: 14px;
  }
`;

const InfoRow = styled.div`
  display: flex;
  justify-content: space-around;
  padding: 5px;
  ${({ clickable }) => clickable && 'cursor: pointer;'}
  color: ${({ color }) => color};
  font-size: ${({ fontSize }) => fontSize};
`;

const StyledInput = styled.input`
  border: 1px solid rgb(210, 211, 212);
  height: 40px;
  margin-right: ${({ space }) => space && '5px'};
  border-radius: 6px;
  width: 100%;
  padding: 5px;
`;

export const Main = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(clearCartAction());
  }, [dispatch]);

  const [hasInformationAboutOrders, sethasInformationAboutOrders] = useState(false);
  const [loadingResponse, setLoadingResponse] = useState(true);
  const [responseInfo, setResponseInfo] = useState();
  const [currentOrders, setCurrentOrders] = useState();
  const [isAPICalled, setIsAPICalled] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [orderNumber, setOrderNumber] = useState();

  const testOrders = [
    {
      "order_number": "156156156",
      "opportunities": [
        {
          "vendor": "Bellhops",
          "unit_of_measure": "hr",
          "stage": "Order Placed",
          "stage_detail":null,
          "product": "Product 1",
          "price": 85.0,
          "logo": "https://getcake--agsdev.my.salesforce.com/sfc/dist/version/download/?oid=00D2D0000000R7J&ids=0682D000000hzXF&d=%2Fa%2F2D0000004MaB%2FmBbdg4OYgiF0zzQ5X2TwDfpm6tj5W2MIBiIvymwiUTs&asPdf=false",
          "last_modified_date": "2021-02-01T20:03:23.000Z",
          "family": "Movers",
          "created_date": "2021-01-28T16:33:08.000Z"
        },
        {
          "vendor": "AT&T",
          "unit_of_measure": "month",
          "stage": "Processing",
          "stage_detail":"Provider Scheduling",
          "product": "Product 2",
          "price": 38,
          "logo": "https://upload.wikimedia.org/wikipedia/commons/3/31/AT%26T_logo_2016.svg",
          "last_modified_date": "2021-02-01T20:03:23.000Z",
          "family": null,
          "created_date": "2021-01-28T16:33:08.000Z"
        },
        {
          "vendor": "QA-VendorTwo",
          "unit_of_measure": "Each",
          "stage": "Processing",
          "stage_detail": "Task",
          "product": "Product 3",
          "price": 2.0,
          "order_number": "156156156",
          "logo": "https://www.mymovingreviews.com/logo-the-move-place-8660.jpg",
          "last_modified_date": "2021-02-01T20:03:23.000Z",
          "family": null,
          "created_date": "2021-01-28T16:33:08.000Z"
        },
      ]
    },
    {
      "order_number": "21493874982",
      "opportunities": [
        {
          "vendor": "Bellhops",
          "unit_of_measure": "hr",
          "stage": "Completed",
          "stage_detail":null,
          "product": "Product 1",
          "price": 85.0,
          "logo": "https://getcake--agsdev.my.salesforce.com/sfc/dist/version/download/?oid=00D2D0000000R7J&ids=0682D000000hzXF&d=%2Fa%2F2D0000004MaB%2FmBbdg4OYgiF0zzQ5X2TwDfpm6tj5W2MIBiIvymwiUTs&asPdf=false",
          "last_modified_date": "2021-02-01T20:03:23.000Z",
          "family": "Movers",
          "created_date": "2021-01-28T16:33:08.000Z"
        },
        {
          "vendor": "AT&T",
          "unit_of_measure": "month",
          "stage": "Order Placed",
          "stage_detail":null,
          "product": "Product 2",
          "price": 38,
          "logo": "https://upload.wikimedia.org/wikipedia/commons/3/31/AT%26T_logo_2016.svg",
          "last_modified_date": "2021-02-01T20:03:23.000Z",
          "family": null,
          "created_date": "2021-01-28T16:33:08.000Z"
        },
        {
          "vendor": "QA-VendorTwo",
          "unit_of_measure": "Each",
          "stage": "Closed Lost",
          "stage_detail": null,
          "product": "Product 3",
          "price": 2.0,
          "order_number": "156156156",
          "logo": "https://www.mymovingreviews.com/logo-the-move-place-8660.jpg",
          "last_modified_date": "2021-02-01T20:03:23.000Z",
          "family": null,
          "created_date": "2021-01-28T16:33:08.000Z"
        },
      ]
    }
  ];


  const getOrderWithTrackingNumber = (trackingNumber) =>{
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Cookie", "BrowserId=vkrSBB7PEeuUtyX3aioJhA");
    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };
    fetch('https://getcake.my.salesforce-sites.com/OrderTracker/services/apexrest/TrackByKey?key='+trackingNumber, requestOptions)
    .then(response => response.text())
    .then(result => JSON.parse(result))
    .then(res => {
      setIsAPICalled(true);
      if (res){
        setIsModalOpen(false);
        setResponseInfo(res);
        sethasInformationAboutOrders(true);
        setLoadingResponse(false);
        return processOpportunities(res.opportunities);
      }
      else{
        setTimeout(()=>{
          setIsModalOpen(true);
        },500);
        sethasInformationAboutOrders(false);
        setLoadingResponse(false);
      }
    }).then( orders => {
      setCurrentOrders(orders);
    })
    .catch(error => {
      sethasInformationAboutOrders(false);
      setLoadingResponse(false);
      setTimeout(()=>{
        setIsModalOpen(true);
      },500);
    });
  };

  const urlParams = new URLSearchParams(window.location.search);

  useEffect(()=>{
    if (urlParams.get('trackByKey')){
      if(!isAPICalled){
        getOrderWithTrackingNumber(urlParams.get('trackByKey'));
      }
    }
    else{
      setIsModalOpen(true);
    }
  });

  const processOpportunities = (oppsToProcess) =>{
    let processedOrders = [];
    let listOfOrders = [];
    for (let i=0; i<oppsToProcess.length; i++){
      if (!listOfOrders.includes(oppsToProcess[i].order_number)){
        listOfOrders = listOfOrders.concat(oppsToProcess[i].order_number);
      }
    }
    if (listOfOrders){
      for(let i=0; i<listOfOrders.length; i++){
        let oppsForCurrentOrder = [];
        for(let j=0; j<oppsToProcess.length; j++){
          if(oppsToProcess[j].order_number === listOfOrders[i]){
            oppsToProcess[j].stage = processOppStageName(oppsToProcess[j].stage);
            oppsForCurrentOrder = oppsForCurrentOrder.concat(oppsToProcess[j]);
          }
        }
        let currentOrder = {
          "order_number":listOfOrders[i],
          "opportunities": oppsForCurrentOrder
        }
        processedOrders.push(currentOrder);
      }
    }
    return processedOrders;
  };

  const processOppStageName = (oppStageName) =>{
    let status = oppStageName;
    switch (oppStageName){
      case 'Processing':
        status = 'Processing';
        break;
      case 'Pending Vendor Confirmation':
        status = 'Processing';
        break;
      case 'On-hold':
        status = 'Processing';
        break;
      case 'Closed Won':
        status = 'Completed';
        break;
      case 'Working':
        status = 'Order Placed';
        break;
      case 'Options sent':
        status = 'Order Placed';
        break;
      case 'Doc sent':
        status = 'Order Placed';
        break;
      case 'Qualification':
        status = 'Order Placed';
        break;
    }
    return status;
  }

  const modalStyles = {
    content: {
      height: isMobileDevice ? "28%": "28%",
      width: isMobileDevice ? "100%": "40%",
      top: '50%',
      left: '50%',
      transform : "translate(-50%, -50%)",
      display: 'flex',
      position: 'fixed',


      flexDirection: 'column',
      alignItems: 'center',
      zIndex: zIndexes.OVERLAY,
    },
  };

  return (
      <Container>
      { hasInformationAboutOrders && currentOrders
      && (
        <ExtraInfoContainer>
          <Heading>Order details</Heading>
          <Subtitle>{responseInfo["firstname"]+" "+responseInfo["lastname"]}</Subtitle>
          <Subtitle>Address:&nbsp;
          {
            (responseInfo["Street"] ? responseInfo["Street"] : '') /*+
            (responseInfo["State"] && (responseInfo["State"].split(",").length<3 && responseInfo["apartment"] ? ", Apt/Unit: " + responseInfo["apartment"] : ''))+
            (responseInfo["city"] ? ", " + responseInfo["city"] : '') +
            (responseInfo["State"] && (responseInfo["State"].split(",").length<3 ? ", " + responseInfo["State"] : '')) +
            (responseInfo["State"] && (responseInfo["State"].split(",").length>3 && responseInfo["apartment"] ? " " + responseInfo["State"].split(",")[0] + ", " + "Apt/Unit: " + responseInfo["apartment"] + ", " + responseInfo["State"].split(",")[1] +", " + responseInfo["State"].split(",")[2] + ", "+ responseInfo["State"].split(",")[3] : '')) +
            (responseInfo["State"] && (responseInfo["State"].split(",").length>3 && !responseInfo["apartment"] ? responseInfo["State"] : '')) +
            (responseInfo["postal_code"] ? ", " + responseInfo["postal_code"] : '') +
            (responseInfo["country"] ? ", " + responseInfo["country"] : '')*/
          }
          </Subtitle>
          <br/>
          { currentOrders.length &&
            currentOrders.map((order, index) =>
            <ExtraInfoContainer>
              <Subtitle key={index}>{"Order #"+order.order_number}</Subtitle>
              {
                order.opportunities.map((opportunity, subindex) => <OpportunityCard key={subindex} opportunity={opportunity}/>)
              }
              <br/>
            </ExtraInfoContainer>
            )
          }
        </ExtraInfoContainer>
      )}
      { !hasInformationAboutOrders && !loadingResponse
      &&
      (
        <ExtraInfoContainer>
          <Heading>No information to show</Heading>
          <br/>
          <Heading>Questions or need something else?</Heading>
          Email connect.support@transactly.com OR Text/Call us at 844-387-7747
        </ExtraInfoContainer>
      )}
      {loadingResponse &&
      (
        <ExtraInfoContainer>
          <Heading>Waiting for Order Details</Heading>
          <br/>
          <Heading>Questions or need something else?</Heading>
          Email connect.support@transactly.com OR Text/Call us at 844-387-7747
        </ExtraInfoContainer>
      )
      }
      {isModalOpen && 
      (
        <Modal
          isOpen={isModalOpen}
          customStyles={modalStyles}
          closeTimeoutMS={250}
        >
          <Heading>Tracking number</Heading>
          <InfoRow>
            <StyledInput
            space
            onChange={(event)=>{
              setOrderNumber(event.target.value)}
            }
            />
            <Link to={"/orderTracking?trackByKey="+orderNumber}>
            <Button>
              <ButtonText>Submit</ButtonText>
            </Button>
            </Link>
          </InfoRow>
          {!hasInformationAboutOrders && !loadingResponse
          &&
          (
            <Subtitle>
            *Insert a valid tracking number*
            </Subtitle>
          )}
        </Modal>
      )
      }
      </Container>
      

  );
};
