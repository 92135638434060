import React from 'react';
import styled from 'styled-components';

import { colors, deviceSize } from '_constants';

const StyledButton = styled.div`
  height: 80px;
  display: flex;
  margin: 0 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const Service = styled.div`
  font-size: 16px;
  margin: 5px 0px;

  @media (max-width: ${deviceSize.tablet}px) {
    font-size: 12px;
    font-weight: normal;
  }
`;

const Selector = styled.div`
  height: 5px;
  width: 80%;
  border-radius: 10px;
  background-color: ${({ selected }) => (selected ? colors.brand : 'white')};

  @media (max-width: ${deviceSize.tablet}px) {
    height: 6px;
    width: 80%;
  }
`;

export const HeroTab = ({ service, selected, onClick }) => {
  const serviceText = (service) => {
    switch (service) {
      case 'Telecom':
        return 'TV/Internet'
        break

      case 'PestControl':
        return 'Pest Control'
        break
      
      case 'HomeWarranty':
        return 'Home Warranty'
        break

      case 'LawnCare':
        return 'Lawn Care'

      default:
        return service
    }
  }
  return (
    <StyledButton onClick={onClick}>
      <Service selected={selected}>
        {/* {service === 'Telecom' ? 'TV/Internet' : service} */
          serviceText(service)
        }
      </Service>
      <Selector selected={selected} />
    </StyledButton>
  );
};
