import React from 'react';
import styled from 'styled-components';

import { colors } from '_constants';

const StyledUnderline = styled.div`
  height: 5px;
  width: 20px;
  background-color: ${colors.brand};
  margin: ${({ noMargin }) => !noMargin && '5px auto 20px 10px;'};
`;

export const Underline = ({ noMargin }) => {
  return <StyledUnderline noMargin={noMargin} />;
};
