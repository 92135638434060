import React, { useEffect } from 'react';
import styled from 'styled-components';

import { updateUserCartAction } from '_store/user';
import {
  ResultCard,
  MobileSimpleProgress,
  Underline,
  InfoSubmitButton,
} from '_components';
import { useSelector, useDispatch } from 'react-redux';
import { deviceSize, colors } from '_constants';
import store from '_store';
import { push } from 'react-router-redux';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 100px;

  @media (max-width: ${deviceSize.tablet}px) {
    padding: 0px;
  }
`;

const Heading = styled.div`
  margin: 10px auto 20px 10px;

  font-size: 36px;
  line-height: 1;
  font-weight: 900;
`;

const Subtitle = styled.div`
  color: ${colors.darkGray};
  margin-left 10px;
  font-size: 12px;
`;

export const Main = ({ query }) => {
  const services = Object.keys(query);
  const dispatch = useDispatch();
  useEffect(() => {
    services.forEach((service) => {
      fetch(`/buildCart/${service}`, {
        method: 'post',
        body: JSON.stringify({
          plan: decodeURIComponent(query[service]),
        }),
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      })
        .then((response) => response.json())
        .then((plan) => {
          dispatch(
            updateUserCartAction({ service: service.toLowerCase(), plan })
          );
        });
    });
  }, [dispatch, query, services]);
  const reducer = (state) => state.userReducer;
  const { cart } = useSelector(reducer);
  const selectedPlansInformation = Object.values(cart).filter(
    (service) => service !== null
  );

  return (
    <Container>
      <MobileSimpleProgress />
      <Heading>Everything look good?</Heading>
      <Subtitle>Super quick review before checkout.</Subtitle>
      <Underline />
      {selectedPlansInformation.map((plan) => (
        <ResultCard plan={plan} key={plan.plan} />
      ))}

      <InfoSubmitButton
        color={colors.brand}
        onClick={() => {
          store.dispatch(push('../checkout'));
        }}
        cursor="pointer"
      />
    </Container>
  );
};
