import React, { useState } from 'react';
import { useEffect } from 'react/cjs/react.development';
import styled from 'styled-components';
import { ValidationError } from 'yup';
import {InputContainer} from '_pages/checkout/components/inputContainer'
import { updateUserAnswersToQuestionsAction } from '_store/user';
import { useDispatch, useSelector } from 'react-redux';



const StyledInputContainer = styled.div`
  margin: 10px 0px;
  width: 100%;
  width: ${({ width }) => width};
`;

const Text = styled.div`
  font-size: ${({ fontSize }) => fontSize};
  font-weight: ${({ fontWeight }) => fontWeight};
`;

const StyledHeader = styled.label`
  height: 40px;
  width: 100%;
  padding: 5px;
  fontSize: 15px;
`;

const StyledLabel = styled.label`
  height: 40px;
  width: 100%;
  padding: 3px;
  font-Size: 12px;
`;

const StyledInput = styled.input`
  border: 1px solid rgb(210, 211, 212);
  height: 40px;
  margin-right: ${({ space }) => space && '5px'};
  border-radius: 6px;
  width: 100%;
  padding: 5px;
`;

const StyledInputError = styled.input`
  border: 1px solid rgb(255,0,0);
  height: 40px;
  margin-right: ${({ space }) => space && '5px'};
  border-radius: 6px;
  width: 100%;
  padding: 5px;
  style={{color: "red"}}
`;

const FlexRow = styled.div`
  display: flex;
  align-content: center;
`;

const ButtonText = styled.div`
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  color: blue;
  margin-left: 5px;
  cursor: pointer;
`;

const PromoCodeText = styled.div`
  color: ${({ color }) => color};
  font-size: 10px;
`;

const ErrorMessage = styled.label`
  color: rgb(255,0,0);
  font-size: 10px;
  `;

export const AdditionalQuestionCard = ({question, service, value}) => {

  const userReducer = (state) => state.userReducer;
  const {
    answersToQuestions,
  } = useSelector(userReducer);

  const [answerToQuestion,setAnswerToQuestion] = useState('');
  const dispatch = useDispatch();

  

  const updateQuestionAndAnswer = (answerToQuestion) => {
    const answer = {
      "productId": question.productSFID,
      "questionId": question.questionSFID,
      "productQuestionId": question.SFID,
      "answer":answerToQuestion
    };
    let updatedAnswers = new Array(0);
    if (answersToQuestions){
      updatedAnswers = updatedAnswers.concat(answersToQuestions);
    }
    let questionIds = [];
    for (let i = 0; i<updatedAnswers.length; i++){
      questionIds.push(updatedAnswers[i].questionId);
    }
    if (questionIds.includes(answer.questionId)){
      for (let i = 0; i<updatedAnswers.length; i++){
        if (updatedAnswers[i].questionId === answer.questionId){
          updatedAnswers[i] = answer;
        }
      }
    }
    else{
      updatedAnswers = updatedAnswers.concat(answer);
    }
    dispatch(updateUserAnswersToQuestionsAction({answerToQuestion:updatedAnswers}));
  };

  return (
    <StyledInputContainer width="100%">
      <StyledLabel>{question.question}</StyledLabel>
      <FlexRow>
          <StyledInput
            name={"answerToQuestion"}
            value={answerToQuestion}
            onChange={(event)=>{
              setAnswerToQuestion(event.target.value);
            }}
            onBlur={()=>{
              updateQuestionAndAnswer(answerToQuestion);
            }}
            placeholder={"Your answer"}
            width="100%"
          />
      </FlexRow>
    </StyledInputContainer>
  );
};
