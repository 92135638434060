import React from 'react';
import { push } from 'connected-react-router';
import styled from 'styled-components';

import store from '_store';
import { ReactComponent as Pencil } from '_images/pencil.svg';

const FlexRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

// const Icon = styled.img``;

const Service = styled.div`
  font-weight: bold;
`;

const PlanText = styled.div`
  padding-top: 10px;
  font-size: 12px;
`;

const StyledHr = styled.hr`
  margin: 20px 0px;
  color: black;
  width: 100%;
`;

const StyledPencil = styled(Pencil)`
  cursor: pointer;
`;

const ServiceIcon = styled.img`
  height: 15px;
`;

export const ResponseBarRow = ({ service, answers, plan, link, icon }) => {
  return (
    <>
      <FlexRow>
        <ServiceIcon src={icon} />
        <Service>{service}</Service>
        <StyledPencil
          onClick={() => {
            store.dispatch(push(link));
          }}
        />
      </FlexRow>
      {answers?.map((answer) => {
        if (answer) return <PlanText>{answer}</PlanText>;
      })}
      <PlanText>{plan}</PlanText>
      <StyledHr />
    </>
  );
};
