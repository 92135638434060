import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import { updateServicesWantedAction } from '_store/user';
import { colors } from '_constants';

const ServiceCard = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 300px;
  height: 60px;
  background-color: white;
  border: 1px solid
    ${({ selected }) => (selected ? colors.brand : colors.border)};
  border-radius: 6px;
  cursor: pointer;
  margin: 25px 0px;
`;

const Indicator = styled.div`
  margin-left: 10px;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  border: 1px solid ${colors.border};
  background-color: ${({ selected }) => (selected ? colors.brand : 'white')};
`;

const ServiceIcon = styled.img`
  flex: 1;
  height: 20px;
`;

const ServiceText = styled.div`
  flex: 2;
`;

export const CheckBoxItem = ({ service, icon }) => {
  const renderText = (string) => {
    if (string === 'telecom') {
      return 'TV/Internet';
    } else {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }
  };
  const dispatch = useDispatch();

  const reducer = (state) => state.userReducer;
  const { servicesWanted } = useSelector(reducer);

  const [selected, setSelected] = useState(false);

  const handleClick = () => {
    setSelected(!selected);
    dispatch(updateServicesWantedAction({ service }));
  };

  useEffect(() => {
    if (servicesWanted.includes(service)) {
      setSelected(true);
    }
  }, [servicesWanted, service]);

  return (
    <ServiceCard onClick={handleClick} selected={selected}>
      <Indicator selected={selected} />
      <ServiceIcon src={icon} />
      <ServiceText>{renderText(service)}</ServiceText>
    </ServiceCard>
  );
};
