import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { colors, deviceSize } from '_constants';

import rightArrow from '_images/rightArrow.svg';

const Button = styled(Link)`
  ${({ productselected }) =>
    productselected === "true"
      ? `background-color: ${colors.brand};`
      : `background-color: white;
  border: 1px solid black;`}
  min-width: 150px;
  max-width: 220px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 35px;
  font-size: 16px;
  margin-top: 10px;

  @media (max-width: ${deviceSize.tablet}px) {
    height: auto;
    max-width: initial;
    min-width: initial;
    font-size: 12px;
  }
`;

const ButtonText = styled.span`
  height: 20px;
  line-height: 20px;
  color: ${({ productSelected }) => (productSelected ? 'white' : 'black')};

  @media (max-width: ${deviceSize.tablet}px) {
    height: initial;
    line-height: initial;
    padding: 5px;
  }
`;

export const AddButton = ({ productSelected, to, onClick }) => {
  return (
    <Button to={to} onClick={onClick} productselected={productSelected.toString()}>
      <ButtonText productSelected={productSelected}>
        {productSelected ? 'Remove' : 'Add'}
      </ButtonText>
      {to && <ButtonText>{to !== '#' && <img src={rightArrow} />}</ButtonText>}
    </Button>
  );
};
