import React from 'react';
import { useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import styled from 'styled-components';

import { ResultCard } from './resultCard';
import { colors } from '_constants';
import { ProceedButton } from './proceedButton';
import store from '_store';

const StyledCartDropdown = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justiy-content: center;
  position: absolute;
  right: 0;
  z-index: 10;
  border: 1px solid black;
  padding: 5px;
`;

export const CartDropdown = () => {
  const reducer = (state) => state.userReducer;
  const { cart } = useSelector(reducer);
  const selectedPlansInformation = Object.values(cart).filter(
    (service) => service !== null
  );
  return (
    <StyledCartDropdown>
      {selectedPlansInformation.map((plan) => (
        <ResultCard condense plan={plan} key={plan.plan} />
      ))}
      <ProceedButton
        text="Check out"
        onClick={() => {
          store.dispatch(push('../review'));
        }}
      />
    </StyledCartDropdown>
  );
};
