import React from 'react';
import styled from 'styled-components';

import { ReactComponent as TransLogo } from '_images/connect_white.svg';
import { ReactComponent as FirstKeyLogo } from '_images/firstKey_white.svg';
import facebook from '_images/facebook.svg';
import twitter from '_images/twitter.svg';
import instagram from '_images/instagram.svg';
import youtube from '_images/youtube.svg';
import { colors, deviceSize } from '_constants';
import { isMobileDevice } from '_helpers';
import store from '_store';
import { push } from 'connected-react-router';
import packageJson from '../../../package.json';

const Container = styled.div`
  width: 100%;
  background-color: #4894F5;
  color: ${colors.ghostWhite};
`;

const FlexRow = styled.div`
  padding: 20px 0px 0px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const SocialLinks = styled.div`
  margin: 0px 0px 0px 40px;
`;

const FlexColumn = styled.div`
  display: ${({ logoColumn }) =>
    logoColumn && isMobileDevice ? 'none' : 'flex'};
  flex-direction: column;
  width: 20%;
  margin: 0px 0px 0px 40px;
  @media (max-width: ${deviceSize.tablet}px) {
    width: 100%;
  }
`;

const ColumnText = styled.div`
  text-align: left;
  cursor: pointer;

  @media (max-width: ${deviceSize.tablet}px) {
    margin: 5px 0px;
    width: 100%;
  }
`;

const Icon = styled.img`
  margin-top:20px;
  max-height: 20px;
  max-width:20px;
  cursor:pointer;

  @media (max-width: ${deviceSize.tablet}px) {
    max-height: 15px;
    padding: 2px;
  }
`;

const Logo = styled(TransLogo)`
  margin-bottom:20px;
  height: 30px;

  @media (max-width: ${deviceSize.tablet}px) {
    display: none;
    height: 25px;
    position: relative;
    right: 35px;
  }
`;

const LogoFK = styled(FirstKeyLogo)`
  margin-bottom:20px;
  height: 75px;
  
  @media (max-width: ${deviceSize.tablet}px) {
    display: none;
    height: 25px;
    position: relative;
    right: 35px;
  }
`;

export const Footer = () => {
  return (
    <Container>
      <FlexRow>
        <FlexColumn logoColumn >
        {
          window.location.href.indexOf("firstkey") > -1 ? 
          <LogoFK onClick={() => {
            store.dispatch(push('../home'));
          }}/>
          :
          <Logo onClick={() => {
            store.dispatch(push('../home'));
          }}/>
        }

        </FlexColumn>
      </FlexRow>
      <FlexRow>
        <FlexColumn>
        <ColumnText
            onClick={() => {
              window.open('https://connectservices.com/privacy-policy/', "_blank") //to open new page
            }}>
            Privacy Policy
          </ColumnText>
        </FlexColumn>
      </FlexRow>
      <FlexRow>
        <FlexColumn>
        <ColumnText
            onClick={() => {
              window.open('https://connectservices.com/terms-of-service/', "_blank") //to open new page
            }}>
            Terms and Conditions
          </ColumnText>
        </FlexColumn>
      </FlexRow>
      <FlexRow>
        <FlexColumn>
          &copy; {new Date().getFullYear()} Connect v.{packageJson.version}
          {/*<Icon src={instagram} first onClick={()=>{window.open('https://www.instagram.com/transactlyonline', "_blank")}} />*/}
        </FlexColumn>
      </FlexRow>
      <SocialLinks>
        <FlexRow>
          <FlexColumn></FlexColumn>
        </FlexRow>
      {/*  <Icon src={instagram} first onClick={()=>{window.open('https://www.instagram.com/transactlyonline', "_blank")}} />&nbsp;&nbsp;
        <Icon src={facebook} onClick={()=>{window.open('https://www.facebook.com/realtransactly', "_blank")}} />&nbsp;&nbsp;
          <Icon src={twitter} onClick={()=>{window.open('https://twitter.com/transactly', "_blank")}} />*/}
      </SocialLinks>
    </Container>
  );
};
