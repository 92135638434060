import React, { useState, useEffect } from 'react';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import { useDispatch, useSelector } from 'react-redux';

import {
  updateUserInfoAction,
  updateSearchedAddressAction,
  updatePreviousAddressAction,
  updateBillingAddressAction,
  updateZipAction,
} from '_store/user';
import { colors } from '_constants';
import { isMobileDevice } from '_helpers';

export const AddressAutocomplete = ({ addressInput }) => {
  const dispatch = useDispatch();

  var addressURL = null;
  if (sessionStorage.getItem('address') !== 'undefined') {
    addressURL = sessionStorage.getItem('address');
  }

  const [address, setAddress] = useState(addressURL);

  const handleChange = (address) => {
    setAddress(address);
  };

  const reducer = (state) => state.userReducer;
  const { searchedAddress, previousAddress, billingAddress } = useSelector(
    reducer
  );

  const routerReducer = (state) => state.router;
  const { location } = useSelector(routerReducer);
  const { pathname } = location;

  const handleSelect = (address) => {
    geocodeByAddress(address)
      .then((results) => {
        const fullAddress = results[0].formatted_address;
        setAddress(fullAddress);
        dispatch(updateZipAction(fullAddress.slice(-10, -5)));
        if (addressInput === 'searched' || addressInput === 'copy') {
          dispatch(updateSearchedAddressAction(fullAddress));
          sessionStorage.setItem('address', fullAddress);
        } else if (addressInput === 'previous') {
          dispatch(updatePreviousAddressAction(fullAddress));
        } else if (addressInput === 'billing') {
          dispatch(updateBillingAddressAction(fullAddress));
        }
        return getLatLng(results[0]);
      })
      .then((latLng) => {
        if (addressInput === 'searched') {
          dispatch(updateUserInfoAction({ lat: latLng.lat, lng: latLng.lng }));
        }
      })
      .catch((error) => console.error('Error', error));
  };

  const searchedStyles = {
    width: '100%',
    backgroundColor: 'white',
    color: colors.darkGray,
    fontSize: isMobileDevice ? '12px' : '16px',
    height: isMobileDevice ? '60px' : '80px',
    borderRadius: '6px',
    border: 'none',
  };

  const checkoutStyles = {
    border: '1px solid rgb(210, 211, 212)',
    height: '40px',
    borderRadius: '6px',
    width: '100%',
    padding: '5px',
  };

  const containerStyles = {
    width: '100%',
  };

  return (
    <PlacesAutocomplete
      value={address ? address : ''}
      onChange={handleChange}
      onSelect={handleSelect}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div style={containerStyles}>
          <div style={{ width: '100%' }}>
            <input
              id="address"
              autoFocus
              onChange={handleChange}
              style={
                addressInput === 'searched' ? searchedStyles : checkoutStyles
              }
              {...getInputProps({
                placeholder:
                  addressInput === 'searched' || addressInput === 'copy'
                    ? searchedAddress || 'Enter service address'
                    : addressInput === 'previous'
                    ? previousAddress || 'Enter previous address'
                    : addressInput === 'billing'
                    ? billingAddress || 'Enter billing address'
                    : 'Enter address',
                className: 'location-search-input',
              })}
            />
          </div>
          <div>
            <div
              className="autocomplete-dropdown-container"
              style={{
                fontSize: '16px',
                position: 'absolute',
                zIndex: '1',
                width: '80%',
              }}
            >
              {loading && <div>Loading...</div>}
              {suggestions.map((suggestion) => {
                const className = suggestion.active
                  ? 'suggestion-item--active'
                  : 'suggestion-item';
                // inline style for demonstration purpose
                const style = suggestion.active
                  ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                  : { backgroundColor: '#ffffff', cursor: 'pointer' };
                return (
                  <div
                    key={suggestion.description}
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style,
                    })}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </PlacesAutocomplete>
  );
};
