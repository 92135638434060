import React, { useState } from 'react';
import styled from 'styled-components';
import { deviceSize, colors } from '_constants';
import { ValidationError } from 'yup';
import { AddressAutocomplete } from '_components/addressAutocomplete';
import {
  isFieldValid
} from '_helpers/inputFieldsValidation';

import Password from 'react-better-password';
import { render } from '@testing-library/react';


const StyledInputContainer = styled.div`
  margin: 10px 0px;
  width: 50%;
  width: ${({ width }) => width};
`;

const Text = styled.div`
  font-size: ${({ fontSize }) => fontSize};
  font-weight: ${({ fontWeight }) => fontWeight};
`;

const StyledInput = styled.input`
  border: 1px solid rgb(210, 211, 212);
  height: 40px;
  margin-right: ${({ space }) => space && '5px'};
  border-radius: 6px;
  width: 100%;
  padding: 5px;
`;

const StyledInputError = styled.input`
  border: 1px solid rgb(255,0,0);
  height: 40px;
  margin-right: ${({ space }) => space && '5px'};
  border-radius: 6px;
  width: 100%;
  padding: 5px;
  style={{color: "red"}}
`;

const FlexRow = styled.div`
  display: flex;
  align-content: center;
`;

const ButtonText = styled.div`
  height: 40px;
  width:10%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  color: ${colors.brand};
  margin-left: 10px;
  margin-right: 10px;
  cursor: pointer;
`;

const PromoCodeText = styled.div`
  color: ${({ color }) => color};
  font-size: 10px;
`;

const ErrorMessage = styled.label`
  margin-left:5px;
  color: rgb(255,0,0);
  font-size: 10px;
  `;

export const InputSensitiveContainer = ({
  text,
  name,
  value,
  placeholder,
  onChange,
  width,
  space,
  button,
  address,
  fieldType,
  isRequired,
  validationType,
}) => {
  const [buttonClicked, setButtonClicked] = useState(false);

  const[isValid, setIsValid] = useState(true);
  const[isRequiredCompleted,setIsRequiredCompleted] = useState(true);
  const[isValueShown,setIsValueShown] = useState(false);

  const runValidation = () => {
    if (isRequired === "true"){
      if (value.trim() === ''){
        setIsRequiredCompleted(false);
      }
      else{
        setIsRequiredCompleted(true);
        if (validationType){
          if (isFieldValid(value,validationType)){
            setIsValid(true);
          }else{
            setIsValid(false);
          }
        }
      }
    } else {
      setIsRequiredCompleted(true);
      if (validationType){
        if (isFieldValid(value,validationType)){
          setIsValid(true);
        } else {
          setIsValid(false);
        }
      }
    }
  };

  const toggleValueShown = () =>{
    setIsValueShown(!isValueShown);
  }
  
  return (
    <StyledInputContainer width={width}>
      <Text>{text}</Text>
      <FlexRow>
      {address ? (
        <AddressAutocomplete addressInput={address}/>
      ) : (isRequiredCompleted && isValid ?(
        <Password
            name={name}
            value={value}
            onChange={(value)=>{onChange({ target: { name: name, value: value}})}}
            placeholder={placeholder}
            space={space}
            type={fieldType}
            show={isValueShown}
            onBlur={()=>{runValidation()}}
            className="styledInput"
            timeout={1500}
        />
      ) : (!isRequiredCompleted ? (<Password
            name={name}
            value={value}
            onChange={(value)=>{onChange({ target: { name: name, value: value}})}}
            placeholder={placeholder+' is required.'}
            space={space}
            type={fieldType}
            show={isValueShown}
            onBlur={()=>{runValidation()}}
            className="styledInputError"
            timeout={1500}
          />
          ):(<Password
            name={name}
            value={value}
            onChange={(value)=>{onChange({ target: { name: name, value: value}})}}
            placeholder={placeholder+' is invalid.'}
            space={space}
            type={fieldType}
            show={isValueShown}
            onBlur={()=>{runValidation()}}
            className="styledInputError"
            timeout={1500}
          />)
      ))}
      {!isValid ? <ErrorMessage>{placeholder} is not valid.</ErrorMessage>:''}
        {
          <ButtonText onClick={toggleValueShown}>
            {isValueShown ? 'Hide': 'Show'}
          </ButtonText>
        }
      </FlexRow>
    </StyledInputContainer>
  );
};
