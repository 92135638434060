import React from 'react';
import styled from 'styled-components';

import star from '_images/star.svg';
import { colors } from '_constants/styleConstants';
import { Circle } from '_pages/home/components/circle';

const Container = styled.div`
  height: 150px;
  width: 300px;
  background-color: ${colors.brand};
  position: relative;
  z-index: 1;
  bottom: 40px;
  left: 20px;
  color: white;
  text-align: left;
  padding: 20px;
  font-size: 12px;
`;

const CircleRow = styled.div`
  display: flex;
  align-items: center;
`;

const Name = styled.div`
  margin-left: 20px;
  margin-right: 50px;
`;

const Star = styled.img`
  height: 15px;
`;

export const ReviewCard = () => {
  return (
    <Container>
      &quot;The easiest move EVER! <br />
      Where have you been all my life?&quot;
      <br />
      <br />
      <CircleRow>
        <Circle color="white" />
        <Name>Jordan L.</Name>
        <Star src={star} />
        <Star src={star} />
        <Star src={star} />
        <Star src={star} />
        <Star src={star} />
      </CircleRow>
    </Container>
  );
};
