import React from 'react';

export const RightArrow = ({ color = '#808080', className }) => {
  return (
    <svg
      className={className}
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 60 80"
      fill={color}
    >
      <rect x="14.7" y="38" className="st0" width="27" height="4" />
      <polygon
        className="st0"
        points="33.8,28.7 31,31.5 39.5,40 31,48.5 33.8,51.3 45.1,40 "
      />
    </svg>
  );
};
