import React, { useState, useEffect } from 'react';
import { push } from 'connected-react-router';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';

import store from '_store';
import { deviceSize, colors } from '_constants';
import { updateContactInfoAction } from '_store/contactInfo';
import { dobRegExp, ssRegExp, ccRegExp, expRegExp, cvvRegExp } from '_helpers';
import { InfoSubmitButton, MobileSimpleProgress, Underline } from '_components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 100px;

  @media (max-width: ${deviceSize.tablet}px) {
    padding: 0px;
  }
`;

const Heading = styled.div`
  margin: 10px auto 20px 10px;

  font-size: 36px;
  line-height: 1;
  font-weight: 900;
`;

const Subtitle = styled.div`
  color: ${colors.darkGray};
  margin-left 10px;
  font-size: 12px;
`;

const StyledInput = styled.input`
  width: 400px;
  height: 60px;
  margin: 10px;

  @media (max-width: ${deviceSize.tablet}px) {
    width: 90%;
    margin: 10px auto;
  }
`;

const ErrorText = styled.div`
  ${({ show }) => !show && 'display: none;'}
  font-size: 10px;
  color: red;
  margin-left: 10px;
`;

export const Main = () => {
  const startDatesReducer = (state) => state.startDatesReducer;
  const { startDates } = useSelector(startDatesReducer);
  const electricityStartDate = startDates.electricity;
  const telecomStartDate = startDates.telecom;
  const insuranceStartDate = startDates.insurance;
  const moversStartDate = startDates.movers;
  const securityStartDate = startDates.security;

  const contactInfoReducer = (state) => state.contactInfoReducer;
  const { contactInfo } = useSelector(contactInfoReducer);
  const { dob, ss, cc, exp, cvv, unit } = contactInfo;

  const userReducer = (state) => state.userReducer;
  const { cart, address, referrer } = useSelector(userReducer);
  const { electricity, telecom, insurance, movers, security } = cart;

  const dispatch = useDispatch();

  const parsePlans = (plan) => {
    if (plan) {
      return plan.plan;
    } else {
      return 'No plan selected.';
    }
  };

  const sendEmail = () => {
    fetch('/sendEmail', {
      method: 'post',
      body: JSON.stringify({
        contactInfo,
        address,
        referrer,
        electricity: parsePlans(electricity),
        telecom: parsePlans(telecom),
        insurance: parsePlans(insurance),
        movers: parsePlans(movers),
        security: parsePlans(security),
        startDates: {
          electricityStartDate,
          telecomStartDate,
          insuranceStartDate,
          moversStartDate,
          securityStartDate,
        },
      }),
      headers: { 'Content-Type': 'application/json' },
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        return data;
      });
  };

  const allowProgress = () => {
    if (
      dobRegExp.test(dob) &&
      ssRegExp.test(ss) &&
      ccRegExp.test(cc) &&
      expRegExp.test(exp) &&
      cvvRegExp.test(cvv)
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  };

  // allowing blank informaiton for now
  // const checkErrors = () => {
  //   setClicked(true);
  //   if (!disabled) {
  //     sendEmail();
  //     store.dispatch(push('../confirmation'));
  //   }
  // };

  const checkErrors = () => {
   // sendEmail();
    store.dispatch(push('../confirmation'));
  };

  async function updateContactInfo(event) {
    const { name } = event.target;
    return dispatch(
      updateContactInfoAction({ ...contactInfo, [name]: event.target.value })
    );
  }

  const getContactInfo = (event) => {
    updateContactInfo(event).then(allowProgress);
  };

  const [disabled, setDisabled] = useState(true);
  const [clicked, setClicked] = useState(false);

  useEffect(() => {
    allowProgress();
  });

  return (
    <Container>
      <MobileSimpleProgress />
      <Heading>Almost Finished.</Heading>

      <Subtitle>Account holder information.</Subtitle>
      <Subtitle>
        We take your privacy seriously and your information is always secure
        with us.
      </Subtitle>
      <Underline />

      <StyledInput
        name="dob"
        value={dob}
        onChange={getContactInfo}
        placeholder="Date of birth (MM/DD/YYYY)"
        type="text"
      />
      <ErrorText show={clicked && !dob}>Date of birth is invalid</ErrorText>
      <StyledInput
        type="text"
        name="ss"
        value={ss}
        placeholder="Social Security Number (No hyphens)"
        onChange={getContactInfo}
      />
      <ErrorText show={clicked && !ss}>
        Social Security number is invalid
      </ErrorText>

      <Subtitle>Payment info. </Subtitle>
      <Subtitle>
        We take your privacy seriously and your information is always secure
        with us.
      </Subtitle>
      <Underline />

      <StyledInput
        type="text"
        name="cc"
        value={cc}
        placeholder="Credit card number (No hyphens)"
        onChange={getContactInfo}
      />
      <ErrorText show={clicked && !cc}>Credit card number is invalid</ErrorText>
      <StyledInput
        name="exp"
        value={exp}
        onChange={getContactInfo}
        placeholder="Expiration Date (MM/YYYY)"
        type="text"
      />
      <ErrorText show={clicked && !exp}>Expiration number is invalid</ErrorText>
      <StyledInput
        type="text"
        name="cvv"
        value={cvv}
        placeholder="CVV"
        onChange={getContactInfo}
      />
      <ErrorText show={clicked && !cvv}>CVV is invalid</ErrorText>

      {/*allowing blank information for now */}
      <InfoSubmitButton
        // color={disabled ? colors.fadedBrand : colors.brand}
        color={colors.fadedBrand}
        onClick={checkErrors}
        // cursor={disabled ? 'not-allowed' : 'pointer'}
        cursor="pointer"
        text="Finish"
      />
    </Container>
  );
};
