import {
    phoneRegExp,
    emailRegExp,
    ssRegExp,
    ccRegExp,
    cvvRegExp,
    dobRegExp,
    startDatesRegExp,
    expRegExp,
  } from '_helpers/regExp';

export const isFieldValid = (value,validationType) =>{

    if (validationType === "Phone"){
        if(phoneRegExp.test(value.trim())){
            return true;
        } else {
          //alert("You have entered an invalid "+validationType + "!");
          return false;
        }
    }
    if (validationType === "Email"){
        if (emailRegExp.test(value.trim()))
        {
          return true;
        } else {
          //alert("You have entered an invalid "+validationType + "!");
          return false;
        }
    } else if (validationType === "SSN"){
        if (ssRegExp.test(value.replaceAll("-","")) || !value){
            return true;
        }else{
            //alert("You have entered an invalid "+validationType+"!");
            return false;
        }
    } else if (validationType === "Credit Card") {
        if(ccRegExp.test(value.replace(/\s/g, '')) || !value){
            return true;
        }else{
            //alert("You have entered an invalid "+validationType+"!");
            return false;
        }
    } else if (validationType === "CVV") {
        if(cvvRegExp.test(value.trim()) || !value){
            return true;
        }else{
            //alert("You have entered an invalid "+validationType+"!");
            return false;
        }
    } else if (validationType === "Expiration Date"){
        if(expRegExp.test(value) || !value){
            return true;
        }else{
            //alert("You have entered an invalid "+validationType+"!");
            return false;
        }
    } else if (validationType === "Date of Birth"){
        let today = new Date();
        if (Date.parse(value) <= today || !value){
            return true;
        }else{
            //alert("You have entered an invalid "+validationType+"!");
            return false;
        }
    } else if (validationType === "Service Start Date"){
        let today = new Date();
        if (Date.parse(value)>= today || !value){
            return true;
        }else{
            //alert("You have entered an invalid "+validationType+"!");
            return false;
        }
    } else {
        return true;
    }
};

export const isRequiredFieldSet = (value) =>{
    if (value){
        return true;
    } else {
        return false;
    }
}