import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import './styleOverrides.css';
import { toggleCartModalAction } from '_store/ui';

import { zIndexes } from '_constants';
import { Checkout } from '_pages';
import { isMobileDevice } from '_helpers';
import { Modal } from '_components/modal';
// import { ReactComponent as leftArrow } from '_images/leftArrow.svg';

export const CheckoutModal = () => {
  const reducer = (state) => state.uiReducer;
  const { isOpenCartModal } = useSelector(reducer);

  const dispatch = useDispatch();

  const modalStyles = {
    content: {
      height: '100%',
      width: isMobileDevice ? '100%' : '50%',
      top: '0px',
      left: 'auto',
      right: '0px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      zIndex: zIndexes.OVERLAY,
    },
  };
  return (
    <Modal
      isOpen={isOpenCartModal}
      customStyles={modalStyles}
      closeModal={() => {
        dispatch(toggleCartModalAction(!isOpenCartModal));
      }}
      closeTimeoutMS={500}
    >
      <Checkout />
    </Modal>
  );
};
