import { createSlice } from '@reduxjs/toolkit';

const urlParams = new URLSearchParams(window.location.search);

const contactInfoSlice = createSlice({
  name: 'contactInfo',
  initialState: {
    contactInfo: {
      firstName: urlParams.get('firstName') || '',
      lastName: urlParams.get('lastName') || '',
      phone: urlParams.get('phone') || '',
      email: urlParams.get('email') || '',
      dob: '',
      ss: '',
      cc: '',
      exp: '',
      cvv: '',
      unit: urlParams.get('unit') || '',
      dl: '',
      dlState: '',
      dlExp: '',
      billingFirst: '',
      billingLast: '',
      billingUnit: '',
      ga_cookies: '',
    },
  },
  reducers: {
    updateContactInfoAction(state, action) {
      state.contactInfo = action.payload;
    },
  },
});

export const { updateContactInfoAction } = contactInfoSlice.actions;
export default contactInfoSlice.reducer;
