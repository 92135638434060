import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { colors } from '_constants';

import rightArrow from '_images/rightArrow.svg';

const Button = styled(Link)`
  min-width: 150px;
  max-width: 220px;
  height: 60px;
  background-color: ${({ color }) => color || colors.brand};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 35px;
  color: white;
  font-size: 16px;
  margin-top: 10px;
`;

const ButtonText = styled.span`
  height: 20px;
  line-height: 20px;
`;

export const ProceedButton = ({ text, to, onClick, color }) => {
  return (
    <Button to={to} onClick={onClick} color={color}>
      <ButtonText>{text || 'Save & continue'}</ButtonText>
      {to && <ButtonText>{to !== '#' && <img src={rightArrow} />}</ButtonText>}
    </Button>
  );
};
