import React from 'react';
import styled from 'styled-components';
import { colors } from '_constants';
import { isMobileDevice } from '_helpers';
import { useSelector } from 'react-redux';

const routesToHide = ['/', '/serviceSelect', '/confirmation'];

const Container = styled.div`
  display: ${({ pathname }) =>
    routesToHide.includes(pathname) || !isMobileDevice ? 'none' : 'flex'};
  margin: 30px auto;
  justify-content: center;
  align-items: center;
`;

const FlexColumn = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Dot = styled.span`
  background-color: ${({ show }) => (show ? colors.brand : 'gray')};
  border: none;
  border-radius: 50%;
  height: 10px;
  width: 10px;
`;

const Text = styled.span`
  color: ${({ show }) => (show ? colors.brand : 'gray')};
  font-size: 12px;
`;

const Line = styled.span`
  position: relative;
  bottom: 10px;
  height: 2px;
  text-align: center;
  min-width: 20px;
  background-color: ${({ show }) => (show ? colors.brand : 'gray')};
  ${({ extend }) => extend && 'width: 60px;'}
`;

export const MobileSimpleProgress = () => {
  const reducer = (state) => state.router;
  const { location } = useSelector(reducer);
  const { pathname } = location;
  //TODO:
  //Do this differently
  return (
    <Container pathname={pathname}>
      <FlexColumn>
        <Dot show />
        <Text show>START</Text>
      </FlexColumn>

      <Line show />

      <FlexColumn>
        <Dot show />
        <Text show>SHOP</Text>
      </FlexColumn>

      <Line
        show={
          pathname.includes('/review') ||
          pathname.includes('/info') ||
          pathname === '/confirmation'
        }
      />

      <FlexColumn>
        <Dot
          show={
            pathname.includes('/review') ||
            pathname.includes('/info') ||
            pathname === '/confirmation'
          }
        />
        <Text
          show={
            pathname.includes('/review') ||
            pathname.includes('/info') ||
            pathname === '/confirmation'
          }
        >
          INFO
        </Text>
      </FlexColumn>

      <Line show={pathname === '/confirmation'} />

      <FlexColumn>
        <Dot show={pathname === '/confirmation'} />
        <Text show={pathname === '/confirmation'}>DONE</Text>
      </FlexColumn>
    </Container>
  );
};
