import { createSlice } from '@reduxjs/toolkit';
// import { userEventsService } from '_services';

// export const getuserEventsAction = createAsyncThunk(
//   'content/user/get',
//   async (payload) => {
//     const response = await userEventsService.getuserEvents(
//       payload.id,
//       payload.location
//     );
//     return response;
//   }
// );

const uiSlice = createSlice({
  name: 'ui',
  initialState: {
    isOpenCartModal: false,
    isOpenCartDropdown: false,
    selectedHeroButton: 'Telecom',
  },
  reducers: {
    toggleCartModalAction(state, action) {
      state.isOpenCartModal = action.payload;
    },
    toggleCartDropdownAction(state, action) {
      state.isOpenCartDropdown = action.payload;
    },
    updateSelectedHeroButtonAction(state, action) {
      state.selectedHeroButton = action.payload;
    },
  },
  // extraReducers: {
  //   [getuserEventsAction.fulfilled]: (state, action) => {
  //     state.user = action.payload;
  //   },
  // },
});

export const {
  toggleCartModalAction,
  toggleCartDropdownAction,
  updateSelectedHeroButtonAction,
} = uiSlice.actions;

export default uiSlice.reducer;
