import React from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';

// import './checkoutModal/styleOverrides.css'
import { zIndexes } from '_constants';

// let { top, right, bottom } = ReactModal.defaultStyles.content;
// top = '0';
// right = '0';
// bottom = '0';

export const Modal = ({
  isOpen,
  closeModal,
  customStyles,
  closeTimeoutMS,
  children,
}) => {
  ReactModal.setAppElement('body');

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={closeModal}
      style={{
        ...customStyles,
        overlay: { ...customStyles.overlay, zIndex: zIndexes.OVERLAY },
      }}
      closeTimeoutMS={closeTimeoutMS}
    >
      {children}
    </ReactModal>
  );
};

Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  customStyles: PropTypes.object,
  children: PropTypes.any,
};
