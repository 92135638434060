import React, { useRef, useState } from 'react';
import { push } from 'react-router-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import store from '_store';
import pageData from '_pageData.json';
import rightBlackArrow from '_images/rightBlackArrow.svg';
import { RadioGroup } from './components/radioGroup';
import { Dropdown } from './components/dropdown';
import { ProceedButton, Modal, Underline, InfoSubmitButton } from '_components';
import { colors, zIndexes, deviceSize } from '_constants';
import { ReactComponent as ToolTip } from '_images/toolTip.svg';
import { MobileSimpleProgress } from '_components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 100px;

  @media (max-width: ${deviceSize.tablet}px) {
    padding: 0px;
  }
`;

const TitleRow = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  @media (max-width: ${deviceSize.tablet}px) {
    align-items: initial;
  }
`;

const Title = styled.div``;

const Fact = styled.div``;

const StyledToolTip = styled(ToolTip)`
  cursor: pointer;

  @media (max-width: ${deviceSize.tablet}px) {
    height: 20px;
    width: 20px;
    margin: 15px 35px 10px 10px;
  }
`;

const Heading = styled.div`
  margin: 10px 10px 20px 10px;

  font-size: 36px;
  line-height: 1;
  font-weight: 900;
`;

const Subtitle = styled.div`
  color: ${colors.darkGray};
  margin-left 10px;
  font-size: 12px;
`;

const SkipContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 30px;
`;

const SkipText = styled(Link)`
  color: black;
  margin-left 10px;
  font-size: 12px;
  cursor: pointer;

  &:hover {
    color: ${colors.brand};
  }
`;

const Arrow = styled.img`
  padding: 5px;
`;

const RefContainer = styled.div`
  width: 100%;
`;

export const Main = ({ serviceType }) => {
  const { questionOne, questionTwo, questionThree } = pageData[serviceType];
  const [showModal, setShowModal] = useState(false);
  const questionTwoScroll = useRef();
  const questionThreeScroll = useRef();
  const button = useRef();

  const scroll = (scrollTo) => {
    scrollTo.current.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'center',
    });
  };

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const modalStyles = {
    content: {
      height: '100%',
      width: '300px',
      top: '0px',
      left: 'auto',
      right: '0px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-around',
      alignItems: 'center',
      zIndex: zIndexes.OVERLAY,
    },
  };

  return (
    <Container>
      <MobileSimpleProgress />
      <Modal
        isOpen={showModal}
        customStyles={modalStyles}
        closeModal={toggleModal}
      >
        <Title>Did you know?</Title>
        <Fact>{pageData[serviceType].fact}</Fact>
        <ProceedButton text="Close" to="#" onClick={toggleModal} />
      </Modal>
      <TitleRow>
        <Heading>{pageData[serviceType].heading}</Heading>
        <StyledToolTip onClick={toggleModal} />
      </TitleRow>
      <Subtitle>{pageData[serviceType].subheading}</Subtitle>
      <Underline />
      <SkipContainer>
        <SkipText to={pageData[serviceType].link}>
          Skip questions. Show me all options.
        </SkipText>
        <Arrow src={rightBlackArrow} />
      </SkipContainer>
      <RefContainer onClick={() => scroll(questionTwoScroll)}>
        <RadioGroup
          question={questionOne.question}
          name={questionOne.name}
          service={serviceType}
          questionNumber="questionOne"
        />
      </RefContainer>
      <RefContainer
        ref={questionTwoScroll}
        onChange={() => scroll(questionThreeScroll)}
      >
        <Dropdown
          question={questionTwo.question}
          name={questionTwo.name}
          service={serviceType}
          answers={questionTwo.answers}
          questionNumber="questionTwo"
        />
      </RefContainer>
      <RefContainer ref={questionThreeScroll} onClick={() => scroll(button)}>
        <RadioGroup
          question={questionThree.question}
          name={questionThree.name}
          service={serviceType}
          questionNumber="questionThree"
        />
      </RefContainer>
      <RefContainer ref={button}>
        <InfoSubmitButton
          color={colors.brand}
          onClick={() => {
            store.dispatch(push(pageData[serviceType].link));
          }}
          cursor="pointer"
        />
      </RefContainer>
    </Container>
  );
};
