import React, { useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import {Link} from 'react-router-dom';

import { colors, deviceSize } from '_constants';
import { isMobileDevice, post } from '_helpers';
import store from '_store';
import { push } from 'connected-react-router';
import { update } from 'lodash';

const Card = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px;
  ${({ condense }) => !condense && 'margin: 10px 0px'};
  width: 100%;
  background-color: white;
  font-weight: bold;
`;

const StyledTable = styled.table`
  width: 100%;
`;

const InfoRow = styled.tr`
  padding: 2px;
  color: ${({ color }) => color};
  font-size: ${({ fontSize }) => fontSize};
`;

const DataSection = styled.td`
  padding: 5px;
  text-align: left;
  width: ${({ cardWidth }) => cardWidth};
  font-size: ${({ fontSize }) => fontSize};
  color: ${({ color }) => color};
  font-weight: ${({ fontWeight }) => fontWeight};

  @media (max-width: ${deviceSize.tablet}px) {
    font-size: 10px;
  }

`;

const Title = styled.div`
    display: flex;
    flex-direction: column;
    padding: 5px;
    ${({ condense }) => !condense && 'margin: 5px 0px'};
    width: 100%;
    background-color: white;
    font-size: 16px;
    font-weight: bold;
`;

const ColoredLine = ({ color }) => (
    <hr
        style={{
            color: color,
            background: color,
            height: 1,
            border: color,
        }}
    />
  );

export const UpfrontChargeSummary = ({ plans, condense }) => {

  function calculateTotal(){
    let total = 0;
    if (plans.length){
        for (var plan in plans){
            total+=plans[plan].upfrontCharge
        }
    }
    return Math.round(total*100)/100;
  }
  
  return (
    <Card condense={condense}> 
    <Title>Upfront Charge</Title>
    <ColoredLine color={colors.lightGray}/>
    <StyledTable>
    {
        plans.map((plan)=>{
                return (
                    <InfoRow>
                        <DataSection fontSize="13px" color={colors.darkGray} cardWidth="40%" >{plan.plan}</DataSection>
                        <DataSection fontSize="13px" color={colors.darkGray} cardWidth="40%">{plan.upfrontChargeDetail}</DataSection>
                        <DataSection fontSize="13px" color={colors.darkGray} cardWidth="20%">{"$ "+plan.upfrontCharge}</DataSection>
                    </InfoRow>
                );
        })
    }
    </StyledTable>
    <ColoredLine color={colors.lightGray}/>
    <StyledTable>
        <InfoRow>
            <DataSection fontSize="13px" fontWeight="bold" cardWidth="80%">Total</DataSection>
            <DataSection fontSize="13px" fontWeight="bold" color={colors.brand} cardWidth="20%">{"$ "+calculateTotal()}</DataSection>
        </InfoRow>
    </StyledTable>
    </Card>
  );
};
