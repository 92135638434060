import React, { useEffect, useRef, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import spinner from '_images/spinner.gif';
import { InputContainer } from './components/inputContainer';
import { InputSensitiveContainer } from './components/inputSensitiveContainer';
import { deviceSize, colors } from '_constants';
import { useDispatch, useSelector } from 'react-redux';
import { updateContactInfoAction } from '_store/contactInfo';
import { toggleCartModalAction } from '_store/ui';
import store from '_store';
import { push } from 'connected-react-router';
import { emailRegExp, phoneRegExp } from '_helpers';
import {
  updateSearchedAddressAction,
  updateUserInfoAction,
  updateServiceDateAction,
  updateUserCartCopyAction,
  updateUserCartAction,
} from '_store/user';
import { ResultCard } from '_components';
import shareCart from '_images/shareCart.svg';
import shoppingCart from '_images/shopping-cart.svg';
import { forEach } from 'lodash';
import { isEmptyObject } from 'jquery';
import {
  serializeLeadAndProducts,
  serializeProducts,
} from '_helpers/serializer';
import {postLeadInSF} from '_helpers/salesforceIntegration';
import {
  isFieldValid,
  isRequiredFieldSet
 } from '_helpers/inputFieldsValidation';

import "./components/style.css";
import {ImInfo} from 'react-icons/im';
import {FaShareSquare} from 'react-icons/fa';
import { AdditionalQuestionCard } from '_components';

import { UpfrontChargeSummary } from './components/upfrontChargeSummary';

const CreateCartButton = styled.span`
  height: 30px;
  width: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: #000000;
  color: white;
  border-radius: 6px;
  box-shadow: ${colors.brand} 0px 10px 40px -10px;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 20px;
  margin-right: 20px;

  @media (max-width: ${deviceSize.tablet}px) {
    height: 20px;
    margin-top: 10px;
  }
`;

const CreateCartButtonText = styled.span`
  font-size: 10px;
  text-align: center;

  @media (max-width: ${deviceSize.tablet}px) {
    font-size: 8px;
  }
`;

const CreateCartResultText = styled.span`
  font-size: 10px;
  text-align: center;
  @media (max-width: ${deviceSize.tablet}px) {
    font-size: 8px;
  }
  `;

const Container = styled.div`
  padding: 0px 10px;
  width: 100%;
`;

const Text = styled.div`
  font-size: ${({ fontSize }) => fontSize};
  font-weight: ${({ fontWeight }) => fontWeight};
  color: ${({ color }) => color}
`;

const SectionContainer = styled.div`
  margin: 20px 0px;
  max-width: 500px;
`;

const NumberedCircle = styled.span`
  display: flex;
  ${({ selected }) =>
    !selected
      ? `background-color: gray; color: white;`
      : `background-color: black;
  color: white;`}
  align-items: center;
  justify-content: center;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  margin-right: 10px;
`;

const IconInfo = styled.div`
  display: flex;
  ${({ selected }) =>
  !selected
    ? `color: gray;`
    : `fill: black !important;
  `}
`;

const FlexRow = styled.div`
  display: flex;
  align-items: ${({ alignItems }) => (alignItems ? alignItems : 'center')};
  justify-content: ${({ justifyContent }) => justifyContent};
  width: 100%;
  ${({ clickable }) => clickable && 'cursor: pointer;'}
`;


const CartTitles = styled.div`
  display: flex;
  ${({ selected }) =>
  !selected
    ? `color: gray;`
    : `color: black;
  `}
`;

//background-color: ${colors.brand};
const FinishButton = styled.span`
  height: 50px;
  width: ${({ width }) => width};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  ${({disabled}) => 
  disabled
    ? `background-color: grey;`
    : `background-color: ${colors.brand};`
  }
  color: white;
  margin-top: 10px;
  border-radius: 6px;
  box-shadow: ${colors.brand} 0px 10px 40px -10px;
`;

const ButtonText = styled.span`
  font-size: 14px;
  justify-content: center;
  align-items: center;
`;

const WhatToExpect = styled.div`
  border: 1px solid black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 300px;
  padding: 10px;
`;

const NoSurprises = styled.div`
  font-weight: bold;
  font-size: 20px;
  border-bottom: 1px solid gray;
`;

const ExpectContent = styled.div`
  font-size: 16px;
  margin: 10px 0px;
`;

const ProgressBar = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Progress = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0px 5px;
`;

const Dot = styled.div`
  height: 5px;
  width: 5px;
  background-color: black;
  border-radius: 50%;
`;

const Step = styled.div`
  ${({ selected }) =>
    !selected
      ? `
  max-height: 0;
  opacity: 0;
  `
      : `
  max-height: 1000px;
  opacity: 1;
  transform: translateY(0);
  transition: all 2s ease;
  `}
  margin-left: 25px;
`;

const GoBack = styled.div`
  align-self: flex-start;
  cursor: pointer;
`;

const StyledSpan = styled.span`
  margin: 0px 5px;
`;

const Selector = styled.div`
  height: 20px;
  width: 20px;
  padding: 2px;
  background-color: ${({ enterBilling }) =>
    !enterBilling ? colors.brand : 'white'};
  border-radius: 50%;
  border: 1px solid ${colors.darkGray};
  margin-right: 10px;
  cursor: pointer;
`;

const CopyButton = styled.div`
  height: 20px;
  width: 30px;
  border: 1px solid black;
  border-radius: 6px;
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 25px;
  right: 25px;
  width: 100px;
`;

const ShareCart = styled.img`
  cursor: pointer;
`;

const divWithIcon = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;

const StyledLabelField = styled.label`
  height: 40px;
  margin-right: ${({ space }) => space && '5px'};
  width: 50%;
  padding: 5px;
  font-Size: 14px;
  text-align: left;
`;

const SpinnerRow = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
`;


export const Checkout = () => {
  const [enterBilling, setEnterBilling] = useState(false);
  const [rentOrOwn, setRentOrOwn] = useState(null);
  const [acceptTerms, setAcceptTerms] = useState(false);
  const contactInfoReducer = (state) => state.contactInfoReducer;
  const { contactInfo } = useSelector(contactInfoReducer);
  const {
    firstName,
    lastName,
    phone,
    email,
    unit,
    cc,
    exp,
    cvv,
    ss,
    dob,
    dl,
    dlState,
    dlExp,
    billingFirst,
    billingLast,
    billingUnit,
    gclid,
    ga,
    expId,
    expName,
    ga_cookies,
  } = contactInfo;

  const userReducer = (state) => state.userReducer;
  const {
    searchedAddress,
    previousAddress,
    billingAddress,
    cart,
    answersToQuestions,
    questions,
    serviceDate,
    referrer,
  } = useSelector(userReducer);
  const selectedPlansInformation = Object.values(cart).filter(
    (service) => service !== null
  );
  const { electricity, telecom, insurance, movers, security } = cart;

  const isDriversLicenseRequired =
    selectedPlansInformation.filter((plan) => plan.driversLicenseRequired).length >
    0;

  const isCreditCardRequired =
    selectedPlansInformation.filter((plan) => plan.creditCardRequired).length >
    0;

  const hasFrontier =
    selectedPlansInformation.filter(
      (plan) => plan.company.includes('Frontier') && plan.type.includes('telecom')
    ).length > 0;


  const additionalQuestionsInformation = Object.values(questions).filter(
    (service) => service !== null
  );
  const hasAdditionalQuestions = additionalQuestionsInformation.length>0;
  const reducer = (state) => state.uiReducer;
  const { isOpenCartModal } = useSelector(reducer);

  const dispatch = useDispatch();

  const [inputCartId,setInputCartId] = useState('');

  const [productsId, setProductsId] = useState({});

  const [allowNextStepMessage, setAllowNextStepMessage] = useState('');

  // async function updateContactInfo(event) {
  //   const { name } = event.target;
  //   return dispatch(
  //     updateContactInfoAction({ ...contactInfo, [name]: event.target.value })
  //   );
  // }

  const [isSubmittingCart, setIsSubmittingCart] = useState(false);
  

  const allowProgress = () => {
    if (
      firstName &&
      lastName &&
      phoneRegExp.test(phone) &&
      emailRegExp.test(email)
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  };

  const checkErrors = () => {
    setClicked(true);
    if (!disabled) {
      store.dispatch(push('../info/2'));
    }
  };

  // async
  function updateContactInfo(event) {
    const { name } = event.target;
    // return
    dispatch(
      updateContactInfoAction({ ...contactInfo, [name]: event.target.value })
    );
  }

  function updateUserInfo(event) {
    const { name } = event.target;
    if (name === 'serviceDate') {
      dispatch(updateServiceDateAction(event.target.value));
    }
    //setTimeout(()=>validateFields(),500);
  }

  const getContactInfo = (event) => {
    updateContactInfo(event);
    // .then(allowProgress);
    //setTimeout(()=>validateFields(),500);
  };

  const [disabled, setDisabled] = useState(true);
  const [clicked, setClicked] = useState(false);

  const [step, setStep] = useState(0);

  const parsePlans = (plan) => {
    if (plan) {
      return plan.plan;
    } else {
      return 'No plan selected.';
    }
  };

  const parseVendor = (plan) =>{
    if (plan) {
      return plan.company;
    } else {
      return 'No Vendor selected.';
    }
  }

  const parseProductCode = (plan) =>{
    if (plan) {
      return plan.SFID;
    } else {
      return 'No product code selected.';
    }
  }

  const parseProductCategory = (plan) =>{
    if (plan){
      return plan.tag;
    } else {
      return 'No product selected';
    }
  }

  const sendEmail = () => {
    fetch('/sendEmail', {
      method: 'post',
      body: JSON.stringify({
        contactInfo,
        searchedAddress,
        previousAddress,
        billingAddress,
        referrer,
        serviceDate,
        electricity: parsePlans(electricity),
        telecom: parsePlans(telecom),
        insurance: parsePlans(insurance),
        movers: parsePlans(movers),
        security: parsePlans(security),
      }),
      headers: { 'Content-Type': 'application/json' },
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        console.log(data);
        return data;
      });
  };

  function getProductsInCart() {
    let products = [];
    for(var productInCart in cart){
      if (cart[productInCart] != null){
        products.push(serializeProducts(parseProductCode(cart[productInCart]),serviceDate));
      }
    }
    return products;
  }

  function getProductsToCreateCart(){
    let products = {};
    for(var productInCart in cart){
      if (cart[productInCart] != null){
        products[parseProductCategory(cart[productInCart]).toLowerCase()] = parseProductCode(cart[productInCart]);
      }
    }
    return products;
  }

  function getUpfrontCharges(){
    let products = [];
    for(var productInCart in cart){
      if (cart[productInCart] != null){
        if (cart[productInCart].upfrontCharge){
          let product = {
            "plan" : cart[productInCart].plan,
            "upfrontCharge": cart[productInCart].upfrontCharge,
            "upfrontChargeDetail": cart[productInCart].upfrontChargeDetail};
          products.push(product);
        }
      }
    }
    return products;
  }

  const buildPayload = () => {
    let lead = {
      phone: phone,
      email: email,
      firstName: firstName,
      lastName: lastName,
      address: searchedAddress.split(",")[0].trim(),
      city: searchedAddress.split(",")[1].trim(),
      state_province: searchedAddress.split(",")[2].trim().split(" ")[0],
      zip: searchedAddress.split(",")[2].trim().split(" ")[1],
      rentOrOwn: window.location.href.indexOf("firstkey") > 0 || window.location.href.indexOf("shoprp") > 0 ? 'Rent' : rentOrOwn,
      unit: unit,
      leadstatus: "Customer",
      type: "Connect Customer",
      attribution__c: "Connect Shop",
      moveInDate: serviceDate,
      dob__c: dob,
      ssn__c: ss,
      cc_debit__c: cc,
      credit_card_billing_address__c: enterBilling ? billingAddress.split(",")[0].trim() : searchedAddress.split(",")[0].trim(),
      credit_card_billing_name__c: firstName + ' ' + lastName,
      credit_card_expiration__c: exp,
      driver_s_license_expiration__c: dlExp,
      driver_s_license_number__c: dl,
      driver_s_license_state__c: dlState,
      security_code__c: cvv,
      legal_full_name__c: firstName + ' ' + lastName,

    }
    let deals = []
    for(var productInCart in cart) {
      const svcDateParts = serviceDate.split("/")
      if (cart[productInCart] != null){
        deals.push({
          attribution: "Connect Shop",
          serviceStartDate: serviceDate,
          product_code: cart[productInCart].product_code
        })
      }
    }

    const payload = {
      lead: lead,
      deals: deals,
    }

    if (window.location.href.indexOf("firstkey") > 0) {
      payload.isFirstKey = true
    } else if (window.location.href.indexOf("shoprp") > 0) {
      payload.isRealPage = true
    } else if (window.location.href.indexOf("wfg") > 0) {
      payload.isWfg = true
    }

    return payload;
  }


  const updateLead = () => {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      
      const payload = buildPayload()
      console.log(payload)
      var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          body: JSON.stringify(payload),
          redirect: 'follow'
      };
      fetch('/api/createleadwithdeal', requestOptions)
      .then(response => response.text())
      .then(result => JSON.parse(result))
      .then(response => {
          console.log(response)
          if (response.status == "200" || response.status == "201"){
            store.dispatch(push('../confirmation'));
            dispatch(toggleCartModalAction(!isOpenCartModal));
            dispatch(updateUserCartCopyAction(cart));
          } else {
            const phone = window.location.href.indexOf("firstkey") ? "844.387.7747" : "844.406.1540"
            const message = "An error has occurred with your order.  Please check the information input or contact us at " + phone
            alert(message)
            setStep(1)
          }
          setIsSubmittingCart(false);
        }
      )
      .catch(error => {
        setIsSubmittingCart(false);
      });
  }


  // const updateLead = () => {
  //   var leadInformation = sessionStorage.getItem('LeadId');
  //   let products = getProductsInCart();
  //   let data;
  //   let leadId = null;
  //   let partnerId = window.location.href.indexOf("firstkey") > -1 ? "0015w00002aUxY3AAK" : "0015w00002SjEpoAAF";

  //   if (leadInformation !== null){
  //     var leadInfoSplit = leadInformation.split('|');
  //     leadId = leadInfoSplit[0]; // This gets the stored leadId item. 
  //   }
  //   data = serializeLeadAndProducts(
  //     email,
  //     searchedAddress,
  //     unit, 
  //     phone,
  //     leadId, 
  //     firstName, 
  //     lastName, 
  //     serviceDate,
  //     products,
  //     answersToQuestions,
  //     previousAddress,
  //     dl,
  //     dlState,
  //     dlExp,
  //     dob,
  //     ss,
  //     billingAddress,
  //     billingUnit,
  //     cc,
  //     exp,
  //     cvv,
  //     ga_cookies,
  //     partnerId
  //     );

  //     var myHeaders = new Headers();
  //     myHeaders.append("Content-Type", "application/json");
  //     myHeaders.append("Cookie", "BrowserId=vkrSBB7PEeuUtyX3aioJhA");
  //     var raw = data;
  //     var requestOptions = {
  //         method: 'POST',
  //         headers: myHeaders,
  //         body: raw,
  //         redirect: 'follow'
  //     };
  //     fetch('https://getcake.my.salesforce-sites.com/leadCreator/services/apexrest/CreateLeadWithProducts', requestOptions)
  //     .then(response => response.text())
  //     .then(result => JSON.parse(result))
  //     .then(response => {
  //         console.log(response);
  //         if (response[0].status == "Success"){
  //           store.dispatch(push('../confirmation'));
  //           dispatch(toggleCartModalAction(!isOpenCartModal));
  //           dispatch(updateUserCartCopyAction(cart));
  //         }
  //         setIsSubmittingCart(false);
  //       }
  //     )
  //     .catch(error => {
  //       console.log('error', error);
  //       setIsSubmittingCart(false);
  //     });
  // }

  const handleSubmit = () => {
    setIsSubmittingCart(true);
    if (validateFields()){
    //  sendEmail();
      updateLead();
    } else {
      setIsSubmittingCart(false);
    }
  };

  const generateString = () => {
    let text = process.env.REACT_APP_CAKE_DOMAIN + "?";
    for (const property in cart) {
      if (cart[property]) {
        text += `${property}=${cart[property].plan}&`;
      }
    }
    return text.slice(0, -1).replace(/ /g, '_');
  };

  const generateCartId = () => {
    let products = getProductsToCreateCart();
    if (!isEmptyObject(products)){
      fetch('/api/searchCart', {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        body: JSON.stringify(getProductsToCreateCart())
      })
        .then((response) => {
          return response.json();
        })
          .then((carts) => {
            if (carts.cart.length === 0){
              fetch('/api/createCart', {
                    method: 'post',
                    headers: {
                      'Content-Type': 'application/json',
                      Accept: 'application/json',
                    },
                    body: JSON.stringify(getProductsToCreateCart()),
                  })
                    .then((response) => {
                      return response.json();
                    })
                      .then((res)=>{
                        setCartId('Cart Id: '+res.newCart.id);
                      });
            }else{
              setCartId('Cart Id: '+carts.cart[0].id);
            }
            setGeneratingCart(false);
          });
    } else {
      setCartId('Empty Cart');
      //setGeneratingCart(false);
    }
      setCartGenerationAttempted(true);
      //setTimeout(() => setCartGenerationAttempted(false), 7000);
  };

  const getCartById = (insertedCartId) =>{
    let insertedCart = {"id":insertedCartId};
    fetch('/api/getCart', {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify(insertedCart),
    })
      .then((response) => {
        return response.json();
      })
        .then((res) => {
          let returnedCart = res.cart[0];
          if (returnedCart != undefined){
            return returnedCart;
          }
          else{
            console.log('No cart returned');
            setProductsId({});
          }
        })
          .then((returnedCart) =>{
            
            if (returnedCart != undefined){
              populateCartById(returnedCart);
              setCartId('Cart Id: '+returnedCart.id);
            }
            else{
              console.log('Cart does not exists');
              setSearchingCart(false);
              setStep(0);
            }
          });
  }

  const populateCartById = (productsId) => {
    fetch('/api/getProductsById', {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify(productsId),
    })
      .then((response) =>{
        return response.json();
      })
        .then((res) => {
          // Anulates current cart
          dispatch(updateUserCartAction({service: "electricity", plan: null}));
          dispatch(updateUserCartAction({service: "telecom", plan: null}));
          dispatch(updateUserCartAction({service: "movers", plan: null}));
          dispatch(updateUserCartAction({service: "insurance", plan: null}));
          dispatch(updateUserCartAction({service: "security", plan: null}));
          // Creates the new cart:
          for (let product in res){
            if (res[product] != null){
              dispatch(updateUserCartAction({service: res[product].type, plan: res[product]}));
              console.log('Product to update '+ product);
            }
          }
          setSearchingCart(false);
          setStep(0);
        })
  }

  const [copyResult, setCopyResult] = useState('');
  const [copyAttempted, setCopyAttempted] = useState(false);

  const [cartId, setCartId] = useState('');
  const [cartGenerationAttempted, setCartGenerationAttempted] = useState(false);

  const [searchingCart, setSearchingCart] = useState(false);
  const [generatingCart, setGeneratingCart] = useState(false);

  const ref = useRef(null);

  // useEffect(() => console.log(ref.current && ref.current), [ref.current])

  const [areRequiredFieldsSet, setAreRequiredFieldsSet] = useState(true);
  const [areAllFieldsValid, setAreAllFieldsValid] = useState(true);

  const validateFields = () =>{
    let requiredFields = isRequiredFieldSet(firstName) && 
                        isRequiredFieldSet(lastName) && 
                        isRequiredFieldSet(phone) &&
                        isRequiredFieldSet(email) && 
                        isRequiredFieldSet(searchedAddress);

    
    
    setAreRequiredFieldsSet(requiredFields);
    setTimeout(()=>{setAreAllFieldsValid(true);setAreRequiredFieldsSet(true)}, 3000);
    return requiredFields;
  };

  const allowNextStep = (step) =>{
    let allow = false;
    if (step === 0){
      if (selectedPlansInformation.length>0){
        allow = true;
      }
      else{
        setAllowNextStepMessage('Select at least one service to proceed.');
      }
    }
    else if (step === 1){
      if (isRequiredFieldSet(firstName) && 
      isRequiredFieldSet(lastName) && 
      isRequiredFieldSet(phone) &&
      isRequiredFieldSet(email) && 
      isRequiredFieldSet(searchedAddress) && 
      isFieldValid(phone, "Phone") &&
      isFieldValid(email, "Email") &&
      window.location.href.indexOf("firstkey") > 0 || window.location.href.indexOf("shoprp") > 0 ? true: isRequiredFieldSet(rentOrOwn)
      ){
            allow = true;
      }
      else{
        setAllowNextStepMessage('Complete all the required information before proceeding.');
      }
    }
    else if (step === 2){
      if (isFieldValid(serviceDate, "Service Start Date") && isRequiredFieldSet(serviceDate)){
        allow = true;
      }
      else{
        setAllowNextStepMessage('Select a valid start date before proceeding.');
      }
    }
    else if (step === 3){
      let totalQuestions = calculateTotalQuestions(additionalQuestionsInformation);
      if (totalQuestions <= answersToQuestions.length){
        allow = true;
      }
      else{
        setAllowNextStepMessage('Please provide an answer to each additional question.');
      }
    }
    return allow;
  };

  const calculateTotalQuestions = (questions) =>{
    let totalQuestions = 0;
    if (questions.length>0){
      for (let i = 0; i<questions.length; i++)
      {
        if (questions[i]){
          totalQuestions += questions[i].length;
        }
      }
    }
    return totalQuestions;
  };

  const setMaxAllowedStep = () =>{
    if (allowNextStep(0)){
      if (allowNextStep(1)){
        if (allowNextStep(2)){
          if (allowNextStep(3)){
            setStep(4);
          } else {
            setStep(3);
          }
        } else {
          setStep(2)
        }
      } else {
        setStep(1);
      }
    } else {
      setStep(0);
    }
  };

  const handleRentOrOwnChange = (e) => {
    setRentOrOwn(e.target.value)
  }

  return (
    <Container>
      <FlexColumn style={{width: '120px', alignItems: 'center', marginLeft:'10px'}}>
        {/* <CreateCartButton onClick={() => {
            setGeneratingCart(true);
            generateCartId();
            setTimeout(()=>{setGeneratingCart(false)},1000);
          }}
        >
          {generatingCart ? <CreateCartButtonText>Loading...</CreateCartButtonText>:<CreateCartButtonText>Get Cart Id</CreateCartButtonText>}
        </CreateCartButton>
        {(cartGenerationAttempted && selectedPlansInformation.length) ? <CreateCartResultText>{cartId}</CreateCartResultText> : <CreateCartResultText></CreateCartResultText>}
        */}
        {/*<FlexRow style={{justifyContent: 'center'}}>
          <CopyToClipboard
            text={generateString()}
            onCopy={(text, result) => {
              setCopyAttempted(true);
              setTimeout(() => setCopyAttempted(false), 2000);
              if (result) {
                setCopyResult('Text copied!');
              } else {
                setCopyResult('Copy failed');
              }
            }}
          >
            <FaShareSquare style={{width: '40px', height: '40px', cursor:'pointer'}} />
          </CopyToClipboard>
          
          </FlexRow>*/}
       { /*<FlexRow>
          <Text fontSize='12px'>Share My Cart</Text>
          <div className="tooltip2" style={{marginBottom: '-5px', marginLeft: '7px', marginRight: '10px'}}><ImInfo />
            <p className="tooltiptext"> 
            Share your selections with roommates or significant others before checking out.
            </p>
          </div>
          </FlexRow>*/}
        {/*copyAttempted && <div style={{marginRight: '0px'}}>{copyResult}</div>*/}
        
      </FlexColumn>
      <GoBack
        onClick={() => {
          setCartGenerationAttempted(false);
          dispatch(toggleCartModalAction(!isOpenCartModal));
        }}
      >
        <StyledSpan>
          <svg width="13.98" height="11" viewBox="0 0 13.98 11">
            <path
              d="M5.64323855,1.44095875 C5.63327336,1.5761889 5.57387376,1.70323808 5.47609569,1.79845875 L1.92895284,5.36429209 L12.4125243,5.36429209 C12.7253541,5.36429209 12.9789528,5.61463953 12.9789528,5.92345875 C12.9789528,6.23227798 12.7253541,6.48262542 12.4125243,6.48262542 L1.92895284,6.48262542 L5.47609569,10.0484588 C5.61304607,10.2102173 5.64619688,10.4342638 5.56183715,10.6279341 C5.47747742,10.8216043 5.28994803,10.9519749 5.07680998,10.9651254 C4.9266272,10.9654991 4.78265206,10.9060032 4.67752427,10.8001254 L0.164667123,6.31762542 C-0.0548890411,6.09954018 -0.0548890411,5.74737733 0.164667123,5.52929209 L0.164667123,5.52929209 L4.67752427,1.04679209 C4.84138359,0.911597503 5.06833981,0.878871678 5.26452524,0.962149928 C5.46071065,1.04542809 5.59277439,1.23055323 5.60609569,1.44095875 L5.64323855,1.44095875 L5.64323855,1.44095875 Z"
              fill="black"
            ></path>
          </svg>
          <StyledSpan>Go back</StyledSpan>
        </StyledSpan>
      </GoBack>
      <Text fontSize="30px" fontWeight="bold">
        Review Your Order
      </Text>
      <Text fontSize="14px">We&apos;ll make this fast & simple</Text>
      {/*
      <SectionContainer>
        <FlexRow clickable onClick={() => setStep(-1)}>
          
          <Text fontSize="16px" fontWeight="bold">
            GOT A CART ID?
          </Text>
        </FlexRow>
        <Step selected={step === -1}>
          <div style={{ display: step !== -1 && 'none' }}>
            <InputContainer
                text=""
                name="cartId"
                placeholder=" Insert Cart Id"
                width="50%"
                onChange = {event => setInputCartId(event.target.value)}
              />
            <FinishButton
              width="50%"
              onClick={() => {
                setSearchingCart(true);
                getCartById(inputCartId);
              }}
            >
            {searchingCart ? <ButtonText>Searching Cart</ButtonText>: <ButtonText>Find Cart</ButtonText>}
            </FinishButton>
          </div>
        </Step>
      </SectionContainer>
      */}
      <SectionContainer>
        <FlexRow clickable onClick={() => setStep(0)}>
          <NumberedCircle selected>1</NumberedCircle>
          <Text fontSize="16px" fontWeight="bold">
            MY SERVICES
          </Text>
        </FlexRow>
        <Step selected={step === 0}>
          <div style={{ display: step !== 0 && 'none' }}>
            {selectedPlansInformation.map((plan) => (
              <ResultCard condense plan={plan} key={plan.plan} />
            ))}

            {selectedPlansInformation.length ? (
              <>
                <FinishButton
                  width="100%"
                  disabled={false}
                  onClick={() => {
                    if (allowNextStep(0)){
                      setAllowNextStepMessage('');
                      setStep(1);
                    }
                    else{
                      setMaxAllowedStep();
                      setTimeout(()=>{setAllowNextStepMessage('')},3000);
                    }
                  }}
                >
                  <ButtonText>Continue</ButtonText>
                </FinishButton>
              </>
            ) : (
              <ButtonText>There are no items in your cart.</ButtonText>
            )}
            <FlexRow>
              <Text fontSize="14px" fontWeight="800">{allowNextStepMessage}</Text>
            </FlexRow>
          </div>
        </Step>
      </SectionContainer>

      <SectionContainer>
        <FlexRow clickable 
          onClick={() => {
            if (allowNextStep(0)){
              setAllowNextStepMessage('');
              setStep(1);
            }
            else{
              setMaxAllowedStep();
              setTimeout(()=>{setAllowNextStepMessage('')},3000);
            }
            }}>
          <NumberedCircle selected={step !== 0 && step !== -1}>2</NumberedCircle>
          <CartTitles selected={step !== 0 && step !== -1}>
            <Text fontSize="16px" fontWeight="bold">
              CONTACT
            </Text>
          </CartTitles>
        </FlexRow>
        <Step selected={step === 1} ref={ref}>
          <div style={{ display: step !== 1 && 'none' }}>
            <FlexRow>
              <InputContainer
                text=""
                name="firstName"
                value={firstName}
                onChange={getContactInfo}
                placeholder=" First Name*"
                width="50%"
                space
                isRequired="true"
              />
              <InputContainer
                name="lastName"
                value={lastName}
                onChange={getContactInfo}
                text=""
                placeholder=" Last Name*"
                width="50%"
                isRequired="true"
              />
            </FlexRow>
            <InputContainer
              text=""
              name="address"
              value={searchedAddress}
              onChange={updateUserInfo}
              placeholder="Street Address*"
              width="100%"
              address="copy"
            />
            <FlexRow>
            <InputContainer
              text=""
              name="unit"
              value={unit}
              placeholder=" Apt, unit, etc."
              width="50%"
              onChange={getContactInfo}
            />
              {window.location.href.indexOf("firstkey") === -1 && window.location.href.indexOf("shoprp") === -1 &&
              <select id='rentorown' style={{marginLeft: '5px', border: '1px solid rgb(210, 211, 212)', height: '40px', borderRadius: '6px', width: '50%'}} onChange={handleRentOrOwnChange}>
                <option value=''>Choose Rent or Own*</option>
                <option value='Rent'>Rent</option>
                <option value='Own'>Own</option>
              </select>
            }
            
            </FlexRow>
            <FlexRow>
              <InputContainer
                text=""
                placeholder="Phone*"
                name="phone"
                value={phone}
                width="50%"
                onChange={getContactInfo}
                space
                isRequired="true"
                validationType="Phone"
              />
              <InputContainer
                text=""
                name="email"
                value={email}
                placeholder="Email*"
                width="50%"
                onChange={getContactInfo}
                fieldType="email"
                isRequired="true"
                validationType="Email"
              />
            </FlexRow>
            {/*hasFrontier && (
              <InputContainer
                text="Previous Address*"
                placeholder="Previous Address*"
                width="100%"
                space
                name="previousAddress"
                address="previous"
                value={previousAddress}
                isRequired="true"
                // onChange={getContactInfo}
              />
            )*/}
            <Text fontSize="12px">* Required fields.</Text>
            <FlexRow>
              <Text fontSize="14px" fontWeight="800">{allowNextStepMessage}</Text>
            </FlexRow>
          </div>
        </Step>
      </SectionContainer>

      <SectionContainer>
        <FlexRow clickable 
          onClick={() => {
              if (allowNextStep(1) && allowNextStep(0)){
                setAllowNextStepMessage('');
                setStep(2);
              }
              else{
                setMaxAllowedStep();
                setTimeout(()=>{setAllowNextStepMessage('')},3000);
              }
            }
          }>
          <NumberedCircle selected={step === 2 || step === 3 || step === 4}>3</NumberedCircle>
          <CartTitles selected={step === 2 || step === 3 || step === 4}>
            <Text fontSize="16px" fontWeight="bold">
                PREFERENCES
            </Text>
          </CartTitles>
        </FlexRow>
        <Step selected={step === 2}>
        <Text fontSize="8px">&nbsp;</Text>
          <FlexRow>
              <div className="tooltip" style={{marginBottom: '-5px', marginLeft: '7px', marginRight: '10px'}}><ImInfo />
                <span className="tooltiptext">Not certain of your start date? No worries! Enter you estimated date here, and you can always contact us if your date should change. 
                </span>
              </div>
              <Text fontSize="12px">Service Start Date?</Text>
          </FlexRow>
          <FlexRow>
            <div style={{ display: step !== 2 && 'none' }}>
              <InputContainer
                placeholder=" Service Start Date?"
                width="100%"
                space
                name="serviceDate"
                value={serviceDate}
                onChange={updateUserInfo}
                fieldType="date"
                validationType="Service Start Date"
              />{' '}
            </div>
          </FlexRow>
          <FlexRow>
            <Text fontSize="14px" fontWeight="800">{allowNextStepMessage}</Text>
          </FlexRow>
        </Step>
      </SectionContainer>
      {/*This is the new section for questions*/}
      <SectionContainer>
        <FlexRow clickable 
          onClick={() => {
            if (allowNextStep(0) && allowNextStep(1) && allowNextStep (2)){
              setAllowNextStepMessage('');
              setStep(3);
            }
            else{
              setMaxAllowedStep();
              setTimeout(()=>{setAllowNextStepMessage('')},3000);
            }
          }}>
          <NumberedCircle selected={step === 3 || step === 4}>4</NumberedCircle>
          <CartTitles selected={step === 3 || step===4}>
            <Text fontSize="16px" fontWeight="bold">
              ADDITIONAL QUESTIONS
            </Text>
            <div className="tooltip" style={{marginBottom: '-5px', marginLeft: '7px'}}><ImInfo style={{zIndex: -1000, position: 'relative', marginTop: '4px'}}/>
                <p className="tooltiptext"> 
                <Text style={{ fontWeight: 'bold' }}>Why Additional Questions?<br></br></Text>
                Some of your products may need answers to additional security questions when we are setting them up for you.
                This help us to set your products sooner and easier.
                </p>
            </div>
          </CartTitles>
          <CartTitles selected={step !== 0 && step !== -1}>
          </CartTitles>
        </FlexRow>
        <Step selected={step === 3}>
          <div style={{ display: step !== 3 && 'none' }}>
            {selectedPlansInformation.length 
              ? (!hasAdditionalQuestions 
                  ? (
                    <StyledLabelField><p>If more information is required about your order, a Connect Representative will follow up with you. </p><br />
                    <p>Prices may vary based on additional changes made during any follow up conversations.</p><br />
                    <p>Please, go to the next step.</p>
                    </StyledLabelField>
                    ) 
                  : (
                    <div>
                    {additionalQuestionsInformation.map((questions) => (
                      questions.map((question)=>(
                        <AdditionalQuestionCard question={question}/>
                      ))
                    ))}
                    </div>
                  ))
              : <ButtonText>There are no items in your cart.</ButtonText>
            }
            <FlexRow>
              <Text fontSize="14px" fontWeight="800">{allowNextStepMessage}</Text>
            </FlexRow>
          </div>
        </Step>
      </SectionContainer>
      {/*This is the end of the Additional Questions section*/}
      <SectionContainer>
        <FlexRow clickable onClick={() => {
            if (allowNextStep(0) && allowNextStep(1) && allowNextStep (2) && allowNextStep(3)){
              setStep(4);
            }
            else{
              setMaxAllowedStep();
              //allowNextStep(step);
              setTimeout(()=>{setAllowNextStepMessage('')},3000);
            }
          }}>
          <NumberedCircle selected={step === 4}>5</NumberedCircle>
          <CartTitles selected={step === 4}>
            <Text fontSize="16px" fontWeight="bold">
              FINISH
            </Text>
            <div className="tooltip" style={{marginBottom: '-5px', marginLeft: '7px'}}><ImInfo style={{zIndex: -1000, position: 'relative', marginTop: '4px'}}/>
                <p className="tooltiptext"> 
                <Text style={{ fontWeight: 'bold' }}>Why do we need this?<br></br></Text>
                The vendors require this information to place your order. 
                Providing this now allows them to directly set up your services. 
                Don’t worry, your sensitive information is secure and protected - we will not share it with any other parties.
                </p>
            </div>
          </CartTitles>
          <CartTitles selected={step !== 0 && step !== -1}>

          </CartTitles>
        </FlexRow>
        <Step selected={step === 4}>
          <div style={{ display: step !== 4 && 'none' }}>
            {isDriversLicenseRequired && (
              <>
                <InputSensitiveContainer
                  placeholder="Driver's License"
                  width="50%"
                  space
                  name="dl"
                  value={dl}
                  onChange={getContactInfo}
                />{' '}
                <InputSensitiveContainer
                  placeholder="State Issued"
                  width="50%"
                  space
                  name="dlState"
                  value={dlState}
                  onChange={getContactInfo}
                />{' '}
                <InputSensitiveContainer
                  placeholder="Driver's License Expiration"
                  width="50%"
                  space
                  name="dlExp"
                  value={dlExp}
                  onChange={getContactInfo}
                />
              </>
            )}
            <Text fontSize="8px">&nbsp;</Text>
            <Text fontSize="12px">Date of Birth</Text>
            <InputContainer
              placeholder="Date of Birth"
              space
              name="dob"
              value={dob}
              onChange={getContactInfo}
              width="50%"
              fieldType="date"
              validationType="Date of Birth"
            />
            <FlexRow></FlexRow>
            {selectedPlansInformation.length && getUpfrontCharges().length ? 
              (
                <UpfrontChargeSummary plans={getUpfrontCharges()}/>
              )
              :
              (
                <FlexRow>
                  <small style={{marginBottom: '20px'}}>Please note: There are no upfront charges associated with your order. You will not be charged by Connect -  any and all charges you will receive will come directly from the providers for the services you’ve selected 
                  </small>
                </FlexRow>
              )
            }
            {
              !areRequiredFieldsSet ? <><br/><Text fontSize="14px" fontWeight="800">Complete all fields in this step to place your order.</Text></>:''
            }
            {
              !areAllFieldsValid ? <><br/><Text fontSize="14px" fontWeight="800">Verify invalid fields.</Text></>:''
            }
            <FlexRow>
              <input style={{marginRight: '15px'}}type="checkbox" name="accept-terms" checked={acceptTerms} onChange={() => setAcceptTerms(!acceptTerms)} />

             
              <Text fontSize="11px">By checking this box,  I agree to Connect Service’s <a href="https://connectservices.com/terms-of-service/" target="_blank">Terms & Conditions</a> and <a href="https://connectservices.com/privacy-policy/" target="_blank">Privacy Policy</a>, and I give consent for Connect to contact me via SMS, phone, or email related to these services and related services.  Messaging frequency is variable. Reply "STOP " to unsubscribe and "HELP" for assistance.</Text>      
            </FlexRow>
            {selectedPlansInformation.length ? ( !isSubmittingCart ? ( 
              <FinishButton width="100%" onClick={() => {acceptTerms ? handleSubmit(): null}} disabled={!acceptTerms}>
                <ButtonText>Place Order</ButtonText>
              </FinishButton>)
                :(
                  <SpinnerRow>
                  <ButtonText>Submitting order...</ButtonText>
                    <img src={spinner} height="50px" width="50px"></img>
                  </SpinnerRow>
                )
              ):(
              <>
              <br/> 
              <ButtonText>There are no items in your cart.</ButtonText>
              </>
              )
            }
          </div>
        </Step>
      </SectionContainer>
    </Container>
  );
};
