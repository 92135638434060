import React, { useState } from 'react';
import styled from 'styled-components';

import { colors, deviceSize } from '_constants/styleConstants';
import rightArrow from '_images/rightArrow.svg';
import { Circle } from '_pages/home/components/circle';
import { SectionTitle } from '_pages/home/components/sectionTitle';
import { RightArrow } from '_components';

const Heading = styled.div`
  font-size: 50px;
  font-weight: bold;

  @media (max-width: ${deviceSize.tablet}px) {
    font-size: 32px;
  }
`;

const Detail = styled.div`
  font-size: 16px;
  color: ${colors.darkGray};
`;

const Divider = styled.hr`
  margin: 20px 0px;
`;

const LearnMoreRow = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  ${({ hover }) =>
    hover &&
    `box-shadow: 0 6px 40px rgba(210, 211, 212, 0.5);
  transform: translateY(-2px);`}
`;

const TextColumn = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
`;

const LearnMoreText = styled.div`
  font-size: 12px;
  color: ${({ color }) => color};

  ${({ hover }) => hover && `color: ${colors.brand};`}
`;

const Arrow = styled(RightArrow)`
  height: 25px;
  vertical-align: middle;
  transition: 1s;

  ${({ hover }) => hover && 'margin-left: 5px;'}
`;

export const MainInfo = ({ className }) => {
  const [hover, setHover] = useState(false);
  const handleHover = () => {
    setHover(!hover);
  };

  const scrollToTop = () => {
    document.getElementById('address').scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'center',
    });
    setTimeout(() => document.getElementById('address').focus(), 1000);
  };

  return (
    <>
      <SectionTitle number="01" title="OUR SERVICES" />
      <Heading>Moving is a piece of cake&trade;</Heading>
      <Detail>
        To busy people who just want it done, Cake is the fastest and most
        convenient way to connect services & utilities for your home. <br />
        <br />
        Instantly connect electricity, internet, tv, smart home security,
        insurance, water, gas, movers--basically everything you&apos;ll need for
        your move.
      </Detail>
      <Divider />
      <LearnMoreRow
        onMouseEnter={handleHover}
        onMouseLeave={handleHover}
        hover={hover}
        onClick={scrollToTop}
      >
        <Circle color={hover ? colors.brand : colors.darkGray} />
        <TextColumn>
          <LearnMoreText hover={hover} color={colors.darkGray}>
            Ready?
          </LearnMoreText>
          <LearnMoreText hover={hover} color="black">
            Get started{' '}
            <Arrow
              className={className}
              color={hover && colors.brand}
              hover={hover}
            />
          </LearnMoreText>
        </TextColumn>
      </LearnMoreRow>
    </>
  );
};
