import React, {useState, useLayoutEffect} from 'react';
import styled from 'styled-components';
import { colors, deviceSize } from '_constants';
import { useDispatch, useSelector } from 'react-redux';
import { isMobileDevice, post } from '_helpers';
import { useEffect } from 'react/cjs/react.development';
import Stepper from 'react-stepper-horizontal';
import {ImInfo} from 'react-icons/im';
import { ReactComponent as CallIcon } from '_images/contact_icon-call.svg';
import { ReactComponent as ChatIcon } from '_images/contact_icon-chat.svg';

const Call = styled(CallIcon)`
  cursor: pointer;
  height: 35px;
  padding-right: 10px;

  &:hover {
    transform: translateY(2px) translateX(2px);
  }

  @media (max-width: ${deviceSize.tablet}px) {
    height: 45px;
  }
`;

const Chat = styled(ChatIcon)`
  cursor: pointer;
  height: 35px;

  &:hover {
    transform: translateY(2px) translateX(2px);
  }


  @media (max-width: ${deviceSize.tablet}px) {
    height: 45px;
  }
`;

const PhoneRow = styled.a`
  display: flex;
  align-items: center;
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  ${({ condense }) => !condense && 'margin: 10px 0px'};
  width: 100%;
  background-color: white;
  border: 1px solid ${colors.darkGray};
  border-radius: 6px;
  font-size: 12px;

  &:hover {
    box-shadow: 0 6px 40px rgba(210, 211, 212, 0.5);
    transform: translateY(0px);
  }
`;

const InformationCard = styled.div`
  display: flex;
  flex-direction: column;
  ${({ condense }) => !condense && 'margin: 10px 0px'};
  width: 100%;
  border: 1px solid ${colors.lightGray};
  border-radius: 6px;
  font-size: 12px;
  box-shadow: 0 6px 6px rgba(210, 211, 212, 0.5);
  padding: 20px;
  max-width: 400px;
  }
`;

const InformationCardNoBorder = styled.div`
  display: flex;
  flex-direction: column;
  ${({ condense }) => !condense && 'margin: 10px 0px'};
  width: 100%;
  font-size: 12px;
  max-width: 250px;
  }
`;

const InfoRow = styled.div`
  display: flex;
  justify-content: space-around;
  padding: 5px;
  ${({ clickable }) => clickable && 'cursor: pointer;'}
  color: ${({ color }) => color};
  font-size: ${({ fontSize }) => fontSize};

  @media (max-width: ${deviceSize.tablet}px) {
    font-size: initial;
  }
`;

const CardSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  padding: 5px;
  ${({ clickable }) => clickable && 'cursor: pointer;'}
  font-size: 20px;

  @media (max-width: ${deviceSize.tablet}px) {
    font-size: 12px;
  }
`;

const CardImage = styled.div`
  background-image: ${(props) =>
    props.frontierInternet
      ? `url('/companyLogos/frontierInternet.png')`
      : props.backgroundImage};
  height: 100px;
  width: 150px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  @media (max-width: ${deviceSize.tablet}px) {
    height: 50px;
    width: 75px;
  }
`;

const ColoredLine = ({ color }) => (
  <hr
      style={{
          color: color,
          background: color,
          height: 1,
          width: 1200,
          border: color,
      }}
  />
);

const InformationCardText = styled.label`
  display: flex;
  justify-content: center;
  padding: ${({ padding }) => padding};
  padding-top: ${({ padding_top }) => padding_top};
  color:  ${({ color }) => color};
  font-size: 14px;
  font-weight: ${({ weight }) => weight};
  text-align: center;
  @media (max-width: ${deviceSize.tablet}px) {
    font-size: 12px;
  }
 `;

const styles = {
  width: '200px',
  display: 'inline-table',
  verticalAlign: 'top'
};

const InfoRowStageLabels = styled.div`
  display: flex;
  padding: 0px;
  color: ${({ color }) => color};
  font-size: ${({ fontSize }) => fontSize};
  padding-bottom: 10px;

  @media (max-width: ${deviceSize.tablet}px) {
    font-size: initial;
  }
`;

const StageLabel = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  ${({ condense }) => !condense && 'margin: 10px 0px'};
  width: 100%;
  color: ${({ color }) => color};
  font-size: 12px;
  text-align: center;
  font-weight: 900;
  }
`;


export const OpportunityCard = ({ opportunity, condense }) => {
  const image = `url('${opportunity.logo}')`;
  const reducer = (state) => state.userReducer;
  const { cart } = useSelector(reducer);

  const routerReducer = (state) => state.router;
  const { location } = useSelector(routerReducer);
  const { pathname } = location;

  const [isOppCancelled, setIsOppCancelled] = useState(false);
  const [oppNeedsMoreInfo, setOppNeedsMoreInfo] = useState(false);
  const [oppPendingScheduling, setOppPendingScheduling] = useState(false);
  const [stepNumber, setStepNumber] = useState(0);
  const [OrderPlacedColor, setOrderPlacedColor] = useState(colors.brand);
  const [ProcessingColor, setProcessingColor] = useState(colors.lightGray);
  const [CompletedColor, setCompletedColor] = useState(colors.lightGray);
  const [isFirsTimeLoaded, setIsFirstTimeLoaded] = useState(true);

  useLayoutEffect(()=>{
    if (isFirsTimeLoaded){
      setIsFirstTimeLoaded(false);
      if (opportunity){
        if (opportunity.stage === 'Closed Lost'){
          setIsOppCancelled(true);
        } 
        else if (opportunity.stage === 'Processing' && opportunity.stage_detail === 'Task'){
          setOppNeedsMoreInfo(true);
          setStepNumber(1);
          setOrderPlacedColor(colors.completedStep);
          setProcessingColor(colors.brand);
        } 
        else if (opportunity.stage === 'Processing' && opportunity.stage_detail === 'Provider Scheduling'){
          setOppPendingScheduling(true);
          setStepNumber(1);
          setOrderPlacedColor(colors.completedStep);
          setProcessingColor(colors.brand);
        }
        else if (opportunity.stage === 'Processing' && !opportunity.stage_detail){
          setStepNumber(1);
          setOrderPlacedColor(colors.completedStep);
          setProcessingColor(colors.brand);
        }
        else if (opportunity.stage === 'Completed'){
          setStepNumber(3);
          setOrderPlacedColor(colors.completedStep);
          setProcessingColor(colors.completedStep);
          setCompletedColor(colors.completedStep);
        }
      }
    }
  });

  const startChat = () =>{
    document.querySelector(".Twilio-EntryPoint").click();
  }

  return (
    <Card condense={condense}>
      <InfoRow>
        <CardSection>
          <CardImage
            backgroundImage={image}
          ></CardImage>
        </CardSection>
        <CardSection>{opportunity.product}</CardSection>
        <CardSection></CardSection>
      </InfoRow>
      <InfoRow>
        <ColoredLine color={colors.lightGray}/>
      </InfoRow>
      {/*This should be displayed when the services is canceled or Closed Lost*/}
      {isOppCancelled && (
        <InfoRow>
          <CardSection>
            <InformationCard>
              <InformationCardText color='#000000' weight='800' padding="10px">This service request has been cancelled</InformationCardText>
              <InformationCardText color='#000000' padding="10px">Need help? Contact Us! We can assist with any questions, or help you reorder this service.</InformationCardText>
              <InformationCardText padding_top="5px">
              <PhoneRow href="tel:1-844-387-7747"><Call pathname={pathname}/></PhoneRow>                    
              <Chat pathname={pathname} onClick={() => startChat()}/>
              </InformationCardText>
            </InformationCard>
          </CardSection>
        </InfoRow>
        )
      }
      {oppNeedsMoreInfo && (
        <InfoRow>
          <CardSection>
            <InformationCardNoBorder>
              <InformationCardText color={colors.brand} weight='800'>More Info Needed</InformationCardText>
              <InformationCardText color='#000000'>Want to contact us?</InformationCardText>
              <InformationCardText padding_top="5px">
                <PhoneRow href="tel:1-844-387-7747"><Call pathname={pathname}/></PhoneRow>                    
                <Chat pathname={pathname} onClick={() => startChat()}/> 
              </InformationCardText>
            </InformationCardNoBorder>
          </CardSection>
        </InfoRow>
        )
      }
      {oppPendingScheduling && (
        <InfoRow>
          <CardSection>
            <InformationCardNoBorder>
              <InformationCardText color='#000000' >We are working with the vendor to set up your service</InformationCardText>
            </InformationCardNoBorder>
          </CardSection>
        </InfoRow>
        )
      }
      {!isOppCancelled && (
        <InfoRow>
        <CardSection>
          <Stepper 
            steps={[
              {
                title: opportunity.created_date.slice(5,7)+"/"+opportunity.created_date.slice(8,10)+"/"+opportunity.created_date.slice(0,4)
              }, 
              {
                title: opportunity.stage === 'Processing' ? opportunity.last_modified_date.slice(5,7)+"/"+opportunity.last_modified_date.slice(8,10)+"/"+opportunity.last_modified_date.slice(0,4) : ''
              }, 
              {
                title: opportunity.stage === 'Completed' ? opportunity.last_modified_date.slice(5,7)+"/"+opportunity.last_modified_date.slice(8,10)+"/"+opportunity.last_modified_date.slice(0,4) : ''
              }
            ]} 
            activeStep={stepNumber}
            activeColor={colors.brand}
            completeColor={colors.completedStep}
            lineMarginOffset={0}
            defaultBarColor={colors.lightGray}
            completeBarColor={colors.completedStep}
            completeTitleColor={colors.completedStep}
            defaultTitleColor={colors.lightGray}
            activeTitleColor={colors.brand}
            circleFontSize={0}
            titleFontSize={12}
            circleTop={10}
             />
        </CardSection>
        </InfoRow>
        )
      }
      {!isOppCancelled && (
        <InfoRowStageLabels>
        <StageLabel color={OrderPlacedColor}>
          Order Placed
        </StageLabel>
        <StageLabel color={ProcessingColor}>
          Processing
        </StageLabel>
        <StageLabel color={CompletedColor}>
          Completed
        </StageLabel>
        </InfoRowStageLabels>
        )
      }
    </Card>
  );
};

