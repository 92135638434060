import { combineReducers } from 'redux';
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { routerMiddleware, connectRouter } from 'connected-react-router';
import { persistReducer } from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session';

import userReducer from './user';
import contactInfoReducer from './contactInfo';
import startDatesReducer from './startDates';
import uiReducer from './ui';
import plansReducer from './plans';
import { history } from '_helpers';

const persistConfig = {
  key: 'root',
  blacklist: ['router'],
  storage: storageSession,
};

const middleware = [...getDefaultMiddleware(), routerMiddleware(history)];

const rootReducer = combineReducers({
  router: connectRouter(history),
  userReducer,
  contactInfoReducer,
  startDatesReducer,
  uiReducer,
  plansReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware,
});

export default store;
