import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { updateUserAnswersAction, updateUserFiltersAction } from '_store/user';
import { colors, deviceSize } from '_constants';

const AnswerCard = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  max-width: 300px;
  height: 70px;
  background-color: white;
  border: 1px solid
    ${({ selected }) => (selected ? colors.brand : colors.border)};
  border-radius: 6px;
  cursor: pointer;
  margin: 10px;

  @media (max-width: ${deviceSize.tablet}px) {
    width: 95%;
    margin: 10px;
  }
`;

const Indicator = styled.div`
  height: 20px;
  width: 20px;
  border-radius: 50%;
  border: 1px solid ${colors.border};
  background-color: ${({ selected }) => (selected ? colors.brand : 'white')};
`;

const AnswerText = styled.div`
  text-align: left;
  width: 80%;
`;

export const RadioOption = ({
  answer,
  service,
  questionNumber,
  tag,
  selectAnswer,
  selectedAnswer,
}) => {
  const dispatch = useDispatch();
  const updateAnswersAndFilters = () => {
    selectAnswer(answer);
    dispatch(updateUserAnswersAction({ answer, service, questionNumber }));
    dispatch(updateUserFiltersAction({ service, filter: tag }));
  };

  const reducer = (state) => state.userReducer;
  const { answers } = useSelector(reducer);

  useEffect(() => {
    if (answers[service][questionNumber] === answer) {
      selectAnswer(answer);
    }
  });

  return (
    <AnswerCard
      onClick={updateAnswersAndFilters}
      selected={selectedAnswer === answer}
    >
      <Indicator selected={selectedAnswer === answer} />
      <AnswerText>{answer}</AnswerText>
    </AnswerCard>
  );
};
