import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import styled from 'styled-components';

import store from '_store';
import { deviceSize, colors } from '_constants';
import { CheckBoxItem } from './components/checkBoxItem';
import { InfoSubmitButton, Underline, LoadingModal } from '_components';
import electricityIcon from '_images/electricityIcon.svg';
import telecomIcon from '_images/telecomIcon.svg';
import insuranceIcon from '_images/insuranceIcon.svg';
import moversIcon from '_images/moversIcon.svg';
import securityIcon from '_images/securityIcon.svg';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0 100px;
  margin-top: 50px;

  @media (max-width: ${deviceSize.tablet}px) {
    padding: 0px;
    margin: 0px 10px;
  }
`;

const LeftAlignContainer = styled.div`
  text-align: left;
`;

const Heading = styled.div`
  font-size: 36px;
  line-height: 1;
  font-weight: 900;
`;

const Subtitle = styled.div`
  color: ${colors.darkGray};
  font-size: 12px;
  margin 20px 0px 10px 0px;
`;

const ErrorText = styled.div`
  ${({ show }) => !show && 'display: none;'}
  font-size: 10px;
  color: red;
  margin-left: 10px;
`;

const services = [
  { service: 'electricity', icon: electricityIcon },
  { service: 'telecom', icon: telecomIcon },
  { service: 'insurance', icon: insuranceIcon },
  { service: 'movers', icon: moversIcon },
  { service: 'security', icon: securityIcon },
];

export const ServiceSelect = () => {
  const reducer = (state) => state.userReducer;
  const { servicesWanted } = useSelector(reducer);
  const [clicked, setClicked] = useState(false);
  const checkErrors = () => {
    if (servicesWanted.length) {
      store.dispatch(push(`../questions/${servicesWanted[0]}`));
    }
    setClicked(true);
  };
  return (
    <Container>
      <LoadingModal />
      <LeftAlignContainer>
        <Heading>We&apos;ll make this fast & simple.</Heading>
        <Subtitle>
          Select the services you want -- skip the stuff you don&apos;t want.
        </Subtitle>
        <Underline noMargin />
        {services.map((service) => (
          <CheckBoxItem
            service={service.service}
            key={service}
            icon={service.icon}
          />
        ))}
        <ErrorText show={clicked && !servicesWanted.length}>
          Please select at least one service.
        </ErrorText>
        <InfoSubmitButton
          color={servicesWanted.length ? colors.brand : colors.fadedBrand}
          onClick={checkErrors}
          cursor={servicesWanted.length ? 'pointer' : 'not-allowed'}
        />
      </LeftAlignContainer>
    </Container>
  );
};
