import React, { useEffect } from 'react';
import styled from 'styled-components';
import { deviceSize, colors } from '_constants';
import { useDispatch, useSelector } from 'react-redux';
import { ResultCard } from '_components';
import { clearCartAction } from '_store/user';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 50px 100px;
  align-items: center;

  @media (max-width: ${deviceSize.tablet}px) {
    padding: 0px 25px;
  }
`;

const Heading = styled.div`
  font-size: 36px;
  line-height: 1;
  font-weight: 900;
  margin: 20px 0px;
`;

const Section = styled.div`
  font-size: 24px;
  line-height: 1;
  font-weight: 900;
  margin-bottom: 20px;
`;

const Subtitle = styled.div`
  color: ${colors.darkGray};
  font-size: 12px;
`;

export const Main = () => {
  return (
    <Container>
      <Heading>Partner Portal</Heading>
      <br />
      <div>
        We’re glad you’re here and look forward to learning more about how we
        can work together! Email{' '}
        <a href="mailto: partners@get-cake.com">partners@get-cake.com</a> and we
        will contact you shortly.
      </div>
    </Container>
  );
};
